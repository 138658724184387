
/*
 *  Modal Post Images
 */

.modal-simgs {
  .modal-box {
    width: 1192px;
    max-width: 100%;
    max-height: 100%;
    @include flexbox(column, stretch, flex-start);
    &-close {
      display: none;
    }
  }
  &-head {
    padding: ($spacer * 2) ($spacer * 4);
    // border-bottom: 1px solid $color-gray-cloud;
    position: relative;
    padding-right: $spacer * 4;
    @include flexbox(row, center, flex-start);
    gap: $spacer * 2;
    &:after {
      display: block;
      content: '';
      position: absolute;
      bottom: ($spacer * -2);
      left: 0;
      width: 100%;
      height: $spacer * 2;
      background: linear-gradient(to bottom, rgba(0,0,0,0.08), transparent);
      z-index: 1;
    }
    &-text {
      flex: 1;
    }
    &-title {
      margin: 0;
      font-weight: $cerebri-heavy;
      font-size: 30px;
    }
    &-dir {
      color: $color-text-medium-1-5;
    }
  }
  &-body {
    overflow: auto;
    padding: ($spacer * 2) ($spacer * 4);
  }
  &-foot {
    padding: $spacer ($spacer * 4);
    // border-top: 1px solid $color-gray-cloud;
    position: relative;
    &:after {
      display: block;
      content: '';
      position: absolute;
      top: ($spacer * -2);
      left: 0;
      width: 100%;
      height: $spacer * 2;
      background: linear-gradient(to top, white, transparent);
      background: linear-gradient(to top, rgba(0,0,0,0.08), transparent);
      z-index: 1;
    }
  }
  &-list {
    // border: 1px solid lime;
    @include flexbox(row, flex-start, flex-start);
    flex-wrap: wrap;
    gap: $spacer * 2;
    // padding: 12px;
    &-slot {
      width: 200px;
      height: 200px;
      background-color: #EEE;
      transition: all 200ms;
      border: 2px solid #EEE;
      border-radius: 3px;
      // overflow: hidden;
      &-card {
        position: relative;
        width: 100%;
        height: 100%;
        &:hover {
          cursor: move;
        }
        &-img {
          // position: absolute;
          // top: 0;
          // left: 0;
          // display: block;
          // width: 100%;
          // height: 100%;
          opacity: 1;
          transition: all 200ms;
          // object-fit: scale-down;
        }
        &-menu {
          background-color: white;
          position: absolute;
          top: 4px;
          left: 4px;
          width: 30px;
          height: 30px;
          border-radius: 100px;
          opacity: 0;
          transition: all 200ms;
        }
        &:hover {
          .modal-simgs-list-slot-card-menu {
            opacity: 1;
          }
        }
      }
      &.dnd-dragging {
        border-color: $color-accent-blue;
        .modal-simgs-list-slot-card-img {
          opacity: 0.5;
        }
      }
      &.dnd-hover {
        background-color: $color-accent-blue;
        .modal-simgs-list-slot-card-img {
          opacity: 0.1;
        }
      }
    }
  }
}



/*
 *  Modal Post URLs
 */

.modal-surls {
  &-preview {
    margin-top: $spacer * 2;
  }
}



/*
 *  Modal Post Audience
 */

.modal-saud {
  .modal-box {
    width: 650px;
    max-width: 100%;
    &-close {
      display: none;
    }
  }

  &-title {
    margin-top: $spacer * 2;
  }

  &-feeds {
    margin-top: $spacer * 4;
    margin-left: $spacer * 2;
  }
  &-feed {
    @include flexbox(row, flex-start, flex-start);
    gap: $spacer;
    // border: 1px solid red;
    &:not(:first-child) {
      margin-top: $spacer * 2;
    }
    &-text {
      &-type {
        font-weight: $cerebri-bold;
        color: $color-text-faint;
        text-transform: uppercase;
        font-size: 12px;
      }
      &-name {
        font-weight: $cerebri-heavy;
        font-size: 18px;
      }
    }
  }
  &-add-form {
    margin-top: $spacer * 2;
    margin-left: ($spacer * 3) + 50px;
    &-row {
      @include flexbox(row, center, flex-start);
      position: relative;
      // gap: $spacer;
      &-close {
        position: absolute;
        left: -42px;
        top: 50%;
        margin-top: -15px;
        width: 30px;
        height: 30px;
        display: block;
        @include flexbox(row, center, center);
        cursor: pointer;
        // margin-left: $spacer;
        color: $color-gray-fog;
        border-radius: 100px;
        &:hover {
          background-color: $color-gray-ice;
          color: $color-gray-midnight;
        }
        svg.icon {
          width: 14px;
          stroke-width: 2px;
        }
      }
    }
    &-feed {
      width: 280px;
      min-width: unset;
    }
    &-type {
      width: 120px;
      min-width: unset;
      margin-left: -1px !important;
    }
  }
}



/*
 *  Modal Post Form
 */

.spost-form {
  & > .modal-box {
    width: 650px;
  }
  &-user {
    @include flexbox(row, center, flex-start);
    gap: $spacer;
    &-avatar {
      width: 60px;
      height: 60px;
      border: 1px solid white;
      border-radius: 100px;
      box-shadow: 0px 2px 4px rgba(0,0,0,0.12);
    }
    &-text {
      &-name {
        font-weight: $cerebri-heavy;
        font-size: 16px;
      }
      &-aud {
        @include flexbox(row, center, flex-start);
        gap: $spacer * 0.5;
        text-decoration: none !important;
        color: $color-text-medium-1-5;
        margin-top: $spacer * 0.5;
        svg.icon {
          width: 18px;
        }
        &-show { display: block; }
        &-edit { display: none; }
        &:hover {
          color: $color-accent-pink;
          .spost-form-user-text-aud-edit { display: block; }
          .spost-form-user-text-aud-show { display: none; }
        }
      }
    }
  }

  &-text {
    width: 100%;
    border: none;
    outline: none;
    padding: 0;
    resize: none;
    font-size: 19px;
    line-height: 26px;
    height: 0;
    min-height: 78px;
    font-weight: $cerebri-heavy;
    color: $color-text-default;
    margin-top: $spacer * 2;
    margin-bottom: 0;
    // border: 1px solid blue;
    // background-color: $color-highlight-blue;
    &::placeholder {
      // color: green;
      color: $color-text-faint;
    }
    &.sz-s {
      font-size: 14px;
      line-height: 18px;
      min-height: 54px;
      font-weight: $cerebri-bold;
    }
  }

  &-main-img {
    margin-top: $spacer * 2;
    position: relative;
    &-menu {
      opacity: 0;
      transition: all 200ms;
      width: 30px;
      height: 30px;
      background-color: white;
      position: absolute;
      inset: 6px 0 0 6px;
      border-radius: 100px;
    }
    &:hover {
      .spost-form-main-img-menu {
        opacity: 1;
      }
    }
    &-count {
      display: block;
      text-align: right;
      font-style: italic;
      color: $color-gray-slate;
      position: absolute;
      bottom: 1px;
      right: 1px;
      background-color: white;
      padding: 0px 4px;
      opacity: 0.7;
    }
  }

  &-car {
    @include flexbox(row, flex-start, flex-start);
    gap: 4px;
    margin-top: 4px;
    overflow: auto;
    &-img-con {
      height: 60px;
      cursor: pointer;
      position: relative;
      &.active:before {
        display: block;
        content: '';
        position: absolute;
        border: 4px solid $color-gray-midnight;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
      }
    }
    &-img {
      height: 100%;
      display: block;
    }
  }

  &-url {
    margin-top: $spacer * 2;
    position: relative;
    &-menu {
      opacity: 0;
      transition: all 200ms;
      width: 30px;
      height: 30px;
      background-color: white;
      position: absolute;
      inset: 12px 0 0 12px;
      border-radius: 100px;
    }
    &:hover {
      .spost-form-url-menu {
        opacity: 1;
      }
    }
  }

  &-mii {
    margin-top: $spacer * 2;
  }
}



/*
 *  URL Preview
 */

.url-preview {
  background-color: $color-gray-cloud;
  // padding: 4px;
  display: block;
  // border: 2px solid $color-gray-cloud;
  border-radius: 2px;
  transition: all 200ms;
  text-decoration: none !important;
  overflow: hidden;
  &:hover:not(.no-click) {
    // border-color: $color-accent-pink;
    box-shadow: 0 0 0 2px $color-accent-pink;
  }
  &.no-click {
    cursor: default;
  }
  &-img {
  }
  &-text {
    // padding-left: $spacer * 0.5;
    padding: ($spacer * 0.5);
    // margin-bottom: ($spacer * 0.5) - 2px;
    & > * {
      font-size: 12px;
      line-height: 14px;
      height: 14px;
      overflow: hidden;
      display: block;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    &-host {
      text-transform: uppercase;
      color: $color-text-medium-1-5;
      // font-size: 12px;
    }
    &-title {
      font-weight: $cerebri-bold;
      color: $color-text-default;
    }
    &-desc {
      // color: $color-text-medium-1-5;
      color: $color-text-default;
    }
  }
}



/*
 *  Post Card
 */

.spost-card {
  width: 320px;
  box-shadow: $shadow;
  border-radius: 2px;
  background-color: white;
  // padding: $spacer;
  // padding-bottom: ($spacer * 2);
  position: relative;
  transition: all 200ms;

  &:has(&-click:hover) {
    transform: translateY(-4px);
    &:not(:has(a:hover)) {
      box-shadow: 2px 1px 10px 1px rgba(0,0,0,0.25);
    }
  }

  // &:hover:not(.no-click):not(:has(.modal:hover)) {
  //   transform: translateY(-4px);
  //   &:not(:has(a:hover)) {
  //     box-shadow: 2px 1px 10px 1px rgba(0,0,0,0.25);
  //   }
  // }

  &-main {
    padding: $spacer;
  }

  &-click {
    position: relative;
    &:hover {
      .spost-card-pin.can-toggle {
        // opacity: 0.8;
        display: flex;
      }
    }
  }

  &-pin {
    border: 1px solid $color-gray-cloud;
    position: absolute;
    display: block;
    right: $spacer * 1.25;
    top: $spacer * 1.25;
    background-color: white;
    width: 32px;
    height: 32px;
    border-radius: 100px;
    z-index: 1;
    padding: 0;
    @include flexbox(row, center, center);
    display: none;
    opacity: 0.6;
    &.pinned {
      display: flex;
      opacity: 1;
    }
    svg.icon {
      width: 20px;
      display: block;
    }
    &.can-toggle:hover {
      border-color: $color-accent-pink;
      color: $color-accent-pink;
      cursor: pointer;
      &:not(.pinned) {
        opacity: 0.8;
      }
    }
  }

  &-fake-input {
    border: 1px solid $color-gray-cloud;
    height: 45px;
    width: 100%;
    text-align: left;
    margin-top: $spacer;
    padding: 0 12px;
    border-radius: 2px;
    font-size: 14px;
    color: $color-text-faint;
    transition: all 200ms;
    cursor: pointer;
    &:hover {
      // border-color: $color-gray-midnight;
      border-color: $color-gray-slate;
    }
    &-con {
      padding: 0 $spacer;
    }
  }

  &-img {
    display: block;
    width: 100%;
  }

  &-carousel {
    @include flexbox(row, flex-start, flex-start);
    margin-top: 2px;
    gap: 2px;
    overflow: hidden;
    position: relative;
    height: 30px;
    &-img {
      display: block;
      height: 100%;
    }
    &-count {
      height: 100%;
      line-height: 30px;
      padding: 0 ($spacer * 1);
      background-color: rgba(255,255,255,0.8);
      position: absolute;
      top: 0;
      right: 0;
      font-weight: $cerebri-bold;
    }
  }

  &-body {
    font-size: 14px;
    line-height: 18px;
    overflow: hidden;
    max-height: 200px;
    margin-top: $spacer;
    &.large {
      font-size: 19px;
      line-height: 23px;
      font-weight: $cerebri-heavy;
      .spost-card-body-p {
        min-height: 23px;
      }
    }
    &-p {
      margin: 0;
      min-height: 19px;
      a {
        word-break: break-all;
      }
    }
    // &:not(:first-child) {
    //   margin-top: $spacer;
    // }
  }

  &-body + &-foot {
    border-top: 1px solid $color-gray-cloud;
    padding-top: $spacer;
  }

  &-foot {
    @include flexbox(row, center, flex-start);
    gap: $spacer * 0.5;
    padding: $spacer;
    padding-bottom: $spacer * 2;
    // margin-top: $spacer;
    &-img {
      width: 32px;
      height: 32px;
      display: block;
      box-sizing: border-box;
      border: 1px solid white;
      object-fit: cover;
      border-radius: 100px;
      box-shadow: 0px 2px 4px rgba(0,0,0,0.12);
    }
    &-det {
      flex: 1;
      &-name {
        font-size: 14px;
        line-height: 16px;
        font-weight: $cerebri-heavy;
      }
      &-date {
        font-size: 12px;
        line-height: 14px;
        color: $color-text-medium-1-5;
        text-decoration: none !important;
        &:hover {
          color: $color-accent-pink;
        }
      }
    }
    &-com {
      @include flexbox(row, center, flex-end);
      flex-shrink: 0;
      font-size: 14px;
      font-weight: $cerebri-heavy;
      gap: 4px;
      svg.icon {
        width: 20px;
      }
    }
  }

  &-react {
    position: absolute;
    right: $spacer;
    bottom: -14px;
  }
}
.spskel {
  opacity: 0.3;
  &-img {
    margin: $spacer;
  }
  &-text {
    margin: $spacer;
    // border: 1px solid red;
  }
  &.variant-1 {
    .spskel-img {
      background: radial-gradient(ellipse at top right, rgba($color-accent-green, 0.3), transparent, transparent),
        radial-gradient(ellipse at bottom left, rgba($color-accent-purple, 0.3), transparent, transparent);
      height: 342px;
    }
  }
  &.variant-2 {
    .spskel-img {
      height: 196px;
      background: radial-gradient(ellipse at bottom left, rgba($color-accent-green, 0.3), transparent, transparent),
    }
  }
  &.variant-3 {
    .spskel-img {
      height: 196px;
      background: radial-gradient(ellipse at top right, rgba($color-accent-purple, 0.3), transparent, transparent),
    }
  }
}



/*
 *  Post Card Reaction Set
 */

.spost-react {
  height: 28px;
  @include flexbox(row, flex-start, flex-end);
  gap: $spacer * 0.5;
  // border: 1px solid lime;

  &-pop-emopick {
    padding: 0 !important;
  }

  &-emojis {
    @include flexbox(row, flex-start, flex-end);
    // overflow: auto;
    flex-wrap: wrap;
    gap: $spacer * 0.5;
    // border: 1px solid red;
    flex: 1;
    max-height: 62px;
    overflow: hidden;
  }
  &-emoji {
    border: 1px solid $color-gray-cloud;
    border-radius: 100px;
    background-color: white;
    @include flexbox(row, center, center);
    height: 28px;
    gap: 3px;
    padding: 0 6px;
    transition: all 200ms;
    cursor: pointer;
    &-char {
      font-size: 17px;
    }
    &-icon {
      width: 20px;
      color: $color-gray-fog;
      transition: all 200ms;
    }
    &:hover:not(.active) {
      border-color: $color-gray-midnight;
      .spost-react-emoji-icon {
        color: $color-gray-midnight;
      }
    }
    &.add {
      padding: 0;
      width: 28px;
      flex-shrink: 0;
    }
    &.active {
      border-color: $color-accent-blue;
      background-color: $color-highlight-blue;
    }
  }
}



/*
 *  Masonry
 */

.smasonry {
  padding-bottom: 14px; // account for reactions
  &-hidden {
    border: 1px solid green;
    position: fixed;
    left: 200%;
  }
  &-cols {
    @include flexbox(row, flex-start, center);
    gap: $spacer * 2;
    &-col {
      @include flexbox(column, center, flex-start);
      gap: $spacer * 2;
      min-width: 320px;
    }
  }
}



/*
 *  Post View
 */

.spost-vw {
  border: 1px solid $color-gray-cloud;
  border-radius: 2px;
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  // @include flexbox(row, stretch, flex-start);
  // max-height: 90vh;

  &-deleted {
    padding: $spacer * 2;
    text-align: center;
  }

  &.has-media {
    width: 1300px;
  }

  &-modal {
    padding-top: $spacer;
    padding-bottom: $spacer * 6;
    .modal-box {
      max-width: 100%;
      // max-height: 100%;
      // .spost-vw {
      //   max-height: 100%;
      // }
      &-close {
        z-index: 1;
      }
    }
  }

  &-main {
    flex: 1;
    // border-right: 1px solid lime;
    padding: $spacer * 2;
    padding-bottom: ($spacer * 2) + 14px;
    // overflow: auto;
    margin-right: 400px;
    position: relative;
    min-height: 600px;
    // border-right: 1px solid $color-gray-cloud;
    &-head {
      // border-bottom: 1px solid $color-gray-cloud;
      @include flexbox(row, center, flex-start);
      gap: $spacer;
      &-avatar {
        display: block;
        border-radius: 100px;
        width: 62px;
        height: 62px;
        border: 1px solid white;
        object-fit: cover;
        box-shadow: 0px 2px 4px rgba(0,0,0,0.12);
      }
      &-text {
        flex: 1;
        @include flexbox(column, flex-start, center);
        &-name {
          font-size: 16px;
          font-weight: $cerebri-heavy;
        }
        &-date {
          color: $color-text-medium-1-5;
          text-decoration: none !important;
          &:hover {
            color: $color-accent-pink;
          }
        }
      }
      &-menu {
        align-self: flex-start;
      }
    }
    &-body {      
      font-size: 14px;
      line-height: 18px;
      min-height: 18px;
      font-weight: $cerebri-bold;
      margin-top: $spacer * 2;
      overflow: hidden;
      & > p {
        min-height: 18px;
        margin: 0;
      }
      &.large {
        font-size: 19px;
        line-height: 26px;
        min-height: 26px;
        font-weight: $cerebri-heavy;
        & > p {
          min-height: 26px;
        }
      }
    }
    &-imgs {
      margin-top: $spacer * 2;
      &.multi {
        .spost-vw-main-imgs-crnt {
          aspect-ratio: 1;
          max-height: 95vh;
        }
      }
      &-crnt {
        // border: 1px solid green;
        @include flexbox(row, center, center);
        margin: 0 auto;
        &-img {
          display: block;
          max-width: 100%;
          max-height: 100%;
        }
      }
      &-car {
        @include flexbox(row, center, flex-start);
        gap: 4px;
        margin-top: 4px;
        height: 60px;
        // border: 1px solid red;
        overflow: auto;
        &-img {
          display: block;
          height: 100%;
          position: relative;
          // &:first-child {
          //   margin-left: auto;
          // }
          // &:last-child {
          //   margin-right: auto;
          // }
          & > img {
            max-height: 100%;
            display: block;
          }
          &.active:before {
            display: block;
            content: '';
            position: absolute;
            border: 4px solid $color-gray-midnight;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
          }
        }
      }
    }
    &-url {
      margin-top: $spacer * 2;
    }
    &-react {
      position: absolute;
      right: $spacer * 1;
      left: $spacer * 2;
      // border: 1px solid lime;
      bottom: -14px;
    }
  }

  &-cmnt {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 400px;
    border-left: 1px solid $color-gray-cloud;
    // padding: ($spacer * 2) 0;
    overflow: auto;
  }

  @include media("<large") {
    &-main {
      margin-right: 0;
      min-height: unset;
      &-head {
        &-text {
          flex: none;
          padding-right: $spacer * 2;
        }
        &-menu {
          align-self: center;
        }
      }
      &-react {
        position: static;
        margin: ($spacer * 2) 0;
        // margin-right: -14px;
      }
    }
    &-cmnt {
      position: static;
      border-top: 1px solid $color-gray-cloud;
      border-left: none;
      max-width: 100vh;
      // margin-top: 100px;
      width: 100%;
    }
  }
}



/*
 *  Comments
 */

.scoms {
  @include flexbox(column, stretch, flex-start);
  // border: 1px solid lime;
  height: 100%;
  // max-height: 800px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  // border: 1px solid red;
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    background: linear-gradient(to top, white, transparent);
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    // margin-top: 1px;
    z-index: 1;
  }
  &-heading {
    padding: ($spacer * 2);
    font-style: italic;
    // padding-bottom: $spacer;
    text-align: center;
    color: $color-text-medium-1-5;
    // border-bottom: 1px solid $color-gray-cloud;
  }
  &-list {
    // border: 1px solid blue;
    flex: 1;
    overflow: auto;
    padding: ($spacer * 2) 0;
    &-con {
      // padding-top: $spacer;
    }
    &-controls {
      margin-top: $spacer;
      @include flexbox(row, center, center);
    }
    &-loading {
      width: 24px;
      color: $color-gray-fog;
    }
  }
  &-new {
    // border: 1px solid red;
    border-bottom: 1px solid $color-gray-cloud;
    padding: 0 0 ($spacer * 2);
    margin: 0 ($spacer * 2);
    // padding-top: $spacer;
    position: relative;
    z-index: 1;
    &:before {
      content: '';
      display: block;
      position: absolute;
      background: linear-gradient(to bottom, white, transparent);
      top: 100%;
      left: 0;
      width: 100%;
      height: 20px;
      margin-top: 1px;
    }
    &-row {
      @include flexbox(row, flex-start, flex-start);
      gap: $spacer;
    }
    &-controls {
      padding-top: $spacer * 0.5;
      @include flexbox(row, center, flex-end);
      display: none;
    }
    &:focus-within, &.has-body {
      .scoms-new-controls {
        display: flex;
      }
    }
    &.has-body textarea {
      height: 100px !important;
    }
    &-avatar {
      width: 30px;
      height: 30px;
      border: 1px solid white;
      border-radius: 100px;
      box-shadow: 0px 2px 4px rgba(0,0,0,0.12);
    }
    &-input {
      flex: 1;
      margin-bottom: 0 !important;
      textarea {
        height: 45px !important;
        &:focus {
          height: 100px !important;
        }
      }
    }
  }
}

.scom {
  border-bottom: 1px solid $color-gray-cloud;
  padding-bottom: $spacer;
  margin: 0 ($spacer * 2);
  position: relative;
  & + & {
    margin-top: $spacer * 1.5;
  }
  &-menu {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    transition: all 200ms;
  }
  &:hover {
    .scom-menu {
      opacity: 1;
    }
  }
  &-main {
    @include flexbox(row, flex-start, flex-start);
    gap: $spacer;
  }
  &-avatar {
    width: 30px;
    height: 30px;
    border: 1px solid white;
    border-radius: 100px;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.12);
    flex-shrink: 0;
  }
  &-text {
    flex: 1;
    &-name {
      font-weight: $cerebri-bold;
      // margin-top: 5px;
      display: block;
    }
    &-date {
      // margin-top: $spacer;
      display: block;
      color: $color-text-medium-1-5;
    }
    &-body {
      margin-top: $spacer * 0.5;
      p {
        margin: 0;
        line-height: 18px;
        min-height: 9px;
      }
      .standard-input {
        width: 100%;
        margin-bottom: 0;
      }
      &-controls {
        @include flexbox(row, center, flex-end);
        margin-top: $spacer * 0.5;
      }
    }
  }
  &-react {
    margin-top: $spacer;
  }
  // margin: $spacer 0;
}



/*
 *  Feed
 */

.sfeed {
  &-header-con {
    height: 122px;
    position: relative;
    &.static-true {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 200;
      display: none;
      &.show {
        display: block;
        .sfeed-header-text-title {
          font-weight: $cerebri-heavy;
          font-size: 24px;
        }
        .sfeed-header-text-subtitle {
          font-size: 14px;
        }
      }
    }
  }
  &-header {
    background-color: white;
    height: 100%;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -50vw;
    border: 1px solid #F5F5F5;
    border-width: 1px 0;
    // box-shadow: 0 1px 10px 1px rgba(0,0,0,.09);
    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 12px;
      background: linear-gradient(to bottom, rgba(0,0,0,0.03), transparent);
    }
    &-widther {
      max-width: 1008px;
      margin: 0 auto;
      @include flexbox(row, center, flex-start);
      gap: $spacer * 2;
      height: 100%;
    }
    &-img {
      display: block;
      max-width: 200px;
      height: 74px;
      object-fit: contain;
    }
    &-text {
      flex: 1;
      &-title {
        font-weight: $cerebri-bold;
        transition: all 200ms;
      }
      &-subtitle {
        font-weight: $cerebri-light;
        text-transform: uppercase;
        font-size: 24px;
        transition: all 200ms;
      }
    }
  }
  &-first-prompt {
    text-align: center;
    font-weight: $cerebri-bold;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: $spacer * 2;
  }
  &-masonry {
    // border: 1px solid red;
    position: relative;
    // margin-top: $spacer * 4;
    &:after {
      display: block;
      content: '';
      position: absolute;
      bottom: -14px;
      left: 0;
      width: 100%;
      height: 200px;
      // border: 1px solid lime;
      background: linear-gradient(to top, white, transparent);
    }
    &-controls {
      // border: 1px solid blue;
      @include flexbox(row, center, center);
      z-index: 1;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      gap: $spacer * 0.5;
    }
  }
  &.exhausted {
    .sfeed-masonry:after {
      display: none;
    }
    .sfeed-masonry-controls {
      position: static;
      margin-top: $spacer * 2;
    }
  }
  &-prompt {
    @include flexbox(column, center, center);
    &-msg {
      margin: 0;
    }
    gap: $spacer * 1.5;
    margin-top: $spacer * 3;
  }
}
