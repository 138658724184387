.page-vol {

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: ($spacer * 4) 0;
    justify-content: space-between;

    &-buttons {
      @include flexbox(row, center, flex-end);
      gap: $spacer;
      margin-left: $spacer;
      flex-wrap: wrap;
    }

    &-heading {
      @extend .gradient-orange-text;
      display: inline;
      font-weight: $cerebri-heavy;
      font-size: 44px;
      margin: 0;
    }

    &-stats {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      flex: 1;

      &-stat {
        padding: 0 ($spacer * 2);
        &:first-child {
          border-right: 1px solid $color-gray-cloud;
        }
        &-val {
          font-weight: $cerebri-heavy;
          font-size: 28px;
          @extend .gradient-orange-text;
        }
        &-key {
          text-transform: uppercase;
          font-size: 12px;
          font-weight: $cerebri-bold;
          color: $color-text-faint;
        }
      }
    }
  }

  &-loading {
    width: 32px;
    display: block;
    color: $color-gray-fog;
    margin: ($spacer * 8) auto;
  }

  &-cat-buttons {
    margin: ($spacer * 4) auto;
  }

  &-cat {
    @include flexbox(column, center, flex-start);
    &-selected {
      @include flexbox(row, center, flex-start);
      border-radius: 100px;
      padding: 0 $spacer;
      padding-left: $spacer * 2;
      margin: 0 auto;
      gap: $spacer;
      color: white;
      font-weight: $cerebri-heavy;
      height: 45px;
      &-icon {
        width: 24px;
        stroke-width: 1.5px;
      }
      &-name {
        max-width: 180px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &-clear {
        margin-left: $spacer;
        border-radius: 100px;
        width: 24px;
        height: 24px;
        text-align: center;
        position: relative;
        transition: all 200ms;
        cursor: pointer;
        &:hover {
          background-color: rgba(0,0,0,0.2);
        }
        svg.icon {
          position: absolute;
          top: 6px;
          left: 6px;
          width: 12px;
          color: white;
        }
      }
    }
  }

  &-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: ($spacer * 2) 0;
    &-spacer {
      flex: 1;
    }
    &-dropdowns {
      .dd {
        margin-left: $spacer;
      }
    }
    &-search {
      position: relative;
      input {
        background-color: $color-gray-ice;
        border: 1px solid $color-gray-ice;
        height: 45px;
        border-radius: 100px;
        font-size: inherit;
        padding: 0 $spacer 0 (($spacer * 2) + 18px);
        outline: none;
        width: 300px;
        &::placeholder {
          color: $color-gray-slate;
        }
        &:focus {
          border-color: $color-accent-blue;
        }
      }
      &-icon {
        width: 18px;
        color: $color-gray-slate;
        position: absolute;
        top: 50%;
        margin-top: -9px;
        left: $spacer * 1.5;
      }
    }
  }

  &-results {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &-result {
      width: 50%;
      padding-bottom: $spacer * 2;
      &:nth-child(odd) {
        padding-right: $spacer;
      }
      &:nth-child(even) {
        padding-left: $spacer;
      }
    }
    &-empty {
      &-h2 {
        font-weight: $cerebri-regular;
        font-size: 30px;
        @include flexbox(row, center, center);
        gap: $spacer;
        svg.icon {
          width: 30px;
        }
      }
      text-align: center;
      margin: ($spacer * 8) auto;
    }
    &-pagination {
      margin-top: $spacer * 2;
    }
    &-spacer {
      height: 400px;
    }
  }

  &-dd-filter {
    width: 260px;
    &-distance {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: ($spacer * 1.5) $spacer ($spacer * 0.75);
      .checkbox {
        margin-right: calc($spacer / 2);
      }
      .input-distance {
        width: 35px;
      }
    }
    &-city {
      margin: 0 $spacer;
    }
    &-sep {
      margin: 0 $spacer;
      border-top: 1px solid $color-gray-cloud;
    }
    &-company {
      padding: $spacer;
      &-cb-row {
        @include flexbox(row, center, flex-start);
        gap: $spacer * 0.5;
      }
      &-logo {
        max-width: 100%;
        display: block;
        max-height: 50px;
        margin-top: $spacer * 0.5;
      }
    }
    &-apply {
      min-width: 150px;
      display: block;
      margin: ($spacer * 1.15) auto;
    }
    &-tags {
      padding: $spacer;
      &-tag {
        @include flexbox(row, center, flex-start);
        gap: $spacer;
        &:not(:last-child) {
          margin-bottom: $spacer;
        }
        &-icon {
          width: 20px;
        }
        label {
          @include flexbox(row, center, flex-start);
          gap: $spacer;
        }
      }
    }
  }

  @include media("<large") {
    &-header {
      margin: ($spacer * 2) 0;
      &-heading {
        font-size: 30px;
      }
      &-stats {
        display: none;
      }
    }
    &-cat {
      margin-top: $spacer * 1.5;
    }
    &-options {
      flex-direction: column;
      &-dropdowns {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: $spacer * 1.5;
        .dd {
          width: 100%;
          margin: 0;
          margin-top: calc($spacer / 2);
        }
      }
    }
    &-results {
      &-result {
        &:nth-child(even), &:nth-child(odd) {
          padding-left: 0;
          padding-right: 0;
        }
        width: 100%;
      }
    }
  }

}

.vol-track-form {
  &-row1, &-row2 {
    @include flexbox(row, center, flex-start);
    margin-bottom: $spacer;
    &-left {
      margin-right: calc($spacer / 2);
    }
    &-right {
      margin-left: calc($spacer / 2);
    }
  }
  &-row1 {
    &-left  { flex: 1; }
    &-right { flex: 2; }
  }
  &-row2 {
    &-left  { flex: 2; }
    &-right { flex: 1; }
  }
  &-by {
    &-option {
      &:not(.selected) {
        padding: $spacer;
      }
    }
  }
  &.type-event {
    .vol-track-form-row1, .vol-track-form-row2 {
      &-left, &-right {
        margin: 0;
      }
    }
  }
  .standard-input, .auto-complete-input, .dd {
    margin: 0;
    width: 100%;
    height: 45px;
  }
  .btn.special {
    width: 100%;
  }
  @include media("<medium") {
    &-row1, &-row2 {
      display: block;
      margin-bottom: 0;
      &-left, &-right {
        margin: 0;
        margin-bottom: $spacer;
      }
    }
  }
}

.modal-vte {
  &-p {
    margin: 0;
    margin-bottom: $spacer;
  }
  &-recents-table {
    width: 100%;
    td, th {
      padding: calc($spacer / 4) calc($spacer / 2);
      border-bottom: 1px solid $color-gray-cloud;
      text-align: left;
      &.right {
        text-align: right;
      }
      &.nowrap {
        white-space: nowrap;
      }
    }
  }
}
