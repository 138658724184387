.ca-mes {
  // max-width: 600px;

  &-selected {
    border: 1px solid $color-gray-cloud;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;

    &-head {
      @include flexbox(row, center, flex-start);
      padding: $spacer;
      &-msg {
        font-weight: $cerebri-bold;
        flex: 1;
      }
      &-btns {
        @include flexbox(row, center, center);
      }
    }
    &-emps {
      padding: $spacer;
      padding-top: 0;
      padding-bottom: calc($spacer / 1);
      max-height: 150px;
      overflow: auto;
      &-emp {
        font-weight: $cerebri-bold;
        padding: 4px 10px;
        font-size: 12px;
        background-color: $color-gray-cloud;
        border-radius: 100px;
        display: inline-block;
        position: relative;
        margin: 0 calc($spacer / 2) calc($spacer / 2) 0;

        &:hover svg.icon {
          display: block;
        }

        svg.icon {
          display: none;
          position: absolute;
          right: 2px;
          top: 50%;
          margin-top: -10px;
          width: 20px;
          cursor: pointer;
          background-color: white;
          border: 3px solid white;
          border-radius: 100px;
          g {
            stroke-width: 2px;
          }
        }
      }
    }
  }

  &-many {
    .standard-input {
      margin-bottom: 0;
    }
    &-btns {
      @include flexbox(row, center, flex-end);
      padding-bottom: calc($spacer / 2);
      & > .text-btn {
        margin-left: $spacer;
      }
    }
    &-cta {
      @include flexbox(row, flex-start, flex-end);
      flex-wrap: wrap;
      &-msg {
        flex: 1;
        padding: 0 calc($spacer / 2);
        margin-top: calc($spacer / 2);
        min-width: 222px;
      }
      &-actions {
        @include flexbox(row, center, flex-end);
        margin-top: calc($spacer / 2);
        & > .btn {
          margin-left: calc($spacer / 2);
        }
      }
    }
  }

  &-single {
    @include flexbox(row, center, flex-end);
    &-emp-input.auto-complete-input {
      flex: 1;
      margin-bottom: 0;
    }
    &-or {
      margin: 0 calc($spacer / 1);
      font-weight: $cerebri-bold;
      color: $color-text-medium-1;
    }
  }

  &.has-selection {
    .ca-mes-inputs-con {
      padding: calc($spacer / 1);
      background-color: $color-gray-ice;
      border: 1px solid $color-gray-cloud;
      border-top: none;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }

}
