.page-gifts-redeem {

  &-title {
    font-size: 18px;
    font-weight: $cerebri-heavy;
    margin: 0 0 ($spacer * 2);
  }

  .redeem-padder {
    padding: 200px 0;
  }

  .redeem-box {
    width: 450px;
    background-color: white;
    margin: 0 auto;
    padding: $spacer * 4;
    box-shadow: 2px 1px 10px 1px rgba(0,0,0,0.09);

    button {
      width: 100%;
    }
  }

  @include media("<medium") {
    .redeem-padder {
      padding: 40px 0;
    }
  }

}
