.page-bgame {

  &-main {
    padding-bottom: ($spacer * 8) !important;
  }

  &-status {
    text-align: center;
    margin: ($spacer * 4) 0;
    &-heading {
      font-weight: $cerebri-heavy;
      font-size: 28px;
      margin: 0;
    }
    &-cd {
      margin-top: $spacer * 1.5;
      justify-content: center;
    }
    &-next-btn {
      margin-top: $spacer * 2;
    }
  }

  &-cols {
    @include flexbox(row, flex-start, space-evenly);

    &-left, &-right {
      width: 516px;
    }
  }

  &-highlight {
    background-color: white;
    box-shadow: 2px 1px 12px 3px rgba(0,0,0,0.09);
    padding: 2px;
    transition: all 500ms;
    &.voted {
      background-color: $color-accent-green;
      background: $gradient-green;
    }
    &.winner {
      background-color: $color-accent-purple;
      background: $gradient-purple;
    }
    &-padder {
      background-color: white;
    }
  }

  &-choice {
    // background-color: white;
    // box-shadow: 2px 1px 12px 3px rgba(0,0,0,0.09);
    text-align: center;
    height: 264px;
    @include flexbox(column, center, center);

    &-logo {
      @include flexbox(column, center, center);
      width: 160px;
      height: 50px;
      // border: 1px solid #ccc;

      &-unknown {
        height: 0px;
        width: 100%;
        background-color: $color-gray-fog;
        box-shadow: 0 0 20px 20px $color-gray-fog;
      }
      &-known {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
      }
      &-icon {
        height: 100%;
      }
    }
    &-text {
      margin: 0;
      padding: ($spacer * 2) ($spacer * 4);
    }
    &-btn {
      &.voted {
        opacity: 1 !important;
      }
    }
    &-actions {
      @include flexbox(row, center, center);
      align-self: stretch;
      margin: 0 ($spacer * 3);
      &-profile {
        display: none;
      }
    }
  }

  &-profile {
    margin-top: $spacer * 2;
  }

  @include media("<medium") {
    &-cols {
      @include flexbox(column, center, flex-start);
      &-left, &-right {
        width: 100%;
      }
      &-right {
        margin-top: $spacer * 4;
      }
    }
    &-profile {
      display: none;
    }
    &-choice {
      &-actions {
        justify-content: space-between;
        &-profile {
          display: inline-block;
        }
      }
    }
  }

}
