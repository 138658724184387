.modal-manual-bank-verify {

  .modal-box {
    width: 600px;
    padding: $spacer * 2;
  }

  &-heading {
    margin: 0 0 ($spacer * 2) 0;
    font-size: 21px;
    font-weight: $cerebri-heavy;
  }

  &-section-heading {
    margin: 0 0 ($spacer * 1.5) 0;
    font-size: 15px;
    font-weight: $cerebri-regular;
    text-transform: uppercase;
  }

  &-success-heading {
    margin: ($spacer * 2) 0;
    text-align: center;
    font-size: 31px;
    font-weight: $cerebri-heavy;
  }

  &-section {
    margin-top: $spacer * 1.5;
    padding: $spacer * 1.5;

    background-color: $color-gray-ice;
  }

  &-example {
    margin-left: $spacer * 2;
  }

  &-cols {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: flex-end;
  }

  &-pad-right {
    padding-right: $spacer * 3;
  }

  &-info-inputs {
    flex: 1;
  }

  &-flex-expand {
    flex: 1;
  }

  &-check-message {
    margin: ($spacer * 1.5) 0;
    padding: 0 ($spacer * 3);
    text-align: center;
    font-weight: $cerebri-bold;
  }

  &-deposit-label {
    text-align: center;
    font-weight: $cerebri-bold;
    margin-bottom: calc($spacer / 2);
  }

  &-deposits .standard-input {
    width: 75px;
  }

  &-lock-message {
    color: $color-danger;
  }

  &-lock-message:last-of-type {
    margin-bottom: 0;
  }

  &-lock-message a {
    margin: 0 0.2em;
    color: $color-danger;
    text-decoration: underline;
  }
  
  &-fail-message {
    margin-top: ($spacer * 0.5);
    text-align: right;
  }
  
  &-fail-message.validation-message a {
    margin: 0 0.2em;
    color: $color-danger;
    text-decoration: underline;
  }
  
  &-success {
    padding: ($spacer * 3.5) ($spacer * 4) ($spacer * 1.5) ($spacer * 3);
    text-align: center;
  }

  &-success p {
    margin: ($spacer * 2) 0;
    text-align: left;
    font-size: 15px;
  }

  .standard-input:last-of-type {
    /* The design vertically-offsets the buttons by one-half of a button height,
     * relative to the input boxes: */
    margin-bottom: 22px;
  }

  .btn {
    min-width: 160px;
    margin-left: $spacer * 3;
  }

  /* Would be nice to use '*' here, but that catches the button text. Not worth
   * a lot of effort: */
  &-disabled h5,
  &-disabled div,
  &-disabled p {
    color: $color-text-faint;
  }
}
