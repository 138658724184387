.ca-brkt-edit {
  padding-bottom: $spacer * 8;
  background-color: $color-gray-ice;

  .required-dot {
    content: '';
    display: block;
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: $color-red;
    width: 10px;
    height: 10px;
    border-radius: 100px;
  }

  &-row1 {
    padding: ($spacer * 2) ($spacer * 4);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    &-name-input, &-name-h1 {
      margin: 0;
      border: none;
      border-bottom: 1px dashed $color-gray-slate;
      padding: 0;
      padding-bottom: 2px;
      font-weight: $cerebri-heavy;
      font-size: 26px;
      color: $color-accent-purple;
      outline: none;
      min-width: 100px;
      position: relative;
    }
    &-name-h1 {
      &.required {
        color: $color-text-faint;
        &:after {
          @extend .required-dot;
        }
      }
    }
    &-name-input {
      width: 300px;
      border-bottom: 1px solid $color-accent-blue;
    }
    &-controls {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      .time-picker {
        margin-left: calc($spacer / 2);
      }
      .timing-required {
        &:after {
          @extend .required-dot;
        }
      }
    }
  }

  &-row2 {
    padding: ($spacer * 2) ($spacer * 4);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $color-gray-ice;
    border-top: 1px solid $color-gray-cloud;

    &-pool {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $color-accent-blue;
      font-size: 20px;
      padding: calc($spacer / 2);
      margin: calc($spacer / -2);
      cursor: pointer;
      transition: all 200ms;
      &:hover {
        background-color: #E1EAF2;
      }
      &-skel {
        height: 28px;
        width: 140px;
        display: inline-block;
        background-color: #E1EAF2;
        margin-left: $spacer;
        position: relative;
        &:after {
          @extend .required-dot;
        }
      }
    }
  }

}



/*
 *  Pool (& Timing) Modal
 */

.ca-brkt-pool {
  .modal-box {
    width: 800px;
    padding: $spacer * 4;
  }
  &-heading {
    font-weight: bold;
    text-align: center;
    font-size: 24px;
    margin: 0;
  }
  &-section {
    padding-left: $spacer + 22px;
    position: relative;
    margin-top: $spacer * 4;
    &-icon {
      position: absolute;
      left: 0;
      top: 0;
      width: 22px;
      color: $color-accent-purple;
    }
    &-heading {
      text-transform: uppercase;
      font-weight: $cerebri-heavy;
      font-size: 14px;
    }
    .standard-input, .dd {
      display: inline-block;
      margin-right: $spacer;
    }
    &-inputs {
      @include flexbox(row, flex-start, flex-start);
      input[type=number] {
        width: 60px;
      }
    }
  }
  &-match {
    @include flexbox(row, center, flex-start);
    .ca-budget {
      margin-left: $spacer * 2;
    }
  }
  &-amount-input {
    width: 200px;
  }
  &-require-opt {
    border: 1px solid $color-gray-cloud;
    width: 420px;
    margin-top: $spacer;
    border-radius: 3px;
    padding: $spacer * 1.5;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all 200ms;
    opacity: 0.6;
    label {
      cursor: pointer;
    }
    .radio-button {
      margin-right: $spacer * 1.5;
      flex-shrink: 0;
    }
    &.selected {
      border-color: $color-accent-blue;
      opacity: 1;
    }
    &-title {
      font-weight: $cerebri-bold;
    }
    .ca-brkt-pool-amount-input {
      margin: $spacer 0 0 0;
    }
  }
  &-actions {
    margin-top: $spacer * 4;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    p {
      flex: 1;
      padding-left: $spacer + 22px;
    }
  }
}



/*
 *  Launch Modal
 */

.ca-brkt-launch {
  .modal-box {
    width: 800px;
    padding: $spacer * 4;
  }
  &-loading {
    display: block;
    width: 24px;
    color: $color-gray-fog;
    margin: ($spacer * 8) auto;
  }
  &-headings {
    text-align: center;
  }
  &-heading {
    font-weight: bold;
    font-size: 24px;
    margin: 0;
  }
  &-subheading {
    text-transform: uppercase;
    font-size: 17px;
    font-weight: $cerebri-light;
    @extend .rainbow-text;
    display: inline-block;
    margin: 0;
    margin-top: calc($spacer / 2);
  }
  &-details {
    margin-top: $spacer * 2;
    &-preview-link.pink-hover {
      @include flexbox(row);
      font-size: 13px;
      color: $color-text-medium-1-5;
      svg.icon {
        width: 10px;
        margin-left: calc($spacer / 2);
      }
    }
  }
  &-detail {
    @include flexbox(row, flex-start, flex-start);
    &-label {
      width: 140px;
      flex-shrink: 0;
      text-align: right;
      font-size: 14px;
      font-weight: $cerebri-heavy;
      text-transform: uppercase;
      padding-right: $spacer;
    }
    &-body {
      flex: 1;
      border-left: 1px solid $color-gray-cloud;
      padding-left: $spacer;
      padding-bottom: $spacer * 3;
      p {
        margin: 0;
        margin-top: $spacer;
        &:first-child {
          margin-top: 0;
        }
      }
      strong {
        color: $color-accent-purple;
      }
    }
    &-validations {
      font-size: 13px;
      font-weight: $cerebri-bold;
      color: $color-danger;
      margin: $spacer 0 0 $spacer;
    }
    &:last-child {
      .ca-brkt-launch-detail-body {
        padding-bottom: $spacer;
      }
    }
  }
  &-disclaim {
    background-color: #FFEEF6;
    margin-top: $spacer * 3;
    padding: ($spacer * 2) ($spacer * 4);
    text-align: center;
    &-edit {
      margin: 0;
      color: $color-accent-pink;
    }
    &-charge {
      margin: 0;
      font-weight: $cerebri-bold;
      margin-top: $spacer;
      border-top: 1px solid $color-gray-cloud;
      padding-top: $spacer;
    }
  }
  &-actions {
    @include flexbox(row, center, center);
    margin-top: $spacer * 3;
    .btn {
      margin: 0 calc($spacer / 2);
    }
    .btn.launch {
      text-transform: uppercase;
    }
  }
  &-success {
    text-align: center;
    &-heading {
      margin: 0;
      font-size: 27px;
      font-weight: $cerebri-heavy;
      @extend .rainbow-text;
      display: inline-block;
      margin-top: $spacer * 2;
    }
    &-subheading {
      margin: 0;
      margin-top: $spacer;
      font-size: 17px;
      font-weight: $cerebri-bold;
    }
    .gm-countdown {
      justify-content: center;
      margin-top: $spacer;
    }
  }
}



/*
 *  Collection Modal
 */

.ca-brkt-colm {
  .modal-box {
    width: 900px;
    padding: ($spacer * 4) ($spacer * 2);
  }
  &-headings {
    text-align: center;
  }
  &-heading {
    font-weight: bold;
    font-size: 24px;
    margin: 0;
  }
  &-subheading {
    text-transform: uppercase;
    font-size: 17px;
    font-weight: $cerebri-light;
    @extend .rainbow-text;
    display: inline-block;
    margin: 0;
    margin-top: $spacer;
  }
  &-desc {
    margin: 0 ($spacer * 2);
    p {
      margin: $spacer 0 0 0; 
    }
  }
  &-actions {
    @include flexbox(row, center, flex-end);
    margin-top: $spacer;
    margin-right: $spacer;
    &-count {
      font-style: italic;
    }
    .btn {
      margin-left: $spacer;
    }
  }
  &-nps {
    @include flexbox(row);
    flex-wrap: wrap;
    margin-top: $spacer;
    &-np {
      &:nth-child(odd) {
        border-right: 1px solid $color-gray-ice;
        padding-right: $spacer * 2;
      }
      &:nth-child(even) {
        padding-left: $spacer * 2;
      }
      @include flexbox(row);
      width: 50%;
      padding: $spacer;
      &-add {
        @include flexbox(column, center, center);
        cursor: pointer;
        width: 50px;
        svg.icon {
          width: 22px;
          margin-bottom: calc($spacer / 4);
        }
        font-size: 14px;
        text-transform: uppercase;
        font-weight: $cerebri-bold;
        &:hover {
          font-weight: $cerebri-bold;
          color: $color-accent-pink;
        }
      }
      &-card {
        flex: 1;
        margin-left: $spacer;
      }
      &.is-added {
        .ca-brkt-colm-nps-np-add {
          color: $color-accent-green;
          svg.icon g {
            fill: $color-accent-green;
            stroke: white;
          }
        }
      }
    }
  }
}

.ca-brackets {
  td.status {
    text-transform: uppercase;
    font-weight: $cerebri-bold;
    &.status-live { color: $color-accent-green; }
    &.status-upcoming { color: $color-accent-blue; }
    &.status-ended { color: $color-gray-slate; }
  }
  &-emps {
    text-align: center;
    font-size: 14px;
    width: 520px;
    margin: ($spacer * 4) auto;
    &-h4 {
      font-weight: $cerebri-bold;
      margin: 0;
      font-size: 16px;
    }
    &-box {
      border: 2px dashed $color-gray-slate;
      padding: ($spacer * 4) ($spacer * 2);
      margin: ($spacer * 2) 0;
      &-p {
        margin: 0;
        margin-bottom: $spacer * 2;
      }
    }
  }
}
