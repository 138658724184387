.toasts {
  position: fixed;
  bottom: $spacer * 2;
  right: $spacer * 2;
  width: 500px;
  z-index: 1000;

  &-toast {
    width: 100%;
    border: 0px solid black;
    border-left-width: 5px;
    background-color: $color-gray-midnight;
    color: white;
    margin-top: $spacer;
    padding: $spacer * 2;
    box-shadow: 2px 1px 10px 1px rgba(0,0,0,0.2);
    transition: all 200ms;
    margin-left: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;

    &-icon {
      width: 20px;
    }
    &-content {
      flex: 1;
      padding: 0 $spacer;
    }
    &-close {
      opacity: 0;
      padding: 8px;
      margin: -8px;
      border-radius: 100px;
      display: block;
      cursor: pointer;
      svg.icon {
        display: block;
        width: 12px;
        color: $color-gray-slate;
      }
      &:hover {
        background-color: black;
        svg.icon {
          color: white;
        }
      }
    }

    &:hover {
      .toasts-toast-close {
        opacity: 1;
      }
    }

    &.show {
      margin-left: 0;
    }

    &.info {
      border-color: $color-accent-blue;
      .toasts-toast-icon { color: $color-accent-blue; }
    }
    &.error {
      border-color: $color-danger;
      .toasts-toast-icon { color: $color-danger; }
    }
    &.success {
      border-color: $color-accent-green;
      .toasts-toast-icon { color: $color-accent-green; }
    }
  }
}
