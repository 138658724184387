.ca-top-line-stats {
  display: flex;
  flex-direction: row;

  &-stat {
    flex: 1;
    background-color: $color-gray-midnight;
    color: white;
    padding: $spacer * 1.5;
    text-align: right;
    position: relative;
    border-radius: 3px;
    svg.icon {
      position: absolute;
      width: 22px;
      top: $spacer * 1.5;
      left: $spacer * 1.5;
    }
    &-val {
      font-weight: $cerebri-heavy;
      font-size: 31px;
    }
    &-key {
      font-size: 16px;
      text-transform: uppercase;
    }
  }

  &.orange {
    .ca-top-line-stats-stat {
      background-color: $color-accent-orange;
      &:nth-child(1) { background-color: #FF4C4D; }
      &:nth-child(3) { background-color: #FF723D; }
      &:nth-child(5) { background-color: #FF8C2E; }
      &:nth-child(7) { background-color: #FFAB06; }
    }
  }
  &.pink {
    .ca-top-line-stats-stat {
      background-color: $color-accent-pink;
      &:nth-child(1) { background-color: #E30084; }
      &:nth-child(3) { background-color: #F332AB; }
      &:nth-child(5) { background-color: #FE5FC6; }
      &:nth-child(7) { background-color: #FF92E9; }
    }
  }
  &.blue {
    .ca-top-line-stats-stat {
      background-color: $color-accent-blue;
      &:nth-child(1) { background-color: #007FCE; }
      &:nth-child(3) { background-color: #00A5D8; }
      &:nth-child(5) { background-color: #00C1DF; }
      &:nth-child(7) { background-color: #00DAE5; }
    }
  }
  &.purple {
    .ca-top-line-stats-stat {
      background-color: $color-accent-purple;
      &:nth-child(1) { background-color: #783AC6; }
      &:nth-child(3) { background-color: #9543D5; }
      &:nth-child(5) { background-color: #AD4AE3; }
      &:nth-child(7) { background-color: #CA53F1; }
    }
  }
  &.groups {
    .ca-top-line-stats-stat {
      background-color: $color-groups-a;
      &:nth-child(1) { background-color: #5330B1; }
      &:nth-child(3) { background-color: #6050CB; }
      &:nth-child(5) { background-color: #6C6DE3; }
      &:nth-child(7) { background-color: #7485F8; }
    }
  }

  &-sep {
    width: $spacer * 1.5;
  }
}
