
.amount-selector {
  position: relative;

  &.disabled {
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 0; left: 0;
      width: 100%;
      height: 100%;
      background-color: white;
      opacity: 0.7;
      z-index: 1;
    }
  }

  &-amounts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &-option, &-special {
    cursor: pointer;
    outline: none;
    border-radius: 2px;
    width: 91px; height: 51px;
    margin: 0 8px 0 0;
    text-align: center;
    padding: 0;
  }

  &:not(.inline) {
    width: 289px;
    .amount-selector-option, .amount-selector-special {
      margin-bottom: 8px;
      &:nth-child(3) {
        margin-right: 0;
      }
    }
  }

  &-option {
    border: 1px solid $color-gray-midnight;
    background: none;
    font-size: 14px;
    font-weight: $cerebri-regular;
    line-height: 51px;

    &.selected {
      background-color: $color-accent-blue;
      border-color: $color-accent-blue;
      color: white;
      font-weight: $cerebri-heavy;
    }
  }

  &-special {
    background-color: $color-accent-green;
    border: none;
    padding: 1px;

    &.millennial {
      background-image: linear-gradient(to right, $color-sunrise-a, $color-sunrise-b);
      .amount-selector-special-label {
        color: $color-sunrise-a;
        @extend .sunrise-text;
      }
    }
    &.gen-x {
      background-image: linear-gradient(to right, $color-sunrise-b, $color-jungle-a);
      .amount-selector-special-label {
        color: $color-sunrise-b;
        @extend .sunset-text;
      }
    }
    &.baby-boomer {
      background-image: linear-gradient(to right, $color-jungle-a, $color-jungle-b);
      .amount-selector-special-label {
        color: $color-jungle-a;
        @extend .jungle-text;
      }
    }

    &-amount {
      font-size: 14px;
    }

    &-label {
      font-size: 10px;
      text-transform: uppercase;
    }

    &-inner {
      background-color: white;
      width: 100%; height: 100%;
      display: block;
      border-radius: 1px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &.selected {
      .amount-selector-special-inner {
        background-color: transparent;
        color: white;
      }
      .amount-selector-special-amount {
        font-weight: $cerebri-heavy;
      }
      .amount-selector-special-label {
        background-image: none !important;
        -webkit-background-clip: unset !important;
        -webkit-text-fill-color: unset !important;
        color: white;
      }
    }
  }

  &-custom {
    display: inline-block;
    width: 91px; height: 51px;
    position: relative;

    input {
      border: none;
      border-bottom: 1px solid $color-gray-midnight;
      display: block;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      height: 30px;
      // padding-left: 12px;
      font-size: 14px;
      font-weight: $cerebri-heavy;
      outline: none;
      text-align: left;
      &::placeholder {
        color: $color-text-faint;
      }
    }

    &.symbol-right input {
      text-align: right;
    }

    &-label {
      font-size: 12px;
      text-transform: uppercase;
      position: absolute;
      top: 0;
      right: 0;
      color: $color-text-default;
    }

    &.selected {
      .amount-selector-custom-label {
        color: $color-accent-blue;
      }
      &:after {
        color: $color-text-default;
      }
      input {
        border-color: $color-accent-blue;
      }
    }
  }

}
