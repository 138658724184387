.ca-slack {

  &-connect {
    margin-right: $spacer * -4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &-left {
      width: 500px;
      padding-right: $spacer * 4;
    }
    &-right {
      min-width: 200px;
      margin-top: -80px;
      img {
        display: block;
        height: 537px;
      }
    }
  }

  &-revoked {
    background-color: $color-danger;
    color: white;
    display: flex;
    font-weight: $cerebri-bold;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $spacer ($spacer * 3);
    border-radius: 3px;
  }

  &-confirm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-size: 20px;
      padding-bottom: $spacer;
    }
  }

  &-connection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: ($spacer * 2) 0;

    &-company {
      max-height: 40px;
    }
    .connector-line {
      width: 200px;
      margin: 0 ($spacer * 2);
    }
    &-slack {
      width: 122px;
    }
  }

  &-channels {
    &-heading {
      margin: 0;
    }
    &-desc {
      color: $color-text-medium-1-5;
      margin-top: calc($spacer / 2);
      margin-bottom: $spacer * 3;
    }
    &-connected {
      display: flex;
      flex-direction: row;
      align-items: center;
      svg.icon {
        width: 20px;
        color: $color-gray-slate;
      }
      &-name {
        font-size: 18px;
        font-weight: $cerebri-bold;
        margin: 0 ($spacer * 2) 0 calc($spacer / 2);
      }
    }
    &-admin {
      // margin-top: $spacer * 4;
    }
  }

}
