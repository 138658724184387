
* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: $cerebri;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  color: $color-text-default;
  font-weight: $cerebri-regular;
  font-size: 14px;
}

input[type='text'], input[type='password'], input[type='email'], input[type='submit'] {
  -webkit-appearance: none;
}

button {
  -webkit-appearance: none;
  outline: none;
  background-color: transparent;
  border: none;
}

p {
  margin: $spacer 0;
}

a {
  color: $color-accent-blue;
  text-decoration: none;
  outline: none;

  &:hover, &:focus {
    text-decoration: underline;
  }
}

b, strong {
  font-weight: $cerebri-bold;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

hr {
  height: 0;
  display: block;
  border: none;
  border-top: 1px solid $color-gray-cloud;
  margin: $spacer 0;
}

code {
  font-family: monospace;
  background-color: $color-gray-cloud;
  padding: 1px 3px;
}
