.page-wallet {

  .app-body .widther {
    max-width: 969px;
    padding-bottom: $spacer * 8;
  }

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: ($spacer * 4) 0;

    &-title-con {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;
    }
    &-image {
      display: block;
      width: 50px; height: 50px;
      border-radius: 100%;
      margin-right: $spacer;
      object-fit: cover;
    }
    &-title {
      font-size: 42px;
      font-weight: $cerebri-heavy;
      margin: 0;
    }
    &-amount {
      color: $color-accent-green;
      text-align: right;
      margin-left: $spacer * 2;
      &-amount {
        font-weight: $cerebri-heavy;
        font-size: 37px;
      }
      &-label {
        text-transform: uppercase;
        font-size: 13px;
      }

      &.match {
        color: $color-accent-purple;
      }
    }
  }

  &-box {
    box-shadow: 2px 1px 12px 3px rgba(0,0,0,0.09);
    padding: $spacer * 4;
    position: relative;

    &-currency-picker {
      position: absolute;
      top: $spacer * 2;
      right: $spacer * 2;
    }
  }

  &-5-percent-a {
    text-align: center;
    margin: ($spacer * 2) 0 0;
  }
  &-5-percent-b {
    text-align: center;
    margin: $spacer 0 0;
    font-weight: $cerebri-bold;
  }

  .upload-box {
    h2 {
      color: $color-accent-blue;
      font-size: 18px;
      margin: 0;
      text-transform: uppercase;
      font-weight: $cerebri-regular;
      text-align: center;
    }

    h3 {
      margin: 0;
      font-size: 15px;
      font-weight: $cerebri-heavy;

      svg.icon {
        width: 24px;
        vertical-align: middle;
        margin-right: calc($spacer / 2);
      }
    }

    &-selections {
      border-bottom: 1px solid $color-gray-cloud;
    }

    &-one-offs {
      width: 289px;
      margin: ($spacer * 4) auto;

      h3 {
        margin-bottom: $spacer * 1.5;
      }
    }

    &-payment {
      max-width: 380px;
      margin: 0 auto;
      margin-top: $spacer * 4;

      h3 {
        text-align: center;
        margin-bottom: $spacer;
      }

      &-button {
        width: 100%;
        margin-bottom: $spacer;
      }

      &-amex-currency-block {
        margin-top: 0;
        color: $color-danger;
        text-align: center;
      }
    }
  }

  .actions-box {
    margin-top: $spacer * 2;

    &-controls {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: $spacer * 2;
      padding-bottom: 0;
      .btn {
        margin-left: $spacer;
      }
    }

    &-tabs {
      border-bottom: 1px solid $color-gray-cloud;
      display: flex;
      flex-direction: row;
      padding-top: $spacer;
      margin: 0 $spacer;
      justify-content: center;

      &-tab {
        display: block;
        border: none;
        outline: none;
        background-color: transparent;
        text-transform: uppercase;
        padding: $spacer;
        cursor: pointer;
        color: $color-text-faint;
        border-bottom: 2px solid transparent;
        transition: all 100ms;

        &.active {
          color: $color-text-default;
          border-color: $color-gray-midnight;
        }
        &:hover {
          color: $color-text-default;
        }
      }
    }
  }

  &-cc-selector {
    width: 100%;
  }

  .dd-credit-card {
    width: 100%;
    margin-bottom: $spacer;

    .dd-menu {
      max-height: 240px;
      overflow: auto;
    }

    .dd-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    .dd-menu-option {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    svg.icon {
      width: 24px;
    }
  }

  .cc-selector-new {
    #card-element {
      border: 1px solid $color-gray-cloud;
      padding: $spacer;
      color: $color-text-default;
      border-radius: 2px;
      width: 100%;
      margin-bottom: $spacer;
    }

    &-error {
      color: $color-danger;
      margin-top: $spacer * -1;
      margin-bottom: $spacer;
    }

    &-default-option {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-bottom: $spacer;

      div.checkbox {
        margin-right: calc($spacer / 2);
      }
    }
  }

  &-balances {
    display: flex;
    flex-direction: row;
    margin-top: $spacer * 4;

    &-box {
      box-shadow: 2px 1px 12px 3px rgba(0,0,0,0.09);
      flex: 1;
      padding: $spacer * 2;
    }

    &-spacer {
      width: $spacer * 2;
      height: $spacer * 2;
    }

    .pwbb {
      &-title {
        margin: 0;
        font-weight: $cerebri-heavy;
        font-size: 15px;
      }
      &-amounts {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-top: $spacer * 2;
        &-main {
          flex: 1;
          &-val {
            font-weight: $cerebri-heavy;
            font-size: 42px;
            display: inline-block;
          }
          &-key {
            text-transform: uppercase;
            font-weight: $cerebri-bold;
            font-size: 12px;
          }
        }
        &-sub-row {
          @include flexbox(row, center, flex-end);
          flex-wrap: wrap;
        }
        &-sub {
          color: $color-text-faint;
          text-align: right;
          padding-left: $spacer * 2;
          // & + .pwbb-amounts-sub {
          //   padding-left: $spacer * 2;
          //   // border-left: 1px solid $color-gray-cloud;
          //   margin-left: $spacer * 2;
          // }
          &-val {
            font-weight: $cerebri-light;
            font-size: 30px;
          }
          &-key {
            text-transform: uppercase;
            font-size: 12px;
          }
        }
      }
      &.wallet {
        .pwbb-amounts-main {
          color: $color-accent-green;
        }
      }
      &.match {
        .pwbb-amounts-main {
          color: $color-accent-purple;
        }
      }
    }
  }

  &-offdon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: $spacer * 2;
    .btn {
      margin-left: $spacer;
    }
  }

  .actions-box {
    padding: 0;
    padding-bottom: $spacer * 4;
    margin-top: $spacer * 2;

    &-left {
      color: $color-accent-green;
      text-align: right;
      margin-right: $spacer * 4;
      &-amount {
        font-weight: $cerebri-heavy;
        font-size: 37px;
      }
      &-label {
        text-transform: uppercase;
        font-size: 13px;
      }
    }
  }

  .actions {
    padding: $spacer * 4;
    overflow: auto;

    &-table {
      width: 100%;
      min-width: 702px;
      border-spacing: 0;
      border-collapse: collapse;
    }

    td {
      padding: $spacer;
      border-bottom: 1px solid $color-gray-cloud;
    }

    tr.employer-match td {
      background-color: $color-gray-ice;
    }

    &-cell {
      &.date {
        text-align: right;
      }
      &.type {
        white-space: nowrap;
        .type-con {
          display: flex;
          flex-direction: row;
          align-items: center;
          svg.icon {
            width: 24px;
            margin-right: calc($spacer / 2);
            flex-shrink: 0;
            &.recurring {
              margin: 0 0 0 calc($spacer / 2);
              color: $color-gray-slate;
            }
          }
          .type-status {
            background-color: $color-gray-slate;
            font-size: 10px;
            text-transform: uppercase;
            padding: calc($spacer / 4) calc($spacer / 2);
            font-weight: $cerebri-bold;
            border-radius: 100px;
            display: inline-block;
            margin: 0 0 0 calc($spacer / 2);
            color: white;
            &.approved { background-color: $color-accent-green; }
            &.declined { background-color: $color-danger; }
          }
        }
        &.wallet-load, &.payroll-deduction {
          font-weight: $cerebri-heavy;
          color: $color-accent-green;
        }
        &.donation {
          color: $color-accent-pink;
        }
        &.gift-sent, &.gift-redeemed {
          color: $color-accent-blue;
        }
        &.employer-match {
          color: $color-accent-purple;
        }
        &.expired-gift-returned {
          color: $color-accent-yellow;
        }
      }
      &.track {
        .track-icon {
          cursor: pointer;
          svg.icon {
            width: 20px;
          }
        }
      }
      &.entity-image {
        white-space: nowrap;
        text-align: right;
        .img1, .img2 {
          width: 24px;
          height: 24px;
          border-radius: 100%;
          object-fit: cover;
          background-color: white;
          border: 1px solid white;
          box-shadow: 0 2px 4px rgba(0,0,0,0.09);
        }
        .img2 {
          margin-right: -8px;
        }
      }
      &.entity-name {
        a {
          color: $color-text-default;
        }
      }
      &.debit {
        text-align: right;
        .receipt-icon {
          width: 22px;
          cursor: pointer;
          &:hover {
            color: $color-accent-pink;
          }
        }
      }
      &.credit {
        text-align: right;
        color: $color-accent-green;
        white-space: nowrap;
        &.gift-redeemed {
          color: $color-accent-blue;
        }
        &.employer-match {
          color: $color-accent-purple;
        }
        &.expired-gift-returned {
          color: $color-accent-yellow;
        }
      }
    }
  }

  &-rds {
    margin: $spacer * 4;

    &-notice {
      margin-bottom: $spacer * 2;
    }
  }

  &-rd {
    display: flex;
    flex-direction: row;
    margin-bottom: $spacer * 4;
    align-items: center;

    &-card {
      width: 320px;
      margin-right: $spacer * 2;
      position: relative;

      &-amount {
        $darker-pink: darken($color-accent-pink, 10%);
        position: absolute;
        background-color: $color-accent-pink;
        color: white;
        font-weight: $cerebri-bold;
        padding: $spacer ($spacer * 1.5);
        top: $spacer;
        left: $spacer * -1;
        z-index: 1;
        box-shadow: 1px 1px 2px rgba(0,0,0,0.25);

        &:after {
          position: absolute;
          content: '';
          display: block;
          border: calc($spacer / 2) solid $darker-pink;
          border-width: calc($spacer / 2.4) calc($spacer / 2);
          top: 100%;
          left: 0;
          border-color: $darker-pink $darker-pink transparent transparent;
        }
      }
    }

    &-details {
      &-desc {
        display: flex;
        flex-direction: row;
        align-items: center;
        svg.icon {
          width: 20px;
          margin-right: calc($spacer / 2);
        }
      }
      &-error {
        color: $color-danger;
      }
      p {
        margin: 0 0 $spacer 0;
      }
    }
  }

  @include media("<large") {
    &-header {
      &-title {
        font-size: 37px;
      }
    }
    .actions {
      padding: $spacer * 2;
    }
  }

  @include media("<medium") {
    &-header {
      flex-direction: column;
      align-items: flex-start;
      &-title {
        font-size: 28px;
      }
      &-title-con {
        margin-bottom: $spacer;
      }
      &-amount {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 0;
        &-label {
          margin-left: calc($spacer / 2);
        }
      }
    }
    &-offdon {
      flex-direction: column;
      .btn {
        margin: 0;
      }
    }
    &-box {
      padding: $spacer * 2;
      padding-top: $spacer * 4;
      margin-left: $spacer * -2;
      margin-right: $spacer * -2;
      &-currency-picker {
        top: $spacer;
        right: $spacer;
      }
    }
    &-balances {
      flex-direction: column;
      margin-left: $spacer * -2;
      margin-right: $spacer * -2;
    }
    &-rd {
      flex-direction: column;
      align-items: flex-start;
      &-card {
        margin-right: 0;
        margin-bottom: $spacer;
      }
    }
  }
}

.modal-wal-confirm {
  .modal-box {
    padding: $spacer * 4;
    text-align: center;
  }
  &-heading {
    color: $color-accent-purple;
    margin: 0;
    font-size: 24px;
    font-weight: $cerebri-bold;
  }
  &-desc {
    max-width: 420px;
    // color: $color-text-medium-1-5;
  }
  &-amount {
    font-size: 24px;
    font-weight: $cerebri-regular;
    margin: $spacer * 2;
  }
  &-error {
    color: $color-danger;
    margin-top: $spacer * 2;
  }
}

.modal-receipts {
  .modal-box {
    padding: $spacer * 4;
    width: 600px;
  }
  &-h1 {
    display: flex;
    flex-direction: row;
    font-weight: $cerebri-heavy;
    font-size: 15px;
    text-transform: uppercase;
    svg.icon {
      width: 23px;
      margin-right: calc($spacer / 2);
    }
  }
  &-exp {
    width: 100%;
  }
  &-year {
    &-heading {
      font-size: inherit;
      font-weight: $cerebri-heavy;
      margin-top: $spacer * 3;
    }
    &-table {
      width: 100%;
      th, td {
        text-align: left;
        padding: ($spacer * 1.25) 0;
        border-bottom: 1px solid $color-gray-cloud;
        &.right {
          text-align: right;
        }
        // &.ein {
        //   padding-left: $spacer;
        //   padding-right: $spacer;
        // }
      }
      th {
        font-weight: $cerebri-regular;
        color: $color-text-medium-1-5;
        text-transform: uppercase;
      }
      td {
        &.amount {
          font-weight: $cerebri-bold;
        }
      }
    }
  }
  @include media("<medium") {
    .modal-box {
      width: 100%;
    }
  }
}

.modal-cc {
  .modal-box {
    max-width: 500px;
  }
  &-exp-fields {
    @include flexbox(row, center, center);
    margin-bottom: $spacer * 1.5;
    .standard-select {
      min-width: 140px;
      flex: 1;
    }
  }
  .stripe-element-root {
    border: 1px solid $color-gray-cloud;
    border-radius: 2px;
    padding: $spacer;
    margin-bottom: $spacer * 1.25;
    &.invalid {
      margin-bottom: $spacer * 0.25;
    }
    & + .validation-message {
      margin-top: 0;
    }
  }
  &-cb {
    @include flexbox(row, center, flex-start);
    margin-bottom: $spacer * 1.25;
    .checkbox {
      margin-right: calc($spacer / 2);
    }
  }
  &-error {
    color: $color-danger;
  }
}
