.app {
  overflow: hidden;

  &-masquerading {
    background-color: $color-danger;
    padding: calc($spacer / 2);
    text-align: center;
    color: white;
    font-weight: $cerebri-bold;

    .btn.app-masquerading-stop {
      height: 23px;
      line-height: 21px;
      margin-left: $spacer;
    }
  }

  &-body {
    min-height: 400px;
  }

  &.decoration-banner .app-body {
    background-image: url('/images/bg-banner.png');
    background-size: 1999px 228px;
    background-repeat: no-repeat;
    background-position: center top;
  }

  &.bg-purple  .app-body { background: $gradient-purple; min-height: 100vh; }
  &.bg-blue    .app-body { background: $gradient-blue; min-height: 100vh; }
  &.bg-groups  .app-body { background: $gradient-groups; }
  &.bg-slack   .app-body { background: $gradient-slack; }
  &.bg-ice     .app-body { background: $color-gray-ice; }

  &.sticky-header {
    .app-stickable {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 200;
      background-color: white;
    }
    .app-body {
      overflow: hidden;
      margin-top: 100px;
    }
  }

  @include media("<xl") {
    &.sticky-header {
      .app-body {
        margin-top: 92px;
      }
    }
  }
  @include media("<large") {
    &.decoration-banner .app-body {
      background-size: 1000px 114px;
    }
  }
  @include media("<medium") {
    &.sticky-header {
      .app-body {
        margin-top: 60px;
      }
    }
  }
}

.page-loading {
  margin: ($spacer * 8) auto;
  color: $color-text-faint;
  text-align: center;

  svg.icon {
    width: 48px;
  }
}

.modal-confirm-nav {
  .modal-box {
    padding: $spacer * 4; 
  }
  &-h1 {
    margin: 0;
    font-size: 20px;
    font-weight: $cerebri-heavy;
  }
  &-actions {
    @include flexbox(row, center, flex-end);
    margin-top: $spacer * 4;
    .btn {
      margin-left: $spacer;
    }
  }
}
