
.page-dashboard {
  .widther {
    min-width: 1000px;
  }
}

.np-dash {

  /*
   * Header
   */

  &-header {
    &-main {
      padding: ($spacer * 3) 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      &-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: $spacer * 4;

        // img {
        //   width: 54px;
        //   height: 54px;
        //   border-radius: 100%;
        //   display: block;
        //   margin-right: $spacer;
        //   object-fit: cover;
        // }

        h1 {
          margin: 0;
          font-size: 42px;
          font-weight: $cerebri-heavy;
        }
      }
      &-right {
        flex-shrink: 0;
      }
    }

    &-menu {
      border-bottom: 1px solid $color-gray-cloud;

      &-items {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }

      &-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: $cerebri-bold;
        font-size: 13px;
        color: $color-text-default;
        text-transform: uppercase;
        padding-bottom: calc($spacer / 2);
        border-bottom: 2px solid $color-text-default;

        &:hover {
          text-decoration: none;
        }

        svg.icon {
          width: 20px;
          margin-right: calc($spacer / 2);
        }
      }
    }
  }

  /*
   * Profile Callout
   */

  &-procta {
    // width: 500px;
    // margin: ($spacer * 2) auto;
    padding-left: $spacer * 2;
    border-left: 1px solid $color-gray-cloud;
    // background-color: $color-gray-ice;
    // border-radius: 5px;
    @include flexbox(row, center, flex-start);
    gap: $spacer * 4;
    flex-shrink: 0;

    &-left {
      flex-shrink: 0;
    }
    &-h2 {
      margin: 0;
      font-weight: $cerebri-heavy;
      font-size: 24px;
      // text-align: center;
    }
    &-scores {
      @include flexbox(row, center, center);
      margin-top: $spacer;
      gap: $spacer;
    }
    &-score {
      text-align: center;
      padding: $spacer;
      border-radius: 3px;
      border: 1px solid $color-gray-cloud;
      flex-shrink: 0;
      &-val {
        font-weight: $cerebri-heavy;
        font-size: 20px;
      }
      &-label {
      }
      &.green {
        border-color: $color-accent-green;
        background-color: rgba($color-accent-green, 0.2)
      }
      &.yellow {
        border-color: $color-accent-yellow;
        background-color: rgba($color-accent-yellow, 0.2)
      }
      &.red {
        border-color: $color-danger;
        background-color: rgba($color-danger, 0.2)
      }
    }
    &-actions {
      // margin-top: $spacer * 2;
      // border-top: 1px solid $color-gray-cloud;
      @include flexbox(column, flex-start, flex-start);
      gap: $spacer;
      flex-shrink: 0;

      a {
        display: block;
        padding: calc($spacer / 4) calc($spacer / 2);
        color: inherit;
        @include flexbox(row, center, flex-start);
        svg.icon {
          width: 20px;
          margin-right: calc($spacer / 2);
          flex-shrink: 0;
        }
        &:hover {
          text-decoration: none;
          color: $color-accent-pink;

        }
      }
    }
  }

  /*
   * PENDING
   */

  &-pending {
    margin: ($spacer * 8) auto 0;
    text-align: center;

    &-p1 {
      font-size: 40px;
      font-weight: $cerebri-heavy;
      color: $color-accent-pink;
    }
    &-p2 {
      font-size: 21px;
      max-width: 870px;
      margin: 0 auto;
    }
    &-p3 {
      font-size: 21px;
      font-weight: $cerebri-bold;
      margin-top: $spacer * 6;
    }
    &-p4 {
      font-size: 18px;
    }
    &-actions {
      margin-top: $spacer * 2;
    }
    &-teaser {
      display: block;
      margin: ($spacer * 8) auto 5px;
      width: 1196px;
    }
  }
}

/*
 * Dashboard
 */

.page-dashboard {
  &-top {
    display: flex;
    flex-direction: row;
    margin-top: $spacer * 4;

    &-stats {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }

    &-actions {
      border-left: 1px solid $color-gray-cloud;
      padding-left: $spacer * 4;

      &-title {
        font-weight: $cerebri-bold;
        font-size: 16px;
        text-transform: uppercase;
        margin: 0;
        margin-bottom: $spacer;
      }
    }
  }

  &-stat {
    text-align: center;
    padding: 0 ($spacer * 4);

    &-value {
      font-weight: $cerebri-heavy;
      font-size: 38px;
    }
    &-label {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: $cerebri-bold;
    }

    &.matched-donations { color: $color-accent-purple; }
    &.donations { color: $color-accent-orange; }
    &.donors { color: $color-accent-pink; }
    &.total { color: $color-accent-green; }
  }

  &-box {
    box-shadow: 2px 1px 12px 3px rgba(0,0,0,0.09);
    margin: ($spacer * 4) auto;
    padding: $spacer * 3;

    &-top {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &-title {
      font-weight: $cerebri-bold;
      font-size: 16px;
      text-transform: uppercase;
      margin: 0;
    }

    &-download {
      color: $color-text-default;
      font-size: 12px;
      &:hover {
        text-decoration: none;
        color: $color-accent-pink;
      }
    }

    .page-dashboard-disbursements {
      width: 100%;
      border-top: 1px solid $color-gray-cloud;
      margin-top: $spacer * 3;
    }
    tr.has-note {
      td {
        border-bottom: none;
      }
      + tr {
        color: $color-text-medium-1-5;
      }
    }
    td {
      border-bottom: 1px solid $color-gray-cloud;
      padding: $spacer;

      &.date {
        color: $color-text-faint;
      }
      &.user-company {
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        img {
          width: 24px;
          height: 24px;
          display: block;
          border-radius: 100%;
          object-fit: cover;
          margin-right: calc($spacer / 2);
        }
        .company-letter {
          color: white;
          background-color: $color-jungle-a;
          height: 24px;
          width: 24px;
          line-height: 24px;
          text-align: center;
          border-radius: 100%;
          margin-right: calc($spacer / 2);
        }
      }
      &.recurring {
        svg.icon {
          width: 24px;
          display: block;
        }
      }
      &.amount {
        text-align: right;
        color: $color-accent-green;
        font-weight: $cerebri-heavy;
      }
      &.type {
        .corporate-match {
          display: inline;
          color: white;
          background-color: $color-accent-purple;
          border-radius: 200px;
          text-align: center;
          padding: calc($spacer / 4) $spacer;
          margin-right: calc($spacer / 2);
        }
      }
    }
  }
}
