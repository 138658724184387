.ca-subx {
  .ca-box {
    max-width: 800px;
  }
  &-disclaim {
    // margin-top: $spacer * 3;
  }
  &-subheading {
    margin-top: $spacer * 4;
    margin-bottom: $spacer;
    font-weight: $cerebri-bold;
    font-size: 18px;
  }
  &-feature {
    border-radius: 100px;
    display: inline-block;
    padding: 2px 8px;
    background-color: $color-gray-fog;
    color: white;
    text-transform: uppercase;
    font-weight: $cerebri-bold;
    font-size: 12px;
    margin-right: calc($spacer / 2);
    &.on {
      background-color: $color-accent-green;
    }
  }
  &-portal {
    background-color: $color-gray-ice;
    padding: $spacer * 2;
    @include flexbox(row, center, space-between);
    &-label {
      font-weight: $cerebri-bold;
    }
  }
}
