.page-groups {

  &-heading {
    @extend .gradient-groups-text;
    font-weight: $cerebri-heavy;
    font-size: 42px;
    margin: 0;
    margin-top: $spacer * 6;
    text-align: center;
  }

  &-groups {
    margin: ($spacer * 4) ($spacer * -1.5) ($spacer * 10);
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    .group-card {
      width: 360px;
      margin: $spacer * 1.5;
    }
  }

  @include media("<medium") {
    &-heading {
      font-size: 30px;
    }
    &-groups {
      .group-card {
        width: 100%;
      }
    }
  }
}
