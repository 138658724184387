.ca-match-general {

  .match-amount {
    background-color: $color-gray-ice;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: ($spacer * 1.5) ($spacer * 3);
    &-edit-icon {
      width: 24px;
      display: none;
      position: absolute;
      top: 0;
      right: 0;
    }
    &-ratio {
      position: relative;
      padding-right: $spacer * 3;
      margin-right: $spacer * 3;
      cursor: pointer;
      &:hover {
        .match-amount-edit-icon {
          display: block;
        }
      }
      .standard-select {
        display: block;
        margin-bottom: 0;
      }
      &-amount {
        font-weight: $cerebri-bold;
        font-size: 21px;
      }
      p {
        margin-top: calc($spacer / 2);
        margin-bottom: 0;
      }
      &-actions {
        margin-top: $spacer * 1.5;
        .btn + .btn {
          margin-left: $spacer * 0.75;
        }
      }
    }
  }

  &-fees {
    &-toggle {
      margin-bottom: $spacer * 2;
      @include flexbox(row, center, flex-start);
      gap: $spacer;
      &-status {
        color: white;
        text-transform: uppercase;
        font-size: 12px;
        background-color: $color-gray-slate;
        border-radius: 100px;
        padding: 2px;
        width: 40px;
        text-align: center;
        &.on  { background-color: $color-accent-green; }
        // &.off { background-color: $color-danger; }
      }
    }
  }

}

/*
 *  Budget Editor
 */

.ca-budget {
  border: 1px solid $color-gray-midnight;
  position: relative;
  padding: ($spacer * 1.5) ($spacer * 3);
  &.editable {
    cursor: pointer;
  }
  &-row {
    @include flexbox(row, center, flex-start);
  }
  &-amounts {
    @include flexbox(row, center, flex-start);
  }
  &-text {
    font-size: 14px;
    padding: 0 ($spacer * 3) 0 0;
  }
  &-link {
    display: none;
    svg.icon {
      width: 10px;
    }
  }
  &-h1 {
    text-transform: uppercase;
    font-weight: $cerebri-bold;
  }
  &:hover {
    .ca-budget-edit-icon {
      display: block;
    }
    .ca-budget-link {
      display: block;
    }
  }
  &-edit-icon {
    position: absolute;
    top: $spacer * 1.5;
    right: $spacer * 1.5;
    width: 24px;
    display: none;
  }
  &-match {
    border-left: 1px solid $color-gray-cloud;
  }
  &-match, &-cap {
    padding-left: $spacer * 3;
    .standard-input {
      display: block;
      margin-bottom: 0;
    }
    &-amount {
      font-weight: $cerebri-bold;
      font-size: 21px;
    }
    p {
      margin-bottom: 0;
      margin-top: calc($spacer / 2);
    }
  }
  &-actions {
    @include flexbox(row, center, flex-start);
    margin-top: $spacer * 1.5;
    .btn + .btn {
      margin-left: $spacer * 0.75;
    }
  }
  &.condensed {
    .ca-budget-row {
      @include flexbox(column, flex-start, flex-start);
    }
    .ca-budget-text {
      @include flexbox(row, center, space-between);
      padding-right: 0;
      width: 100%;
    }
    .ca-budget-match {
      padding-left: 0;
    }
    .ca-budget-match, .ca-budget-cap {
      border-left: 0;
      padding-top: $spacer * 1.5;
    }
  }
}



.ca-modal-fees {
  &-cb-row {
    @include flexbox(row, center, flex-start);
    gap: $spacer * 0.5;
  }
}
