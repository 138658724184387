.page-events {
  &-header {
    @extend .gradient-groups-text;
    display: inline-block;
    font-size: 44px;
    font-weight: $cerebri-heavy;
  }
  &-subheader {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: $cerebri-bold;
    text-align: center;
    margin: ($spacer * 2) 0;
  }
}
