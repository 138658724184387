
.claim-mrkt {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: ($spacer * 8) 0;

  &-item {
    width: 288px + ($spacer * 6);
    flex-shrink: 0;
    padding: 0 ($spacer * 3);

    &:nth-child(2) {
      border: 1px solid $color-gray-cloud;
      border-width: 0 1px;
    }

    &-img {
      width: 230px;
      height: 230px;
      display: block;
      margin: 0 auto;
      border-radius: 100%;
      box-shadow: 0 0 20px rgba(0,0,0,0.2);
    }

    &-title {
      width: 230px;
      margin: 0 auto;
      text-align: center;
      font-size: 20px;
      font-weight: $cerebri-heavy;
      margin-top: $spacer * 2;
    }

    &-desc {
      color: $color-text-faint;
    }
  }

  @include media("<xl") {
    &-item {
      width: 288px + ($spacer * 4);
      flex-shrink: 0;
      padding: 0 ($spacer * 2);
    }
  }

  @include media("<large") {
    flex-direction: column;
    margin: ($spacer * 4) 0;

    &-item {
      width: auto;
      max-width: 400px;
      padding: $spacer * 2;
      text-align: center;
      border: none !important;
      margin: 0 auto;

      &-img {
        width: 180px;
        height: 180px;
      }
    }
  }

}
