.page-gifts-send {

  &-header {
    text-align: center;

    &-title {
      @extend .rainbow-text;
      font-size: 48px;
      font-weight: $cerebri-heavy;
      display: inline-block;
      margin-top: $spacer * 6;
      margin-bottom: 0;
    }

    &-redeem {
      margin-top: $spacer * 2;
      a {
        font-weight: $cerebri-bold;
      }
    }
  }

  .send-box {
    max-width: 933px;
    margin: ($spacer * 2) auto;
    margin-bottom: $spacer * 4;
    box-shadow: 2px 1px 12px 3px rgba(0,0,0,0.09);
    padding: $spacer * 4;
    padding-bottom: 0;

    &-title {
      text-transform: uppercase;
      font-size: 15px;
      font-weight: $cerebri-regular;
      text-align: center;
    }

    &-form {
      width: 300px;
      margin: 0 auto;
      margin-top: $spacer * 2;

      .btn {
        width: 140px;
        display: block;
        margin: 0 auto;
      }

      &-amount {
        width: 132px;
      }
    }

    &-balance {
      text-align: center;
      font-size: 12px;
      margin-top: $spacer * 2;
      &-a {
        font-weight: $cerebri-bold;
      }
      &-b {
        color: $color-text-faint;
        text-transform: uppercase;
      }
    }
  }

  @include media("<medium") {
    &-header {
      &-title {
        margin-top: $spacer * 4;
        font-size: 32px;
      }
    }
  }

}



.card-selector {
  position: relative;
  margin-left: $spacer * -4;
  margin-right: $spacer * -4;
  overflow: hidden;
  padding: $spacer 0;

  &-image-unselected {
    position: absolute;
    top: 50%;
    width: 291px;
    opacity: 0.11;

    .card-selector-image-heighter {
      margin-top: -35.5%;
    }

    &.prev {
      left: -145px;
    }
    &.next {
      right: -145px;
    }
  }
  &-image-selected {
    max-width: 455px;
    width: 66.667%;
    margin: 0 auto;
    box-shadow: 2px 4px 9px rgba(0,0,0,0.21);
  }
  &-image-heighter {
    padding-bottom: 71%;
    background-position: center;
    background-size: cover;
  }

  &-btn-prev, &-btn-next {
    width: 28px;
    height: 28px;
    display: block;
    background: none;
    border: 1px solid $color-gray-midnight;
    position: absolute;
    top: 50%;
    left: 50%;
    outline: none;
    transform: rotate(45deg);
    cursor: pointer;
    margin-top: -14px;

    &:hover {
      border-color: black;
    }
  }
  &-btn-prev {
    margin-left: -266px;
    border-top-width: 0;
    border-right-width: 0;
  }
  &-btn-next {
    margin-left: 238px;
    border-left-width: 0;
    border-bottom-width: 0;
  }

  @include media("<large") {
    &-image-unselected {
      display: none;
    }
  }
  @include media("<medium") {
    &-btn-prev, &-btn-next {
      margin-left: 0;
    }
    &-btn-prev {
      left: 18px;
    }
    &-btn-next {
      left: unset;
      right: 18px;
    }
  }
}
