.clients-list {
  margin: 35px auto;
  text-align: center;

  &-title {
    font-size: 14px !important;
    font-weight: $cerebri-bold !important;
    line-height: 18px;
  }

  &-items {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin: ($spacer * 2) ($spacer * 4);
    }
  }

  @include media("<large") {
    &-items {
      img {
        margin: ($spacer * 2);
      }
    }
  }

  @include media("<medium") {
    &-items {
      img {
        transform: scale(0.5, 0.5);
        margin: 0 ($spacer * -1);
      }
    }
  }
}
