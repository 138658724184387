.ca-purchase {

  &-title {
    color: $color-accent-purple;
    font-size: 27px;
    font-weight: $cerebri-heavy;
    display: flex;
    flex-direction: row;
    align-items: center;

    svg.icon {
      width: 21px;
      margin-right: $spacer;
      // vertical-align: middle;
    }
  }

  &-tx {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    &-total {
      font-weight: $cerebri-heavy;
      font-size: 41px;
      padding-right: $spacer * 3;
    }

    &-details {
      padding: 0 ($spacer * 3);
      border-left: 1px solid $color-gray-cloud;
      border-right: 1px solid $color-gray-cloud;
      min-width: 340px;

      &-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: $spacer 0;
        align-items: center;

        &-key {
          margin-right: $spacer * 4;
        }
        &-val {
          font-weight: $cerebri-bold;
          text-align: right;
        }
      }
    }

    &-disclaimer {
      padding-left: $spacer * 3;
      color: $color-text-faint;
    }
  }

  &.type-gift {
    .ca-purchase-title {
      color: $color-accent-blue;
    }
  }
  &.type-dfd {
    .ca-purchase-title {
      color: $color-accent-orange;
    }
  }
  &.type-grant {
    .ca-purchase-title {
      color: $color-accent-pink;
    }
  }
  &.type-payroll {
    .ca-purchase-title {
      color: $color-accent-green;
    }
  }

}
