
.action-tiles {

  &-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &:nth-child(2) {
      margin: $spacer 0;
    }
    &:nth-child(3) {
      margin-left: -100px;
    }
  }

  &-action {
    width: 264px;
    padding: $spacer;
    box-shadow: 2px 1px 10px 1px rgba(0,0,0,0.09);
    margin: 0 calc($spacer / 2);
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;

    &-images {
      margin-right: calc($spacer / 2);
      display: flex;
      flex-direction: row;
      align-items: center;

      .img1, .img2 {
        width: 39px;
        height: 39px;
        border: 1px solid white;
        box-shadow: 0 2px 4px rgba(0,0,0,0.09);
        border-radius: 100%;
        display: block;
        object-fit: cover;
      }
      .img2 {
        margin-right: -12px;
      }
    }

    &-icon {
      width: 24px;
      margin-right: calc($spacer / 2);
      &.gift-sent {
        margin-top: -3px;
        color: $color-accent-green;
      }
    }

    &-text {
      font-size: 13px;
      max-height: 34px;
      overflow: hidden;
      display: block;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;

      &.gift-sent {
        strong {
          color: $color-accent-green;
        }
      }
    }
  }

}
