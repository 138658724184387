/*
 *  Category Buttons
 */

.cat-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1100px;
  gap: 16px;
  margin: 0 auto;
  font-size: 13px;

  &-button {
    position: relative;
    color: white;
    width: 165px;
    height: 48px;
    border-radius: 100px;
    opacity: 1;
    transition: opacity 100ms;
    box-shadow: 0 2px 6px rgba(0,0,0,0.16);

    &:hover, &:active, &:focus {
      .cat-buttons-button-dropdown {
        display: block;
      }
    }

    &-item {
      @include flexbox(row, center, flex-start);
      color: white;
      height: 48px;
      padding: 0 $spacer;
      &.toggle {
        border-radius: 24px;
        svg.icon.caret {
          width: 12px;
          opacity: 0.6;
        }
      }
      &:first-child {
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
      }
      &:last-child {
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
      }
      svg.icon {
        width: 21px;
        flex-shrink: 0;
        &:first-child {
          margin-right: calc($spacer / 2);
        }
      }
      span {
        flex: 1;
        font-size: 13px;
      }
      &:hover, &:active {
        text-decoration: none;
        opacity: 0.7;
      }
    }

    &-dropdown {
      box-shadow: $shadow;
      z-index: 5;
      background-color: white;
      position: absolute;
      top: 100%;
      left: 0;
      width: 240px;
      display: none;
      border-radius: 24px;
    }
  }

  &-all-con {
    @include flexbox(row, center, center);
    margin-top: $spacer * 7;
  }
}

/*
 *  Search Field
 */

.search-field {
  margin: 0 auto;
  max-width: 580px;
  position: relative;

  &-input-con {
    height: 46px;
    width: 100%;
    position: relative;
  }

  &-glass {
    width: 18px;
    color: $color-text-faint;
    position: absolute;
    top: 50%;
    left: $spacer * 1.5;
    margin-top: -9px;
  }

  &-input {
    background-color: $color-gray-ice;
    border: 1px solid transparent;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0; right: 0; bottom: 0; left: 0;
    border-radius: 50px;
    outline: none;
    color: $color-text-default;
    font-size: 19px;
    padding-left: 42px;
    transition: all 100ms;

    &::placeholder {
      color: $color-text-faint;
    }
  }

  &-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: 2px 1px 10px 1px rgba(0,0,0,0.09);
    margin-top: calc($spacer / 2);
    max-height: 270px;
    overflow: auto;
    background-color: white;
    z-index: 1;

    &-np {
      display: flex;
      flex-direction: row;
      border-top: 1px solid $color-gray-ice;
      padding: $spacer;
      align-items: center;
      color: inherit;

      &-glass {
        width: 20px;
        height: 20px;
        color: $color-gray-fog;
        margin-right: $spacer;
      }
      &-image {
        width: 30px;
        height: 30px;
        display: block;
        object-fit: cover;
        margin-right: $spacer;
        border-radius: 100%;
      }
      &-text {
        flex: 1;
      }
      &-subtext {
        display: flex;
        flex-direction: row;
        color: $color-text-faint;
        justify-content: space-between;
        align-items: center;
      }
      &-name {
        font-size: 16px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        height: 21px;
        overflow: hidden;
      }

      &.selected, &:hover, &:active {
        text-decoration: none;
        background-color: $color-highlight-blue;
      }
    }
  }

  &.has-category {
    .search-field-input {
      background-color: white;
      box-shadow: 2px 1px 10px 1px rgba(0,0,0,0.09);
    }
  }

  &.focused {
    .search-field-input {
      background-color: white;
      border-color: $color-accent-blue;
    }
  }

  &.small {
    .search-field-glass {
      width: 17px;
      left: 14px;
    }
    .search-field-input-con {
      height: 36px;
    }
    .search-field-input {
      font-size: 14px;
      padding-left: 37px;
    }
  }
}



/*
 *  Badges
 */

.np-badge-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .np-badge {
    margin-bottom: $spacer;
    &:not(:last-child) {
      margin-right: $spacer;
    }  
  }

  @include media("<large") {
    .np-badge:first-child {
      .np-badge-tooltip {
        left: 0;
        margin-left: 0;
        &:after {
          left: 27px;
          // margin-left: 0;
        }
      }
    }
  }
}

.np-badge {
  width: 54px;
  height: 54px;
  position: relative;
  flex-shrink: 0;

  img {
    display: block;
    width: 100%;
    height: 100%;
    box-shadow: 1px 2px 4px 1px rgba(0,0,0,0.13);
    border-radius: 100%;
    position: relative;
    bottom: 0;
    transition: all 200ms;
  }

  &-tooltip {
    opacity: 0;
    transform: translateY(-12px);
    background-color: white;
    width: 200px;
    position: absolute;
    left: 50%;
    margin-left: -100px;
    padding: $spacer * 2;
    bottom: 100%;
    margin-bottom: $spacer * 1.5;
    box-shadow: 2px 1px 10px 1px rgba(0,0,0,0.09);
    transition: all 200ms;
    color: $color-text-default;

    p {
      margin-top: calc($spacer / 2);
      margin-bottom: 0;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 0; height: 0;
      border: 10px solid transparent;
      border-color: white transparent;
      border-width: 5px 7.5px 0;
      top: 100%; left: 50%;
      margin-left: -7.5px;
    }

    &.hover-enter {
      opacity: 0;
      transform: translateY(-12px);
    }
    &.hover-enter-active, &.hover-enter-done {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  &.hover {
    img {
      bottom: calc($spacer / 2);
    }
  }
}



/*
 *  Breadcrumbs
 */

.nonprofit-breadcrumbs {
  padding: calc($spacer / 2) 0;
  color: white;
  a {
    color: inherit;
    &:hover {
      text-decoration: none;
      opacity: 1;
    }
  }
  span, a {
    opacity: 0.5;
  }

  &.invert {
    a { opacity: 1; }
    a:hover { opacity: 0.5; }
  }
}



/*
 *  New Card
 */

.np-card {
  background-color: white;
  width: 100%;
  max-width: 360px;
  color: $color-text-default;
  box-shadow: $shadow;
  height: 339px;
  transition: all 200ms;
  @include flexbox(column, stretch, flex-start);

  &:hover {
    text-decoration: none;
    &[href] {
      transform: translateY(-2px);
      box-shadow: 2px 1px 10px 1px rgba(0,0,0,0.25);
    }
    .np-card-img-imgicon {
      opacity: 1;
    }
  }

  &.has-donate {
    height: 457px;
  }

  &-img {
    height: 203px;
    display: block;
    position: relative;
    &-img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &-imgicon {
      transition: all 200ms;
      position: absolute;
      color: white;
      opacity: 0.7;
      height: 100%;
      right: $spacer * -2;
      top: $spacer;
      stroke-width: 0.5px;
      &-con {
        position: relative;
        width: 100%;
        height: 100%;
        // border: 1px solid pink;
        overflow: hidden;
      }
    }
    &-icon {
      background-color: $color-cat-other;
      position: absolute;
      bottom: -16px;
      left: $spacer * 2;
      // z-index: 2;
      width: 32px;
      height: 32px;
      padding: 6px;
      border-radius: 100px;
      &-icon {
        width: 20px;
        color: white;
      }
    }

    @include cat-bg-light($color-cat-other);
    &.health {
      @include cat-bg-light($color-cat-health);
      .np-card-img-icon { background-color: $color-cat-health; }
    }
    &.animals {
      @include cat-bg-light($color-cat-animals);
      .np-card-img-icon { background-color: $color-cat-animals; }
    }
    &.culture {
      @include cat-bg-light($color-cat-culture);
      .np-card-img-icon { background-color: $color-cat-culture; }
    }
    &.community-dev {
      @include cat-bg-light($color-cat-community-dev);
      .np-card-img-icon { background-color: $color-cat-community-dev; }
    }
    &.education {
      @include cat-bg-light($color-cat-education);
      .np-card-img-icon { background-color: $color-cat-education; }
    }
    &.environment {
      @include cat-bg-light($color-cat-environment);
      .np-card-img-icon { background-color: $color-cat-environment; }
    }
    &.public-policy {
      @include cat-bg-light($color-cat-public-policy);
      .np-card-img-icon { background-color: $color-cat-public-policy; }
    }
    &.human-services {
      @include cat-bg-light($color-cat-human-services);
      .np-card-img-icon { background-color: $color-cat-human-services; }
    }
    &.public-services {
      @include cat-bg-light($color-cat-public-services);
      .np-card-img-icon { background-color: $color-cat-public-services; }
    }
    &.civil-rights {
      @include cat-bg-light($color-cat-civil-rights);
      .np-card-img-icon { background-color: $color-cat-civil-rights; }
    }
    &.international {
      @include cat-bg-light($color-cat-international);
      .np-card-img-icon { background-color: $color-cat-international; }
    }
    &.religion {
      @include cat-bg-light($color-cat-religion);
      .np-card-img-icon { background-color: $color-cat-religion; }
    }
  }

  &-details {
    height: 136px;
    padding: $spacer * 2;
    &-line1 {
      @include flexbox(row, flex-start, space-between);
    }
    &-location {
      font-size: 11px;
      line-height: 14px;
      // white-space: nowrap;
      padding-left: $spacer;
      color: $color-text-faint;
      flex-shrink: 0;
      max-height: 28px;
      max-width: 100px;
      text-align: right;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    &-title {
      font-weight: $cerebri-bold;
      font-size: 14px;
      line-height: 17px;
      max-height: 34px;
      overflow: hidden;
      margin: 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      a {
        color: inherit;
        transition: all 200ms;
        &:hover {
          color: $color-accent-pink;
          text-decoration: none;
        }
      }
    }
    &-desc {
      font-size: 11px;
      line-height: 14px;
      max-height: 42px;
      overflow: hidden;
      margin: 0;
      margin-top: $spacer;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }

  &-donate {
    @include flexbox(column, center, flex-start);
    height: 94px + calc($spacer * 2);
  }

  @mixin smallcard() {
    // border: 1px solid red !important;
    flex-direction: row;
    height: 136px;
    max-width: unset;

    .np-card-img {
      height: 100%;
      width: 136px;
      flex-shrink: 0;
      &-icon {
        left: $spacer;
        bottom: $spacer;
      }
    }
    .np-card-content {
      height: 100%;
    }
    .np-card-details {
      height: 100%;
      padding: $spacer * 1.5;
      &-line1 {
        @include flexbox(column, flex-start, flex-start);
      }
      &-location {
        max-width: unset;
        padding: 0;
        text-align: left;
        max-height: 14px;
        -webkit-line-clamp: 1;
      }
      &-desc {
        margin-top: $spacer * 0.75;
      }
    }
    .np-card-donate {
      display: none;
    }
  }
  &.small {
    @include smallcard();
  }
  @include media("<medium") {
    &.responsive {
      @include smallcard();
    }
  }

}
