.ca-drive-track {

  &-choices {
    padding-top: $spacer * 2;

    &-choice {
      border: 1px solid $color-gray-fog;
      display: block;
      width: 100%;
      margin: 0;
      margin-top: $spacer * 2;
      padding: ($spacer * 2) ($spacer * 3);
      cursor: pointer;
      text-align: left;
      border-radius: 8px;
      position: relative;

      h2 {
        margin: 0;
      }

      p {
        margin: 0;
        margin-top: $spacer * 1;
      }

      svg.icon {
        width: 20px;
        position: absolute;
        top: 50%;
        margin-top: -10px;
        right: $spacer * 1.5;
        color: $color-gray-fog;
      }

      &:hover {
        background-color: $color-highlight-blue;
        border-color: $color-accent-blue;
        svg.icon {
          color: $color-accent-blue;
        }
      }
    }
  }

  .date-picker {
    display: block;
  }

}
