.page-cadmin-matches {

  .btn.load-more {
    display: block;
    margin: ($spacer * 3) auto 0;
  }

  // .ca-box-tabs-tab.inquiries, .ca-box-tabs-tab.offdons {
  //   position: relative;
  //   &:after {
  //     display: block;
  //     content: '';
  //     width: 1px;
  //     height: 100%;
  //     position: absolute;
  //     top: 2px;
  //     left: $spacer * -2;
  //     background-color: $color-gray-cloud;
  //   }
  //   // border-left: 1px solid $color-gray-cloud;
  //   margin-left: $spacer * 4;
  // }

  th, td {
    &.status-actions {
      .btn:not(:first-child) {
        margin-left: calc($spacer / 2);
      }
    }

    &.match-status-declined, &.match-status-rejected {
      color: $color-danger;
    }

    &.receipt-img {
      svg.icon {
        width: 22px;
        cursor: pointer;
        &:hover {
          color: $color-accent-pink;
        }
      }
    }

    &.track {
      button {
        cursor: pointer;
      }
      svg.icon {
        width: 20px;
      }
    }
  }

}


.ca-offdon-track-another {
  @include flexbox(row, center, center);
  margin-right: $spacer;
  label {
    margin-left: calc($spacer / 2);
    padding: calc($spacer / 2) 0;
  }
}
