.ca-wallet {

  &-pm-loading {
    width: 24px;
    color: $color-gray-fog;
    margin: $spacer 0;
    display: block;
  }

  &-pm {
    padding: $spacer ($spacer * 1.5);
    border: 1px solid $color-gray-cloud;
    position: relative;
    margin-bottom: $spacer;
    font-size: 15px;

    &-desc {
      margin-top: calc($spacer / 2);
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 20px + ($spacer * 2.5);

      svg.icon {
        width: 22px;
        margin-right: $spacer;
        flex-shrink: 0;
      }

      &-status {
        text-transform: uppercase;
        font-weight: $cerebri-bold;
        font-size: 13px;
        color: white;
        padding: calc($spacer / 4) $spacer;
        border-radius: 100px;
        margin-left: $spacer;
      }

      &-pending {
        background-color: $color-gray-slate;
      }

      &-linked {
        background-color: $color-accent-green;
      }
    }

    &-micro-deposits-btn {
      margin: ($spacer * 1.5) 0 ($spacer * 0.5) 0;
    }

    &-data-contain {
      /* So that the delete button can be vertically centered relative to this
       * container: */
      position: relative;
    }

    &-delete {
      position: absolute;
      top: 50%;
      right: 0;
      border: none;
      background: none;
      cursor: pointer;
      color: $color-gray-slate;
      margin-top: -10px;
      outline: none;

      &:hover {
        color: $color-gray-midnight;
      }

      svg.icon {
        width: 20px;
      }
    }

    &-conflict {
      color: $color-danger;
      margin: 0;
      margin-top: $spacer;
    }
  }

  &-pm-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: $spacer * 2;
    .btn {
      margin: 0 calc($spacer / 2);
    }
  }

  &-program-settings-heading {
    font-weight: $cerebri-bold;
    margin: 0;
    padding-left: $spacer * 1.5;
  }

  &-cols {
    display: flex;
    flex-direction: row;

    &-left {
      flex: 2;
    }
    &-right {
      min-width: 361px;
      flex: 1;
      margin-left: $spacer * 2;
    }
  }

  &-balance {
    text-align: right;
    color: $color-accent-green;
    margin-top: $spacer * 2;
    &-amount {
      font-size: 37px;
      font-weight: $cerebri-heavy;
    }
    &-label {
      font-size: 13px;
      text-transform: uppercase;
    }
  }

}

.ca-program-settings {
  // max-width: 700px;
  .ca-box-header-title {
    font-size: 14px;
    font-weight: $cerebri-bold;
    text-transform: uppercase;
    svg.icon {
      width: 22px;
    }
  }

  &.grant {
    .ca-box-header-title { color: $color-accent-pink; }
  }
  &.gift {
    .ca-box-header-title { color: $color-accent-blue; }
  }
  &.match {
    .ca-box-header-title { color: $color-accent-purple; }
  }
  &.payroll {
    .ca-box-header-title { color: $color-accent-green; }
  }
  &.dfd {
    .ca-box-header-title { color: $color-accent-orange; }
  }

  &-fields {
    display: flex;
    flex-direction: row;
    align-items: center;

    .payment-select {
      width: 280px;
      margin-right: $spacer * 2;
      flex-shrink: 0;
    }

    .checkbox {
      flex-shrink: 0;
      margin-right: calc($spacer / 2);
    }

    label {
      font-size: 13px;
    }
  }

  &-subtext {
    // @extend .faint;
    font-size: 13px;
    // font-style: italic;
    margin-bottom: 0;
    color: $color-text-medium-1-5;
  }
}

.payment-select {
  &-option {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg.icon {
      width: 22px;
      margin-right: calc($spacer / 2);
      flex-shrink: 0;
    }

    &.selected {
      font-weight: $cerebri-bold;
      padding-right: $spacer * 2;
    }

    &:not(.selected) {
      padding: $spacer;
      cursor: pointer;
      border-left: 2px solid transparent;
      &:hover {
        background-color: $color-highlight-blue;
        color: $color-accent-blue;
        font-weight: $cerebri-bold;
        border-color: $color-accent-blue;
      }
    }
  }
}

.ca-upload {
  .amount-selector {
    margin: 0 auto;
  }
  .payment-select {
    width: 100%;
  }
  .btn.special {
    width: 100%;
    margin-top: $spacer;
    text-transform: uppercase;
  }
  &-hr {
    margin: ($spacer * 3) 0;
  }
  &-payment-heading {
    text-align: center;
    font-weight: $cerebri-heavy;
    font-size: 15px;
  }
  &-fp {
    @extend .faint;
  }
  &-stripe, &-fp {
    padding: 0 $spacer;
  }
}

.ca-budg {
  &-progress {
    background-color: $color-gray-cloud;
    height: 8px;
    width: 200px;
    border-radius: 100px;
    overflow: hidden;
    margin-top: calc($spacer / 2);
    &-complete {
      height: 100%;
      background: $gradient-green;
      border-radius: 100px;
    }
  }
}


.ca-modal-assign-pm {
  &-pro {
    @include flexbox(row, center, flex-start);
    gap: $spacer * 0.5;
    margin-top: $spacer * 2;
    &:first-child {
      margin-top: $spacer;
    }
    &-label {
      color: $color-text-medium-1-5;
      font-weight: $cerebri-heavy;
    }
    &.active {
      .ca-modal-assign-pm-pro-label {
        color: $color-text-default;
      }
    }
  }
}
