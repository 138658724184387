
.ca-brkt-db {

  .tip {
    margin-left: calc($spacer / 2);
  }

  &-head {
    position: relative;
    margin-bottom: ($spacer * 4) - ($spacer * 0.75);
    &-h1 {
      @include flexbox(row, center, center);
      svg.icon {
        width: 29px;
        margin-right: $spacer;
      }
      color: $color-accent-purple;
      font-weight: $cerebri-heavy;
      font-size: 35px;
      margin: 0;
    }
    &-status {
      position: absolute;
      right: 0;
      top: 0;
      display: inline-block;
      text-transform: uppercase;
      font-size: 13px;
      background-color: $color-gray-slate;
      color: white;
      padding: calc($spacer / 4) $spacer;
      border-radius: 100px;
      text-align: center;
      &.status-complete { background-color: $color-accent-blue; }
      &.status-in-progress { background-color: $color-accent-green; }
    }
    .ca-back-crumb {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      margin: 0;
    }
  }

  &-main {
    margin: 0 ($spacer * -0.75);
  }

  &-box {
    padding: $spacer * 3;
    margin: $spacer * 0.75;
    box-shadow: 2px 1px 10px 1px rgba(0,0,0,0.09);
    &-title {
      font-weight: $cerebri-bold;
      font-size: 16px;
      line-height: 21px;
      text-transform: uppercase;
      @include flexbox(row, center, flex-start);
    }
  }

  &-row1, &-row2, &-row3, &-row4 {
    @include flexbox(row, stretch, flex-start);
  }

  &-row1 {
    &-left  { flex: 1; }
    &-right { flex: 1; }
  }
  &-row2 {
    &-left  { flex: 2; }
    &-right { flex: 1; }
  }
  &-row3 {
    &-left  { flex: 1; }
    &-right { flex: 1; }
  }
  &-row4 {
    &-left  { flex: 1; }
    // &-right { flex: 1; }
  }
  &-row5, &-row6 {
    padding-top: 1px;
  }

  .stat-label {
    font-size: 16px;
    text-transform: uppercase;
    @include flexbox(row, center, flex-start);
  }
  .stat-accent {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 5px;
    opacity: 0.2;
    border-radius: 100px;
  }

  &-stats1 {
    @include flexbox(row, stretch, flex-start);
    margin-top: $spacer * 3;
    &-stat {
      flex: 1;
      position: relative;
      padding-left: ($spacer * 1.5) + 5px;
      &:not(:last-child) {
        padding-right: $spacer * 3;
      }
      &:before {
        @extend .stat-accent;
        background-color: $color-accent-green;
      }
      &-key {
        @extend .stat-label;
      }
      &-val {
        font-size: 33px;
        font-weight: $cerebri-heavy;
      }
    }
  }

  &-engage {
    @include flexbox(row, center, flex-start);
    margin-top: $spacer * 1.5;
    &-stat {
      &-key {
        @extend .stat-label;
      }
      &-val {
        font-weight: $cerebri-light;
        font-size: 56px;
      }
    }
  }

  &-rchart {
    @include flexbox(row, stretch, flex-start);
    margin-right: $spacer * 3;
    &-rnd {
      text-align: center;
      // border: 1px solid red;
      width: 80px;
      &-barcon {
        height: 100px;
        @include flexbox(column, center, flex-end);
        border-bottom: 1px solid $color-gray-cloud;
        margin-top: 17px + calc($spacer / 3)
      }
      &-bar {
        width: 20px;
        background: $gradient-green;
        @include flexbox(row, flex-start, center);
        &-val {
          font-size: 14px;
          line-height: 17px;
          margin-top: -17px - calc($spacer / 3);
        }
      }
      &-label {
        font-size: 14px;
        margin-top: calc($spacer / 3);
      }
    }
  }

  &-stats2 {
    @include flexbox(row, stretch, flex-start);
    &-stat {
      flex: 1;
      position: relative;
      padding-left: ($spacer * 1.5) + 5px;
      &:not(:last-child) {
        padding-right: $spacer * 3;
      }
      &:before {
        @extend .stat-accent;
        background-color: $color-accent-purple;
      }
      &-key {
        @extend .stat-label;
      }
      &-val {
        font-size: 33px;
        font-weight: $cerebri-heavy;
      }
    }
  }

  &-rand {
    @include flexbox(column, stretch, space-around);
    height: 100%;
    .progress-bar + .progress-bar {
      margin-top: $spacer * 3;
    }
  }

  &-table {
    width: 100%;
    font-size: 14px;
    margin-top: -21px;
    .right {
      text-align: right;
    }
    th {
      font-size: 14px;
      line-height: 21px;
      text-transform: uppercase;
      font-weight: $cerebri-regular;
      padding: 0 calc($spacer / 2) $spacer;
    }
    td {
      padding: calc($spacer / 2);
    }
  }

  &-stats3 {
    @include flexbox(row, stretch, flex-start);
    margin-top: $spacer * 3;
    margin-right: $spacer * 6;
    &-stat {
      flex: 1;
      position: relative;
      padding-left: ($spacer * 1.5) + 5px;
      &:not(:last-child) {
        padding-right: $spacer * 3;
      }
      &:before {
        @extend .stat-accent;
        background-color: $color-accent-purple;
      }
      &-key {
        @extend .stat-label;
      }
      &-val {
        font-size: 56px;
        font-weight: $cerebri-light;
      }
    }
  }

  &-games {
    &-table {
      .badge {
        padding-right: 0;
        padding-left: $spacer;
        svg.icon {
          width: 20px !important;
        }
      }
      .sep {
        border-left: 1px solid $color-gray-ice;
      }
    }
  }

  &-people {
    @include flexbox(row, center, flex-start);
    margin-top: $spacer * 2;
    img {
      display: block;
      border-radius: 100px;
      width: 64px;
      height: 64px;
      object-fit: cover;
      margin-right: $spacer * -1;
    }
  }

  &-cats {
    @include flexbox(row, center, flex-start);
    color: white;
    border-radius: 100px;
    overflow: hidden;
    margin-top: $spacer * 2;
    &-cat {
      height: 44px;
      padding-left: $spacer;
      @include flexbox(row, center, flex-start);
      white-space: nowrap;
      svg.icon {
        width: 20px;
        flex-shrink: 0;
        margin-right: calc($spacer / 2);
      }
    }
  }

  &-votes {
    &-loading {
      width: 24px;
      color: $color-gray-fog;
    }
  }

}
