.bs {
  display: flex;
  flex-direction: row;
  height: 100vh;

  &-nav {
    width: 240px;
    background: $color-gray-ice;
    border-right: 1px solid $color-gray-cloud;
    padding: $spacer * 2;
    overflow: auto;

    &-sep {
      border-top: 1px solid $color-gray-fog;
      margin: $spacer 0;
      max-width: 220px;
    }

    a {
      display: block;
      padding: $spacer 0;
    }
  }

  &-body {
    flex: 1;
    padding: $spacer * 2;
    overflow: auto;
  }

  &.nobody {
    .bs-nav {
      width: 100%;
      border-right: none;
    }
  }

  &-pill {
    background-color: $color-gray-fog;
    padding: 1px 6px;
    border-radius: 100px;
    font-size: 12px;
    font-weight: $cerebri-bold;
    text-transform: uppercase;
    color: white;
    display: inline-block;
    &.status-sub-active, &.status-inv-paid {
      background-color: $color-accent-green;
    }
    & + .bs-pill {
      margin-left: calc($spacer / 4);
    }
  }
}

table.backstage {
  border-spacing: 0;
  border-collapse: collapse;

  th, td {
    text-align: left;
    border: 1px solid $color-gray-cloud;
    padding: $spacer;

    &.center {
      text-align: center;
    }
    &.right {
      text-align: right;
    }

    &.nowrap {
      white-space: nowrap;
    }

    .btn {
      margin-top: calc($spacer / 4);
      margin-bottom: calc($spacer / 4);
      &:not(:last-child) {
        margin-right: calc($spacer / 2);
      }
      // & + .btn {
      //   margin-left: calc($spacer / 2);
      // }
    }

    &.max-300 {
      max-width: 300px;
    }

    svg.icon {
      width: 20px;
    }

    &.na {
      background-color: #F9F9F9;
    }
  }

  th {
    background-color: $color-gray-ice;
  }

  &.sticky-header {
    th {
      position: sticky;
      top: -25px;
      z-index: 1;
    }
  }

  pre {
    margin: 0;
  }
}

.bs-page {
  &-h1 {
    display: block;
    font-size: 30px;
    font-weight: $cerebri-heavy;
    margin: 0;
    margin-bottom: $spacer;
  }
  &-h2 {
    display: block;
    margin: ($spacer * 4) 0 $spacer;
    font-size: 18px;
    font-weight: $cerebri-heavy;
  }
  &-filters {
    margin-top: $spacer * 2;
    @include flexbox(row, flex-start, flex-start);
    gap: $spacer;
    flex-wrap: wrap;
    &-filter {
      @include flexbox(column, flex-start, flex-start);
      // min-width: 200px;
      label {
        text-transform: uppercase;
        color: $color-text-medium-1-5;
      }
      .auto-complete-input, .standard-input {
        width: 200px;
        margin-bottom: 0;
        .prefix {
          color: $color-text-faint;
        }
      }
    }
  }
  &-pagination {
    margin-top: $spacer;
    justify-content: flex-start;
  }
}

.page-pending-claims {
}

.page-bs-npp {
  &-new {
    margin-top: $spacer * 8;
    min-height: 500px;
    width: 400px;
  }
}

.page-bs-pricing {
  &-script {
    padding: $spacer;
    border: 1px solid $color-gray-fog;
    background-color: $color-gray-ice;
    font-family: "Menlo", monospace;
    max-width: 100%;
    width: 600px;
    height: 400px;
    white-space: pre;
  }
}

.page-bs-cols {
  table {
    th, td {
      vertical-align: top;
    }
  }
}

.bs-modal {
  .modal-box {
    width: 400px;
    padding: $spacer * 2;
  }
}

.page-bs-pgs {
  &-card-img {
    width: 100px;
  }
  tr.sent {
    opacity: 0.25;
  }
  &-date-cell {
    min-width: 120px;
  }
}

.page-bs-np {
  &-details-table {
    tr:nth-child(1) {
      th, td {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
    tr:nth-child(2) {
      th, td {
        border-top: none;
      }
    }
  }
}

.page-bs-nps {
  &-search-form {
    display: flex;
    flex-direction: row;
    gap: 3px;
    .standard-input {
      width: 200px;
    }
  }
}

.page-bs-ffs {
  tr.deprecated {
    opacity: 0.5;
    text-decoration: line-through;
  }
  .add-entity {
    display: flex;
    flex-direction: row;
    > * {
      margin-right: $spacer;
    }
  }
}

.page-bs-companies {
  &-head {
    @include flexbox(row, center, flex-start);
    &-spacer {
      flex: 1;
    }
    .standard-input {
      margin: 0;
      margin-left: $spacer * 2;
    }
    margin-bottom: $spacer * 2;
  }
  &-img-field {
    img {
      max-height: 30px;
      max-width: 50px;
    }
  }
  table .bs-pill {
    margin-top: calc($spacer / 2);
  }
}

.page-bs-pages {
  &-cell-hidden {
    color: $color-danger;
  }
}

.bs-modal-page {
  .modal-box {
    width: 800px;
  }
  #input-body {
    width: 100%;
    height: 300px;
  }
  &-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-col {
      flex: 1;
      &:first-child {
        margin-right: $spacer;
      }
      &:last-child {
        margin-left: $spacer;
      }
    }
  }
}

.bs-overview {
  h2 {
    margin: ($spacer * 2) 0 ;
  }
  &-blocks {
    display: flex;
    flex-direction: row;
  }
  &-block {
    border: 1px solid $color-gray-cloud;
    margin: 0 ($spacer * 2);
    min-width: 200px;
    &-title {
      // font-weight: $cerebri-bold;
      padding: calc($spacer / 2) $spacer;
      background-color: $color-gray-cloud;
      font-size: 18px;
      // text-align: center;
    }
    &-stats {
      text-align: right;
      padding: $spacer;
    }
    &-stat {
      margin-top: $spacer;
      &-val {
        font-weight: $cerebri-heavy;
        font-size: 18px;
      }
    }
  }
}

.bs-nonprofits {
  &-head {
    @include flexbox(row, center, space-between);
  }
}

// .bs-modal-edit-np {
//   .standard-select {
//     display: block;
//     margin-bottom: $spacer * 1.25;
//   }
// }

.bs-bals {
  &-inputs {
    @include flexbox(row, flex-start, flex-start);
    margin-bottom: $spacer * 4;
    &-input {
      @include flexbox(column, flex-start, flex-start);
      margin-right: $spacer * 2;
      label {
        color: $color-text-faint;
        text-transform: uppercase;
      }
    }
  }
  .pagination {
    justify-content: flex-start;
    margin: ($spacer * 2) 0;
  }
}

.bs-bal {
  .pagination {
    justify-content: flex-start;
    margin: ($spacer * 2) 0;
  }
}

.bs-modal-tx {
  .modal-box {
    padding: $spacer * 4;
  }
}

.bs-actions {
  @include flexbox(row, center, flex-start);
  .btn {
    margin-right: $spacer;
  }
}

.bs-bal-trans {
  &-field {
    @include flexbox(row, flex-start, flex-start);
    margin-bottom: $spacer;
    &-label {
      width: 200px;
      font-weight: $cerebri-bold;
      height: 48px;
      line-height: 48px;
      text-align: right;
      padding: 0 ($spacer * 2);
    }
    &-input {
      flex: 1;

      .standard-input, .standard-select {
        width: 300px;
        margin-bottom: calc($spacer / 4);
      }

      &-wrapper {
        height: 48px;
        @include flexbox(row, center, flex-start);
      }
    }
  }
}

.bs-modal-comp {
  &-features {
    @include flexbox(row, flex-start, flex-start);
    &-selection {
      margin-left: $spacer;
      &-feature {
        @include flexbox(row, center, flex-start);
        margin-top: calc($spacer / 2);
        .checkbox {
          margin-right: calc($spacer / 2);
        }
        label {
          text-transform: uppercase;
        }
      }
    }
    &-controls {
      @include flexbox(column, flex-start, flex-start);
      padding-left: 100px;
      .btn {
        margin-bottom: calc($spacer / 2);
      }
    }
  }
}

.bs-feature-pill {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 100px;
  background-color: $color-gray-cloud;
  font-size: 12px;
  font-weight: $cerebri-heavy;
  text-transform: uppercase;
  & + .bs-feature-pill {
    margin-left: calc($spacer / 2);
  }
  &.off {
    color: $color-gray-slate;
  }
  &.on {
    // background-color: lighten($color-accent-green, 10%);
    background-color: $color-accent-green;
    color: white;
  }
}

.page-bs-ntee {
  &-filters {
    @include flexbox(row, center, flex-start);
    margin-bottom: $spacer;
    .standard-input, .standard-select {
      min-width: 240px;
      margin-right: $spacer;
      margin-bottom: 0;
    }
    &-cb {
      @include flexbox(row, center, center);
    }
  }
  &-icon {
    color: white;
    svg.icon {
      width: 30px !important;
    }
  }
}

.bs-modal-ntee {
  div.standard-input textarea {
    height: 200px;
  }
}

.bs-modal-irs {
  .modal-box {
    width: unset;
    max-width: 1400px;    
  }
  &-p {
    max-width: 600px;
  }
}

.bs-long-desc {
  display: inline-block;
  max-width: 500px;
}

.bs-icon-label {
  @include flexbox(row, center, flex-start);
  svg.icon {
    width: 24px;
    margin-right: calc($spacer / 2);
  }
}

.bs-modal-npr-approve {
  .np-card {
    margin: ($spacer * 2) auto;
  }
}

.bqm {
  .modal-box {
    width: 510px;
  }
  &-items {
    @include flexbox(row, center, center);
    flex-wrap: wrap;
    gap: $spacer;
    max-width: 100%;
    margin-top: $spacer * 2;
  }
  &-item {
    width: 130px;
    height: 100px;
    text-align: center;
    flex-shrink: 0;
    @include flexbox(column, center, center);
    gap: $spacer;
    border: 1px solid $color-gray-cloud;
    border-radius: 3px;
    color: $color-text-default;
    transition: all 200ms;
    padding: $spacer;
    overflow: hidden;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      // background-color: $color-gray-cloud;
      transform: translateY(-2px);
      box-shadow: 2px 1px 10px 1px rgba(0,0,0,0.15);
      border-color: $color-gray-slate;
    }
    &-key {
      border: 1px solid $color-gray-cloud;
      width: 22px;
      height: 24px;
      line-height: 22px;
      border-radius: 3px;
      background-color: $color-gray-ice;
    }
    &-label {
      font-weight: $cerebri-bold;
      flex: 1;
      @include flexbox(column, center, center);
      overflow: hidden;
    }
    &.special {
      @include flexbox(row, center, flex-start);
      width: 100%;
      height: unset;
      margin-top: $spacer * 2;
      // text-align: left;
      .bqm-item-label {
        @include flexbox(row, flex-start, flex-start);
        text-align: left;
      }
    }
  }
}
