
@keyframes gm-nonprofit-flash2 {
    0% {background-color: white;}
   17% {background-color: $color-accent-blue;}
   33% {background-color: white;}
   50% {background-color: $color-accent-blue;}
   67% {background-color: white;}
   83% {background-color: $color-accent-blue;}
  100% {background-color: white;}
}
@keyframes gm-nonprofit-flash {
    0% {background-color: white; color: $color-text-default;}
   25% {background-color: $color-accent-blue; color: white;}
   50% {background-color: white; color: $color-text-default;}
   75% {background-color: $color-accent-blue; color: white;}
  100% {background-color: white; color: $color-text-default;}
}

.gm {
  $conlen: 12px;
  $color-droppable: #E5F0FA;



  /*
   *  Bracket
   */

  &-bracket {
    background-color: $color-gray-ice;
    padding: $spacer * 4;

    &-cols {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow: auto;
      margin-top: $spacer * -4;
      margin-bottom: $spacer * -4;
    }
    &-col {
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      position: relative;

      &:first-child {
        margin-left: auto;
      }
      &:last-child {
        margin-right: auto;
      }

      // ----- connector lines -----
      &:not(.round-2):not(.round-1) {
        .gm-bracket-game-con {
          &:before {
            width: $conlen;
            height: 1px;
            right: 0;
          }
          &:nth-child(odd) {
            &:after {
              width: 1px;
              height: 100%;
              right: 0px;
              top: 50%;
            }
          }
          &:nth-child(even) {
            &:after {
              width: $conlen;
              height: 1px;
              right: $conlen * -1;
              top: 0;
            }
          }
        }
        &.side-right {
          .gm-bracket-game-con {
            &:before {
              right: unset;
              left: 0;
            }
            &:nth-child(odd) {
              &:after {
                right: unset;
                left: 0;
              }
            }
            &:nth-child(even) {
              &:after {
                right: unset;
                left: $conlen * -1;
                top: 0;
              }
            }
          }
        }
      }
      // ----- connector lines -----

      &.side-left:not(:nth-child(1)):not(:nth-child(2)) {
        .gm-bracket-game-con {
          margin-left: -160px;
        }
      }
      &.side-right:not(:nth-last-child(1)):not(:nth-last-child(2)) {
        .gm-bracket-game-con {
          margin-right: -160px;
        }
      }

    }

    &-game-con {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      position: relative;

      &:before, &:after {
        // z-index: -1;
        position: absolute;
        display: block;
        content: '';
        background-color: $color-gray-fog;
      }
    }

    &.is-edit {
      .gm-bracket-col {
        &:not(:first-child):not(:last-child) {
          .gm-game {
            width: 82px;
            background-color: #FAFAFA;
            border-color: $color-gray-fog;
            box-shadow: 0 2px 2px 2px rgba(0,0,0,0.04);
            // opacity: 0.5;
            &-slot-unknown {
              background-color: $color-gray-cloud;
              box-shadow: 0 0 10px 5px $color-gray-cloud;
            }
          }
        }
        &.side-left:not(:nth-child(1)):not(:nth-child(2)) {
          .gm-bracket-game-con {
            margin-left: -60px;
          }
        }
        &.side-right:not(:nth-last-child(1)):not(:nth-last-child(2)) {
          .gm-bracket-game-con {
            margin-right: -60px;
          }
        }
      }
    }

    &.size-32 {
      .gm-game {
        margin-top: $spacer * 2;
        margin-bottom: $spacer * 2;
      }
      .gm-bracket-cols {
        margin-top: $spacer * -2;
        margin-bottom: $spacer * -2;
      }
    }
    &.size-64 {
      .gm-game {
        margin-top: $spacer;
        margin-bottom: $spacer;
      }
      .gm-bracket-cols {
        margin-top: $spacer * -1;
        margin-bottom: $spacer * -1;
      }
    }

    &-dnd-delete {
      opacity: 0;
      transition: all 200ms;
      position: absolute;
      bottom: $spacer * 4;
      left: 50%;
      margin-left: -80px;
      width: 160px;
      border: 1px dashed $color-gray-slate;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: $spacer * 1.5;
      text-align: center;
      color: $color-gray-slate;
      svg.icon {
        width: 23px;
        margin-bottom: $spacer;
      }
      p {
        margin: 0;
      }
      &.dnd-is-dragging {
        opacity: 1;
      }
      &.dnd-is-over {
        color: $color-accent-blue;
        border-color: $color-accent-blue;
      }
    }
  }



  /*
   *  Champ
   */

  &-champ {
    position: relative;
    padding: ($spacer) ($spacer * 3);
    margin: 0 ($spacer * 2);
    &:before {
      display: block;
      position: absolute;
      content: '';
      background: $gradient-purple;
      top: 0; left: 0;
      width: 100%; height: 100%;
      z-index: 0;
      opacity: 0.05;
    }
    &-heading, &-amount {
      color: $color-accent-purple;
      text-align: center;
      line-height: 25px;
    }
    &-heading {
      text-transform: uppercase;
      margin: 0;
      margin-bottom: calc($spacer / 2);
      font-size: 14px;
      font-weight: $cerebri-regular;
    }
    &-amount {
      font-size: 16px;
      font-weight: $cerebri-heavy;
      margin-top: calc($spacer / 2);
    }
    .gm-game {
      position: relative;
      margin: 0 auto;
      &.status-ready {
        margin: ($spacer * 1.5) auto;
      }
    }
  }



  /*
   *  Game
   */

  &-game {
    border: 0px solid $color-gray-slate;
    background-color: white;
    width: 182px;
    height: 78px;
    margin: ($spacer * 4) $conlen;
    z-index: 1;
    box-shadow: 0 2px 2px 2px rgba(0,0,0,0.09);
    border-left-width: 2px;
    padding: 6px 1px;
    transition: border-color 200ms, box-shadow 200ms, top 200ms;
    color: $color-text-default;
    display: block;
    position: relative;
    &:hover {
      text-decoration: none;
    }
    &.playing:hover {
      top: -2px;
      box-shadow: 0px 6px 10px 2px rgba(0,0,0,0.09);
    }

    h4 {
      margin: 0;
      line-height: 68px;
    }

    &.is-dragging {
      border: 1px solid $color-accent-blue;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 2px;
    }

    &-slot {
      height: 33px;
      position: relative;
      // & + .gm-game-slot {
      //   margin-top: 2px;
      // }
      &.droppable {
        &.dnd-is-dragging, &.empty {
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 9px; right: 5px; bottom: 9px; left: 5px;
            background-color: transparent;
            transition: all 200ms;
            z-index: -1;
          }
        }
        &.empty {
          &:after {
            background-color: $color-gray-cloud;
          }
        }
        &.dnd-is-dragging {
          &.empty:after {
            background-color: $color-droppable;
          }
          &.dnd-is-over:after {
            background-color: $color-accent-blue;
          }
        }
      }
      &-unknown {
        height: 0px;
        background-color: $color-gray-cloud;
        box-shadow: 0 0 10px 5px $color-gray-cloud;
        top: 50%;
        position: absolute;
        left: $spacer * 2;
        right: $spacer * 2;
      }
    }

    &.status-complete {
      border-color: $color-accent-blue;
    }
    &.status-ready {
      border-color: $color-accent-purple;
      &:after {
        content: 'PLAY';
        display: block;
        position: absolute;
        top: 100%;
        left: 50%;
        background-color: $color-accent-purple;
        color: white;
        padding: 2px;
        width: 46px;
        margin-left: -23px;
        margin-top: -10px;
        text-align: center;
        font-size: 14px;
        font-weight: $cerebri-bold;
        border-radius: 100px;
      }
    }
    &.status-voted {
      border-color: $color-accent-green;
    }
  }



  /*
   *  Nonprofit
   */

  &-nonprofit {
    @include flexbox(row, center, flex-start);
    background-color: white;
    height: 33px;
    padding: 5px;
    white-space: nowrap;
    overflow: hidden;
    // position: relative;

    &-img {
      width: 23px;
      height: 23px;
      margin-right: calc($spacer / 2);
      flex-shrink: 0;
      svg.icon {
        width: 19px;
        height: 19px;
        margin: 2px;
      }
      &-img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
      }
    }
    &-name {
      flex: 1;
      overflow: hidden;
      position: relative;
      height: 23px;
      line-height: 23px;
      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 20px;
        background: linear-gradient(to left, white, white 20%, rgba(255,255,255,0));
      }
    }
    &-percent {
      flex-shrink: 0;
    }
    &.draggable {
      cursor: grab;
      .drag-handle {
        width: 9px;
        height: 13px;
        border: 10px solid white;
        border-width: 10px 7px 10px 12px;
        background-color: white;
        position: absolute;
        box-sizing: content-box;
        left: 0;
        top: 0;
        color: $color-gray-slate;
        opacity: 0;
        transition: all 100ms;
      }
      &:hover {
        .drag-handle {
          opacity: 1;
        }
      }
    }

    &.winner .gm-nonprofit-name {
      font-weight: $cerebri-bold;
    }
    &.loser {
      opacity: 0.3;
    }
    &.voted .gm-nonprofit-name {
      text-decoration: underline;
      text-underline-offset: 4px;
    }

    // &.flash {
    //   animation: gm-nonprofit-flash 1000ms;
    // }
  }



  /*
   *  Divvy
   */

  &-divvy {
    background-color: $color-gray-ice;
    .widther {
      display: flex;
      flex-direction: row;
      // justify-content: center;
    }
    &-round {
      font-size: 14px;
      text-align: center;
      padding: 0 $spacer;
      white-space: nowrap;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
      &-label {
        color: $color-text-medium-1-5;
        margin-bottom: calc($spacer / 2);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
      &-amount {
        color: $color-accent-blue;
      }
      &.current {
        .gm-divvy-round-label {
          font-weight: $cerebri-heavy;
          color: $color-text-default;
          // margin-right: 9px;
          &:before {
            content: '';
            display: inline-block;
            width: 6px; height: 6px;
            background-color: $color-accent-green;
            border-radius: 100px;
            margin-right: 3px;
          }
        }
      }
    }
  }



  /*
   *  Legend
   */

  &-legend {
    @include flexbox(row, center, flex-start);
    flex-wrap: wrap;
    &-item {
      @include flexbox(row, center, center);
      margin: 0 ($spacer * 2);
      font-size: 14px;
      white-space: nowrap;
      margin: auto;
      padding: 0 ($spacer * 2);
      &.game-complete, &.game-ready, &.game-voted {
        &:before {
          content: '';
          display: block;
          height: 2px;
          width: 13px;
          background-color: $color-gray-slate;
          margin-right: calc($spacer / 2);
        }
      }
      &.game-complete:before { background-color: $color-accent-blue; }
      &.game-ready:before { background-color: $color-accent-purple; }
      &.game-voted:before { background-color: $color-accent-green; }
      &.nonprofit-won {
        font-weight: $cerebri-bold;
      }
      &.nonprofit-voted {
        text-decoration: underline;
        text-underline-offset: 4px;
      }
    }
  }



  /*
   *  Profile
   */

  &-profile {
    background-color: $color-gray-ice;
    .gm-divvy {
      margin-top: $spacer * 4;
    }
    .gm-legend {
      padding-top: $spacer * 4;
      padding-bottom: $spacer * 4;
    }
    // padding-top: $spacer * 4;
    &-social {
      max-width: 1200px;
      margin: ($spacer * 8) auto;
      &-heading {
        font-weight: $cerebri-bold;
        font-size: 16px;
        margin: 0;
        text-align: center;
        text-transform: uppercase;
      }
      &-feed {
        margin-top: $spacer * 2;
      }
    }
  }



  /*
   *  Draft Bar
   */

  &-draft {
    .widther {
      @include flexbox(row, center, center);
      padding: ($spacer * 0.75);
    }
    background-color: $color-gray-midnight;
    color: $color-gray-slate;
    &-status {
      text-transform: uppercase;
      font-weight: $cerebri-bold;
      color: white;
    }
    &-edit, &-launch {
      margin-left: $spacer * 2;
      border-left: 1px solid rgba($color-gray-slate, 0.5);
      padding-left: $spacer * 2;
    }
    &-edit {
      &-link {
        @include flexbox(row, center, center);
        color: #00E0E7;
        svg.icon {
          width: 10px;
          margin-left: calc($spacer / 2);
        }
        &:hover {
          text-decoration: none;
          color: white;
        }
      }
    }
    &-launch {
      @include flexbox(row, center, center);
      &-link {
        display: inline-block;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        font-weight: $cerebri-bold;
        color: $color-gray-midnight;
        background: white;
        margin-left: $spacer;
        border-radius: 1px;
        padding: 0 calc($spacer / 2);
        text-transform: uppercase;
        background: $gradient-green;
        transition: color 200ms;
        &:hover {
          background: #00B8B7;
          color: white;
          text-decoration: none;
        }
      }
    }
  }



  @include media("<medium") {
    &-bracket {
      padding-left: $spacer * 2;
      padding-right: $spacer * 2;
    }
    &-divvy {
      &-round {
        &.side-right {
          display: none;
        }
      }
    }
    &-legend {
      flex-direction: column;
      align-items: flex-start;
      &-item {
        margin: 0;
      }
    }
  }

}
