.cadmin-camb {

  &-dates {
    .date-picker {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: $spacer;
      }
    }
  }

  &-match {
    &-row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
    &-cb {
      flex-shrink: 0;
      margin-right: calc($spacer / 2);
      margin-top: 4px;
    }
    .cadmin-camb-match-label {
      font-size: inherit;
      text-transform: none;
      color: inherit;
      padding-right: calc($spacer / 2);
    }
    &-amount {
      width: 110px;
      flex-shrink: 0;
    }
  }

  &-sharing {
    &-row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &-label {
      flex: 1;
      span {
        display: block;
      }
      span:first-child {
        color: $color-accent-blue;
        font-size: 12px;
      }
      span:last-child {
        text-transform: none;
      }
    }
    &-cb {
      flex-shrink: 0;
    }
  }

  &-nonprofits {
    &-validation {
      color: $color-danger;
      margin: 0 0 ($spacer * 2) 0;
    }
  }

  &-antm {
    margin-top: $spacer * 2;
    &-expl {
      margin: calc($spacer / 2) 0 0 0;
      font-style: italic;
    }
  }

  @-webkit-keyframes fade-in {
      0% { opacity: 0; }
    100% { opacity: 1; }
  }

  &-budg {
    &-abox {
      border: 1px solid $color-gray-cloud;
      padding: $spacer;
      padding-top: 7px; // account for label
      position: relative;
      &-label-con {
        text-align: center;
        text-transform: uppercase;
        position: absolute;
        top: -8px;
        left: 0;
        right: 0;
        font-size: 14px;
        line-height: 15px;
        @include flexbox(row, center, center);
      }
      &-label {
        background-color: $color-gray-ice;
        padding: 0 calc($spacer / 2);
      }
      &-heading {
        margin-top: $spacer;
        font-weight: $cerebri-bold;
      }
      .standard-select, .standard-input {
        margin-top: calc($spacer / 2);
      }
    }

    &-abox + &-help {
      margin-top: $spacer * 1.5;
    }
    &-help {
      animation: fade-in 1s 1;
      font-style: italic;
    }
  }

  &-good {
    .standard-input {
      margin-bottom: calc($spacer / 2);
    }
    &-goal {
      background-color: white;
      padding: $spacer;
      border: 1px solid $color-gray-cloud;
      border-radius: 2px;
      &.on {
        .cadmin-camb-good-goal-on label {
          color: inherit;
        }
      }
      &-on {
        @include flexbox(row, center, flex-start);
        .checkbox {
          margin-right: calc($spacer / 2);
        }
        label {
          text-transform: none;
          font-weight: $cerebri-bold;
          font-size: 14px;
        }
      }
      &-hr {
        margin: $spacer 0;
        border-top: 1px solid $color-gray-cloud;
      }
      .segmented-input {
        margin-bottom: $spacer;
      }
    }
  }

  &-drive-aet {
    &-statement {
      font-style: italic;
      margin: calc($spacer / 2) 0 0;
    }
  }

}
