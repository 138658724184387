.ca-groups {
  &-table {
    &-logo {
      img {
        display: block;
        height: 24px;
      }
    }
  }
}
