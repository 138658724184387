.emp-invite-blurb {
  padding: 260px ($spacer * 2);
  max-width: 500px;
  // border: 1px solid red;

  &-logo {
    max-width: 200px;
    max-height: 50px;
  }

  &-heading-con {
    margin: $spacer 0;
  }

  &-heading {
    @extend .jungle-text;
    font-weight: $cerebri-heavy;
    font-size: 46px;
    display: inline;
  }

  &-p1 {
    font-size: 23px;
    color: $color-accent-blue;
    font-weight: $cerebri-bold;
    margin: ($spacer * 2) 0;
  }

  &-p2 {
    font-size: 23px;
    margin-bottom: $spacer * 4;
  }

  @include media("<large") {
    padding: 140px 0 ($spacer * 4);
    // padding: 120px 0 0;

    &-heading {
      font-size: 36px;
    }
  }
}

.emp-invite-conflict {
  padding: 260px 0;
  max-width: 500px;
  margin: 0 auto;

  @include media("<large") {
    padding: 140px 0;
  }
}
