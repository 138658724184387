.page-slack-nonce {
  overflow: hidden;

  &-logo {
    width:122px;
    display: block;
    margin: ($spacer * 6) auto;
  }

  &-box {
    background-color: white;
    max-width: 540px;
    margin: 0px auto;
    padding: $spacer * 4;
    margin-bottom: $spacer * 12;
    box-shadow: 2px 1px 10px 1px rgba(0,0,0,0.09);

    h2 {
      text-align: center;
      margin: ($spacer * 3) 0 ($spacer * 1) 0;
    }
  }

  &-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &-company {
      display: block;
      max-width: 140px;
      max-height: 40px;
    }
    &-slack {
      display: block;
      width: 122px;
    }
    .connector-line {
      width: 80px;
      margin: 0 $spacer;
    }
  }

  &-actions {
    margin-top: $spacer * 2;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

}
