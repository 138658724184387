
.group-card {
  padding: $spacer * 2.5;
  text-align: center;
  box-shadow: 2px 1px 10px 1px rgba(0,0,0,0.09);
  border-radius: 3px;
  color: $color-text-default;
  transition: all 200ms;

  &:hover {
    text-decoration: none;
    transform: translateY(-12px);
    .group-card-link {
      font-weight: $cerebri-bold;
    }
  }

  &-logo {
    display: block;
    width: 220px;
    height: 110px;
    object-fit: contain;
    margin: 0 auto;
    // border: 1px solid lime;
  }

  &-hr {
    margin: ($spacer * 1.5) 0;
  }

  &-name {
    font-size: 18px;
    font-weight: $cerebri-heavy;
    margin: 0;
  }

  &-desc {
    margin: 0;
    margin-top: $spacer;
    font-size: 14px;
    line-height: 19px;
    height: 38px;
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  &-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: $color-groups-a;
    margin-top: $spacer;
    transition: all 200ms;
    svg.icon {
      width: 9px;
      margin-top: 1px;
    }
    &:hover {
      text-decoration: none;
    }
  }

}
