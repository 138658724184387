.modal-drive-track {
  &-campname {
    margin: 0;
  }

  &-form {
    .date-picker {
      display: block;
    }
  }

  &-totals {
    display: inline-block;
    margin-left: $spacer * 2;

    td {
      padding-top: $spacer;
      &:first-child {
        text-align: right;
        font-weight: $cerebri-heavy;
        font-size: 18px;
        padding-right: calc($spacer / 2);
      }
    }

    &-total {
      @include flexbox(row, flex-start, flex-start);
      margin-top: $spacer;
      &-val {
        font-weight: $cerebri-heavy;
        margin-right: $spacer;
        font-size: 18px;
      }
    }
  }

  &-entries {
    &-head {
      @include flexbox(row, flex-end, space-between);
    }
    &-new {
      display: inline-block;
      padding: $spacer 0;
      font-weight: $cerebri-bold;      
    }
  }
}


.drive-qty-input {
  &-good {
    @include flexbox(row, center, flex-start);
    &:not(:last-child) {
      margin-bottom: calc($spacer / 2);
    }
    .standard-input {
      margin: 0;
      width: 120px;
    }
    &-name {
      padding: 0 $spacer;
      font-weight: $cerebri-bold;
      border: 1px solid $color-gray-cloud;
      height: 45px;
      overflow: hidden;
      @include flexbox(row, center, flex-start);
      flex: 1;
      border-left: none;
      border-radius: 0 2px 2px 0;
      position: relative;
      left: -1px;
    }
  }
}
