.ca-sgift {

  .ca-box {
    max-width: 1000px;

    .standard-input {
      margin: 0;
    }
  }

  &-cols {
    display: flex;
    flex-direction: row;
    &-left {
      flex: 1;
    }
    &-right {
      margin-left: $spacer * 3;
      width: 300px;
    }
  }

  &-label {
    color: $color-text-medium-1-5;
    text-transform: uppercase;
    font-weight: $cerebri-bold;
    font-size: 12px;
    display: block;
    &:not(:first-child) {
      margin-top: $spacer * 1.5;
    }
  }

  &-input-row {
    display: flex;
    flex-direction: row;
    gap: $spacer;
    margin-top: $spacer;

    &-input {
      flex: 1;
      flex-shrink: 0;
      .standard-input, .dd {
        display: block;
        min-width: unset;
      }
    }
  }

  &-cta {
    @include flexbox(row, center, flex-start);
    margin-top: $spacer * 6;
    gap: $spacer * 1.5;
    &-pm {
      color: $color-text-faint;
      font-style: italic;
      flex: 1;
    }
    &-total {
      font-weight: $cerebri-bold;
    }
  }

  &-error-msg {
    color: $color-danger;
    padding: $spacer 0;
    text-align: right;
  }

}
