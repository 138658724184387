.gm-header {

  &-row1 {
    @include flexbox(row, center, center);
    color: $color-accent-purple;
    background-color: white;
    padding: ($spacer * 2);
    &-icon {
      width: 29px;
      height: 29px;
      margin-right: $spacer;
    }
    &-title {
      font-weight: $cerebri-heavy;
      font-size: 35px;
      margin: 0;
      a {
        display: inline-block;
        color: inherit;
        text-decoration: none !important;
      }
    }
  }

  &-row2 {
    background-color: #F1F1F1;
    padding: ($spacer * 2) 0;
    .widther {
      @include flexbox(row, center, center);
    }
    &-pool {
      @include flexbox(row, center, flex-start);
      flex: 1;
      &-right {
        margin-left: $spacer * 2;
      }
      &-text {
        color: $color-accent-blue;
        font-size: 20px;
      }
      &-back {
        color: inherit;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        svg.icon {
          width: 10px;
          margin-right: calc($spacer / 4);
        }
      }
    }
    &-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: $spacer * 2;
      &-label {
        margin-right: $spacer;
      }
      &-number {
        font-size: 30px;
        color: $color-accent-purple;
        font-weight: $cerebri-bold;
      }
    }
    &-cd {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: $spacer * 2;
      &-label {
        margin-right: $spacer;
      }
      &-cd {
        margin-bottom: -13px;
      }
    }
    &-round-pill {
      background-color: $color-gray-midnight;
      color: white;
      border-radius: 100px;
      font-weight: $cerebri-heavy;
      font-size: 14px;
      padding: calc($spacer / 2) $spacer;
      position: relative;
      &.current {
        padding-left: ($spacer * 1.25) + 6px;
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: $spacer;
          width: 6px;
          height: 6px;
          margin-top: -3px;
          background-color: $color-accent-green;
          border-radius: 100px;
        }
      }
    }
  }

  &.bracket-status-upcoming {
    .gm-header-row2 {
      background: linear-gradient(to right, #DEEBF3, #E7E1F1);
    }
    .gm-header-row2-cd-label {
      font-weight: $cerebri-heavy;
      color: $color-accent-purple;
    }
  }

  @include media("<medium") {
    &-row1 {
      &-icon {
        width: 24px;
        height: 24px;
      }
      &-title {
        font-size: 25px;
      }
    }
    &-row2 {
      .widther {
        flex-direction: column;
      }
      &-pool {
        justify-content: space-between;
        align-self: stretch;
        &-text {
          font-size: inherit;
        }
      }
      &-round-pill {
        margin-top: $spacer * 2;
      }
      &-left {
        margin: 0;
        margin-top: $spacer * 2;
        flex-direction: column;
        &-label {
          margin: 0;
        }
      }
      &-cd {
        margin: 0;
        margin-top: $spacer * 2;
        flex-direction: column;
        &-label {
          margin: 0;
        }
      }
    }
  }

}
