.gm-countdown {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  &-cell {
    text-align: center;
    &-val {
      font-size: 30px;
      font-weight: $cerebri-bold;
    }
    &-label {
      font-size: 10px;
      color: $color-text-faint;
      height: 13px;
      overflow: hidden;
    }
    &.sep {
      margin: 0 $spacer;
    }
  }

  &.color-purple {
    .gm-countdown-cell-val {
      color: $color-accent-purple;
    }
  }
}
