.cdash {
  overflow: auto;
  &-widther {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 ($spacer * 4);
    padding-bottom: $spacer * 8;
  }

  &-loading {
    width: 48px;
    margin: ($spacer * 8) auto;
    display: block;
    color: $color-gray-cloud;
  }

  /*
   *  Top
   */

  &-mission {
    margin-top: $spacer * 4;
    &-logo {
      display: block;
      margin: 0 auto;
      max-width: 240px;
      max-height: 50px;
    }
    &-blurb {
      max-width: 760px;
      margin: 0 auto;
      margin-top: $spacer * 2;
    }
  }

  &-year {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: $spacer;
    &-select {
      min-width: 140px;
      .dd-button {
        height: 31px;
      }
    }
    &-option:not(.selected) {
      padding: $spacer;
    }
  }

  /*
   *  Top Stats
   */

  &-top-stats {
    display: flex;
    flex-direction: row;
    margin-top: $spacer * 2;
    align-items: stretch;
    &-stat {
      flex: 1;
      color: white;
      border-radius: 3px;
      position: relative;
      text-align: right;
      padding: $spacer * 1.5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &:not(:last-child) {
        margin-right: $spacer;
      }
      &.purple { background: $gradient-purple $color-accent-purple; }
      &.orange { background: $gradient-orange $color-accent-orange; }
      &.pink { background: $gradient-pink $color-accent-pink; }
      &.blue { background: $gradient-blue $color-accent-blue; }
      &.green { background: $gradient-green $color-accent-green; }
      &-val {
        font-weight: $cerebri-heavy;
        font-size: 31px;
        padding-left: 24px; // width of icon
      }
      &-key {
        text-transform: uppercase;
        opacity: 0.7;
        font-size: 16px;
      }
      svg.icon {
        width: 24px;
        position: absolute;
        top: $spacer * 1.5;
        left: $spacer * 1.5;
      }
    }
  }

  /*
   *  Campaigns
   */

  &-campaigns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: $spacer * 1.5;
    margin-right: $spacer * -1;

    &-campaign {
      width: 50%;
      padding-right: $spacer;
      padding-top: $spacer;
    }
  }

  /*
   *  Social
   */

  &-social {
    margin-top: $spacer * 1.5;
  }

  /*
   *  Common
   */

  &-box {
    background-color: white;
    border-radius: 3px;
    box-shadow: 2px 1px 10px 1px rgba(0,0,0,0.09);
    padding: $spacer * 2;
    position: relative;
    &-heading {
      font-size: 16px;
      font-weight: $cerebri-bold;
      text-transform: uppercase;
      margin: 0;
      &.center {
        text-align: center;
      }
    }
    &-private {
      width: 17px;
      position: absolute;
      top: $spacer * 2;
      right: $spacer * 2;
      color: $color-gray-slate;
    }
    &-table {
      margin-top: $spacer;
      width: 100%;
      td:first-child {
        color: $color-text-faint;
        text-align: right;
        padding-left: 0;
      }
      td:last-child {
        text-align: right;
        padding-right: 0;
      }
      td {
        padding: calc($spacer / 2);
      }
    }
  }

  &-section {
    margin-top: $spacer * 6;
    position: relative;
    &-title {
      margin: 0;
      text-transform: uppercase;
      font-weight: $cerebri-bold;
      font-size: 43px;
      display: inline-block;
      background-image: linear-gradient(to bottom right, #65799B, #EBEBEB);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &-content {
    }
    &.pink   .cdash-section-title { background-image: linear-gradient(to bottom right, #D01782, adjust-color(#F269F4, $alpha: -0.69)); }
    &.purple .cdash-section-title { background-image: linear-gradient(to bottom right, #6F41BF, adjust-color(#BC5DE9, $alpha: -0.58)); }
    &.blue   .cdash-section-title { background-image: linear-gradient(to bottom right, #337EC8, adjust-color(#4DD7B7, $alpha: -0.59)); }
    &.orange .cdash-section-title { background-image: linear-gradient(to bottom right, #FF5D56, adjust-color(#FBB13E, $alpha: -0.71)); }
  }

  &-stats-row {
    display: flex;
    flex-direction: row;
    &-stat {
      flex: 1;
      padding-left: $spacer + 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      &:not(:last-child) {
        padding-right: $spacer * 2;
      }
      &:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 5px;
        border-radius: 100px;
        background-color: $color-gray-midnight;
        opacity: 0.2;
      }
      &-val {
        font-weight: $cerebri-heavy;
        font-size: 33px;
      }
      &-key {
        font-size: 16px;
        text-transform: uppercase;
      }
    }
    &.purple .cdash-stats-row-stat:before { background: $gradient-purple; }
    &.blue   .cdash-stats-row-stat:before { background: $gradient-blue; }
    &.orange .cdash-stats-row-stat:before { background: $gradient-orange; }
  }

  /*
   *  Match
   */

  &-match {
    &-row1, &-row2 {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      .cdash-box {
        height: 100%;
      }
    }
    &-row1 {
      &-cell1 { flex: 2; padding-right: $spacer * 0.75; }
      &-cell2 { flex: 1; padding-left:  $spacer * 0.75; }
    }
    &-row2 {
      margin-top: $spacer * 1.5;
      &-cell1 { flex: 1; padding-right: $spacer * 0.75; }
      &-cell2 { flex: 1; padding-left:  $spacer * 0.75; }
    }
    &-main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      &-stats {
        padding-bottom: $spacer * 3;
        margin-bottom: $spacer * 3;
        border-bottom: 1px solid $color-gray-ice;
      }
      .cdash-progress-bar {
        margin-top: $spacer * 1.5;
      }
    }
    &-nonprofits {
      td a {
        color: $color-text-default;
        &:hover {
          color: $color-accent-pink;
          text-decoration: none;
        }
      }
    }
    &-causes {
      &-cats {
        margin-top: $spacer * 2;
        height: 44px;
        // max-width: 424px;
        overflow: hidden;
        border-radius: 100px;
        background-color: $color-gray-cloud;
        &.placeholder {
          opacity: 0.12;
        }
      }
      &-cat {
        height: 100%;
        float: left;
        color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: $spacer * 0.75;
        white-space: nowrap;
        position: relative;
        &:first-child {
          padding-left: $spacer;
        }
        &:after {
          display: block;
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          width: calc($spacer / 2);
          background-color: inherit;
          height: 100%;
        }
        svg.icon {
          flex-shrink: 0;
          width: 21px;
          margin-right: calc($spacer / 2);
        }
      }
    }
    &-impacted {
      &-images {
        display: flex;
        flex-direction: row;
        margin-top: $spacer * 2;
        overflow: hidden;
      }
      &-image {
        width: 75px;
        height: 75px;
        flex-shrink: 0;
        background-size: cover;
        background-position: center;
        border-radius: 100%;
        background-color: white;

        &:not(:first-child) {
          margin-left: -9px;
        }
      }
    }
  }

  /*
   *  Gift
   */

  &-gift {
    &-row1 {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      .cdash-box {
        height: 100%;
      }
      &-cell1 { flex: 1; padding-right: $spacer * 0.75; }
      &-cell2 { flex: 1; padding-left:  $spacer * 0.75; }
    }
    .cdash-stats-row {
      margin-top: $spacer * 2;
    }
  }

  /*
   *  Community
   */

  &-com {
    &-row2 {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      margin-top: $spacer * 1.5;
      .cdash-box {
        height: 100%;
      }
      &-cell1 { flex: 1; padding-right: $spacer * 0.75; }
      &-cell2 { flex: 2; padding-left:  $spacer * 0.75; }
    }
    &-nonprofits {
      padding: ($spacer * 2) ($spacer);
      &-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }
      .np-card.small {
        width: 320px;
        margin: 0 ($spacer);
        margin-top: $spacer * 2;
      }
    }
    &-team {
      &-members {
        overflow: hidden;
        margin: $spacer * -0.75;
        margin-top: $spacer * 1.25;
      }
      &-member {
        width: 44px;
        height: 44px;
        // background-color: $color-gray-cloud;
        border-radius: 100%;
        margin: $spacer * 0.75;
        float: left;
        background-size: contain;
      }
      &-others {
        text-align: center;
        margin: 0;
        margin-top: $spacer * 2;
      }
    }
    &-program {
      &-details {
        margin: ($spacer * 2) 0;
      }
    }
  }

  /*
   *  Volunteer
   */

  &-vol {
    &-row1 {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      .cdash-box {
        height: 100%;
      }
      &-cell1 { flex: 2; padding-right: $spacer * 0.75; }
      &-cell2 { flex: 1; padding-left:  $spacer * 0.75; }
    }
    .cdash-stats-row {
      margin-top: $spacer * 2;
    }
    &-opps {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-right: $spacer * -1;
      margin-top: $spacer;

      &-opp {
        // background-color: $color-gray-cloud;
        width: 50%;
        padding-right: $spacer;
        padding-top: $spacer;
      }
    }
  }

  /*
   *  Grant
   */

  &-grant {
    .cdash-box {
      padding: $spacer * 1.5;
    }
    &-con {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
    &-grant {
      padding-top: 60px;
      padding-left: 40px;
      position: relative;
      margin: ($spacer * 1.5);
      &-amount {
        background-color: #D01782;
        position: absolute;
        top: 0;
        left: 0;
        height: 136px;
        width: 310px;
        color: white;
        border-radius: 3px;
        font-weight: $cerebri-heavy;
        font-size: 23px;
        line-height: 60px;
        padding-left: 40px;
      }
      .np-card.small {
        width: 310px;
      }
      &:nth-child(3n+1) .cdash-grant-grant-amount { background-color: #D01782; }
      &:nth-child(3n+2) .cdash-grant-grant-amount { background-color: #E13FB9; }
      &:nth-child(3n+3) .cdash-grant-grant-amount { background-color: #EF5FE7; }
    }
  }

  @include media("<xl") {
    &-widther {
      padding-left: $spacer * 3;
      padding-right: $spacer * 3;
    }
  }

  @include media("<large") {
    &-top-stats {
      flex-wrap: wrap;
      margin-right: $spacer * -1;
      &-stat {
        flex: unset;
        width: calc(50% - #{$spacer});
        margin-bottom: $spacer;
      }
    }
    &-campaigns, &-vol-opps {
      display: block;
      &-campaign, &-opp {
        width: unset;
      }
    }
    &-match, &-gift, &-vol, &-com {
      &-row1, &-row2 {
        display: block;
        &-cell1, &-cell2 {
          padding-left: 0;
          padding-right: 0;
        }
        &-cell2 {
          margin-top: $spacer * 1.5;
        }
      }
    }
    &-com {
      &-nonprofits {
        &-list {
          display: block;
          .np-card.small {
            width: unset;
          }
        }
      }
    }
  }

  @include media("<medium") {
    &-widther {
      padding-left: $spacer * 2;
      padding-right: $spacer * 2;
    }
    &-stats-row {
      flex-wrap: wrap;
      &-stat {
        flex: unset;
        width: 50%;
        margin-bottom: $spacer;
      }
    }
  }

}
