.popper {

  &-pop {
    position: absolute;
    z-index: 500;
    &.inactive {
      visibility: hidden;
    }
    &-arrow {
      position: relative;
      width: 0;
      height: 0;

      &:before, &:after {
        content: '';
        display: block;
        position: absolute;
      }
      &:before {
        border: 8px solid black;
      }
      &:after {
        border: 7px solid lightgray;
      }
    }
    &[data-popper-placement^='top'] > .popper-pop-arrow {
      bottom: 0px;
      &:before, &:after {
        top: 0;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
      }
      &:before { margin-left: -8px; }
      &:after { margin-left: -7px; }
    }
    &[data-popper-placement^='bottom'] > .popper-pop-arrow {
      top: 0;
      &:before, &:after {
        bottom: 0;
        border-top-color: transparent;
        border-right-color: transparent;
        border-left-color: transparent;
      }
      &:before { margin-left: -8px; }
      &:after { margin-left: -7px; }
    }
    &[data-popper-placement^='left'] > .popper-pop-arrow {
      right: 0;
      &:before, &:after {
        left: 0;
        border-top-color: transparent;
        border-bottom-color: transparent;
        border-right-color: transparent;
      }
      &:before { margin-top: -8px; }
      &:after { margin-top: -7px; }
    }
    &[data-popper-placement^='right'] > .popper-pop-arrow {
      left: 0;
      &:before, &:after {
        right: 0;
        border-top-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
      }
      &:before { margin-top: -8px; }
      &:after { margin-top: -7px; }
    }
  }

  &-popover {
    background-color: white;
    border: 1px solid $color-gray-cloud;
    border-radius: 5px;
    padding: $spacer * 2;
    box-shadow: $shadow;
    .popper-pop-arrow {
      &:before { border-color: $color-gray-cloud; }
      &:after { border-color: white; }
    }
  }

  &-tooltip {
    background-color: $color-gray-midnight;
    border: 1px solid $color-gray-midnight;
    color: white;
    border-radius: 5px;
    padding: $spacer;
    box-shadow: $shadow;
    max-width: 400px;
    .popper-pop-arrow {
      &:before { border-color: $color-gray-midnight; }
      &:after { border-color: $color-gray-midnight; }
    }
  }

  &-menu {
    background-color: white;
    box-shadow: $shadow;
  }

}
