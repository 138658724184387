.page-volme {

  &-box {
    border-radius: 3px;
    box-shadow: 2px 1px 10px 1px rgba(0,0,0,0.09);
    // padding: ($spacer * 2) ($spacer * 3);
    padding: ($spacer * 3);
    &-heading {
      font-weight: $cerebri-bold;
      text-transform: uppercase;
      font-size: 16px;
      margin: 0;
    }
  }

  &-back {
    padding-top: $spacer * 2;
    &-link {
      color: $color-text-faint;
      font-size: 17px;
      text-transform: uppercase;
      &:hover {
        text-decoration: none;
        color: $color-accent-pink;
      }
      svg.icon {
        width: 12px;
      }
    }
  }

  &-header {
    @include flexbox(row, center, space-between);
    margin-top: $spacer * 2;
    flexwrap: wrap;
    &-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      &-img {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        background-size: cover;
        background-position: center center;
        border-radius: 100px;
        margin-right: $spacer;
      }
      &-title {
        @extend .gradient-orange-text;
        font-weight: $cerebri-heavy;
        font-size: 44px;
        margin: 0;
      }
    }
  }

  &-upcoming {
    margin-top: $spacer * 3;
    &-heading {
      font-weight: $cerebri-heavy;
      font-size: 15px;
      text-align: center;
      margin: 0;
    }
    &-cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    &-con {
      width: 50%;
      display: inline-block;
      margin-top: $spacer * 1.5;
      &:nth-child(odd) {
        padding-right: $spacer * .75;
      }
      &:nth-child(even) {
        padding-left: $spacer * .75;
      }
    }
  }

  &-widgets {
    margin-top: $spacer * 3;
    display: flex;
    flex-direction: row;
    align-items: stretch;

    &-left {
      width: 50%;
      padding-right: $spacer * 0.75;
    }
  }

  &-nums, &-dfd {
    &-stats {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: $spacer;
    }
    &-stat {
      &-val {
        @extend .gradient-orange-text;
        font-size: 35px;
        font-weight: $cerebri-heavy;
      }
      &-key {
        text-transform: uppercase;
        font-size: 12px;
        color: $color-text-faint;
      }
      &:not(:last-child) {
        margin-right: $spacer;
      }
    }
  }

  &-nums {
    display: flex;
    flex-direction: column;
  }

  &-dfd {
    margin-top: $spacer * 3;
    position: relative;
    padding-left: 130px + ($spacer * 3);

    &-icon {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 130px;
      background: $gradient-orange;
      color: white;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: $spacer;
      svg.icon {
        width: 48px;
        display: block;
        margin: 0 auto;
      }
      &:after {
        display: block;
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border: 1px solid transparent;
        border-width: 350px 30px 0 0;
        border-right-color: white;
        top: 0;
        right: 0;
      }
    }

    &-body {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &-stats {
      flex: 1;
    }
    &-disclaimer {
      flex: 1;
      border-left: 1px solid $color-gray-cloud;
      padding-left: $spacer * 3;
      margin-left: $spacer * 3;
    }
  }

  &-entries {
    margin-top: $spacer * 3;
    overflow: auto;

    table {
      margin-top: $spacer * 2;
      min-width: 700px;
      td.status.approved {
        color: $color-accent-green;
        font-weight: $cerebri-bold;
      }
    }
  }

  &-nps {
    margin-top: $spacer * 3;
    &-heading {
      font-weight: $cerebri-heavy;
      font-size: 15px;
      text-align: center;
      margin: 0;
    }
    &-cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      padding: ($spacer * .75) 0;
      .np-card {
        margin: $spacer * .75;
      }
    }
  }

  @include media("<large") {
    &-header-left {
      &-title {
        font-size: 30px;
      }
    }
    &-widgets {
      flex-direction: column;
      &-left {
        padding-right: 0;
        margin-bottom: $spacer * 1.5;
        width: 100%;
      }
    }
    &-upcoming {
      &-con {
        width: 100%;
        display: inline-block;
        margin-top: $spacer * 1.5;
        &:nth-child(odd), &:nth-child(even) {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
    &-dfd {
      &-body {
        flex-direction: column;
        align-items: stretch;
      }
      &-disclaimer {
        border-left: none;
        margin-left: 0;
        padding-left: 0;
        border-top: 1px solid $color-gray-cloud;
        margin-top: $spacer * 2;
        padding-top: $spacer * 2;
      }
    }
  }

  @include media("<medium") {
    &-dfd {
      padding-left: $spacer * 3;
      &-icon {
        display: none;
      }
    }
  }

}

.eon-input {
  &-button {
    &-selected {
      font-weight: $cerebri-bold;
    }
    &-unselected {
      color: $color-text-faint;
    }
  }
  &-menu {
    button {
      display: block;
      padding: ($spacer) $spacer;
      width: 100%;
      cursor: pointer;
      text-align: left;
      &:hover {
        background-color: $color-highlight-blue;
      }
    }
  }
}
