.page-ios {

  &-h1 {
    font-weight: $cerebri-heavy;
    font-size: 32px;
    margin: ($spacer * 4) 0 ($spacer * 2);
  }

  &-h2 {
    font-weight: $cerebri-heavy;
    font-size: 24px;
    margin: ($spacer * 4) 0 ($spacer);
  }

  &-form {
    @include flexbox(row, flex-start, flex-start);
    gap: $spacer;
    flex-wrap: wrap;
    .standard-input, .standard-select {
      width: 240px;
      margin-bottom: 0;
    }
    &-field {
      label {
        text-transform: uppercase;
        display: block;
        color: $color-text-medium-1-5;
      }
    }
  }

  &-results-con {
    min-height: 500px;
  }

  &-orgs {
    @include flexbox(row, stretch, center);
    flex-wrap: wrap;
  }

  &-org-con {
    width: 50%;
    padding-top: $spacer;
    padding-bottom: $spacer;
    &:nth-child(odd) {
      padding-right: 12px;
    }
    &:nth-child(even) {
      padding-left: 12px;
    }
  }

  &-org {
    box-shadow: $shadow;
    border-radius: 3px;
    padding-left: ($spacer * 2);
    border: 1px solid $color-gray-cloud;
    @include flexbox(row, center, flex-start);
    height: 100%;
    &-flag {
      font-size: 40px;
      flex-shrink: 0;
    }
    &-text {
      padding: ($spacer * 2);
      flex: 1;
      @include flexbox(column, flex-start, flex-start);
      gap: $spacer * 0.5;
      &-name {
        font-weight: $cerebri-bold;
        font-size: 20px;
      }
    }
    &-actions {
      height: 100%;
      @include flexbox(column, center, center);
      gap: $spacer * 2;
      padding: ($spacer * 2);
      &.on-millie {
        background-color: $color-gray-ice;
        .millie-logo {
          width: 80px;
        }
      }
      &-link {
        text-transform: uppercase;
        svg.icon {
          width: 10px;
          stroke-width: 1.5px;
        }
      }
    }
  }

  &-login-notice {
    text-align: right;
    color: $color-danger;
    margin-bottom: 0;
  }

  &-pagination {
    margin: $spacer * 4;
  }

  &-skel {
    &-countries {
      @include flexbox(row, center, center);
      flex-wrap: wrap;
      margin-top: $spacer * 3;
      margin-bottom: $spacer * 7;
      margin-right: $spacer * -1;
      margin-left: $spacer * -1;
    }
    &-country-con {
      width: 33.33333%;
    }
    &-country {
      border: 1px solid $color-gray-cloud;
      border-radius: 2px;
      @include flexbox(row, center, center);
      gap: $spacer * 2;
      padding: $spacer * 2;
      color: $color-text-default;
      transition: all 200ms;
      margin: $spacer;
      &-flag {
        font-size: 40px;
      }
      &-text {
        flex: 1;
        white-space: nowrap;
        &-name {
          font-weight: $cerebri-bold;
          font-size: 22px;
        }
        &-count {
          color: $color-text-faint;
        }
      }
      &-go {
        width: 20px;
        color: $color-gray-fog;
        transition: color 200ms;
      }
      &:hover {
        text-decoration: none;
        box-shadow: $shadow;
        transform: translateY(-2px);
        .page-ios-skel-country-go {
          color: $color-gray-slate;
        }
      }
    }
  }

  @include media("<xl") {
    // &-orgs {
    //   @include flexbox(row, stretch, center);
    //   flex-wrap: wrap;
    //   // gap: $spacer * 2;
    // }

    &-org-con {
      width: 100%;
      padding: $spacer 0;
      &:nth-child(odd) {
        padding-right: 0;
      }
      &:nth-child(even) {
        padding-left: 0;
      }
    }

    &-skel-country-con {
      width: 50%;
    }
  }

  @include media("<medium") {
    &-skel-country-con {
      width: 100%;
    }
  }

}
