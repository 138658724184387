table.default {
  width: 100%;

  th {
    color: $color-text-medium-1-5;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: $cerebri-regular;
  }

  th, td {
    text-align: left;
    padding: $spacer;
    border-bottom: 1px solid $color-gray-cloud;

    &.right {
      text-align: right;
    }
    &.nowrap {
      white-space: nowrap;
    }
  }

  & + .pagination {
    margin-top: $spacer * 2;
  }
}

table.attrs {
  td, th {
    padding: $spacer;
    text-align: left;
    &.right {
      text-align: right;
    }
  }
  th {
    font-weight: $cerebri-bold;
    color: $color-text-medium-1-2;
    text-transform: uppercase;
  }
}

table tr {
  &.pipe-green {
    td, th {
      &:first-child {
        border-left: 4px solid $color-accent-green;
      }
    }
  }
  &.pipe-danger {
    td, th {
      &:first-child {
        border-left: 4px solid $color-danger;
      }
    }
  }
  &.pipe-cloud {
    td, th {
      &:first-child {
        border-left: 4px solid $color-gray-cloud;
      }
    }
  }
  th, td {
    &.icon:first-child {
      padding-left: $spacer * 2;
    }
  }
}
