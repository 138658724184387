.cadmin-dfd {

  &-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    &-heading {
      font-weight: $cerebri-bold;
      font-size: 23px;
      margin: 0;
      margin-right: $spacer;
    }
    &-status-pill {
      font-size: 13px;
      text-transform: uppercase;
      color: white;
      background-color: $color-gray-slate;
      padding: 1px 6px;
      border-radius: 100px;
      display: inline-block;
      &.live {
        background-color: $color-accent-green;
      }
    }
  }

  &-exp-main {
    margin: ($spacer * 2) 0;
    color: $color-text-medium-1-2;
  }

  &-fields {
    position: relative;
    padding: ($spacer * 3);
    background-color: $color-gray-ice;
    &-toggle {
      position: absolute;
      top: $spacer;
      right: $spacer;
      &-edit {
        width: 22px;
        color: $color-gray-slate;
        cursor: pointer;
        &:hover {
          color: $color-gray-midnight;
        }
      }
    }
    &-fields {
      display: flex;
      flex-direction: row;
      // border-bottom: 1px solid $color-gray-cloud;
      // padding-bottom: $spacer * 2;
      margin-bottom: $spacer * 2;
    }
    &-field {
      width: 33.333%;
      &-label {
        font-weight: $cerebri-bold;
        margin: 0;
        font-size: 14px;
      }
      &-input {
        margin-top: $spacer;
        margin-bottom: 0 !important;
        max-width: 220px;
      }
      &-value {
        margin: 0;
        margin-top: $spacer;
        color: $color-accent-blue;
        font-size: 34px;
        font-weight: $cerebri-bold;
      }
    }
    &-bottom {
      display: flex;
      flex-direction: row;
      // border-top: 1px solid $color-gray-cloud;
      &-addition {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        .checkbox {
          margin-right: calc($spacer / 2);
        }
      }
      .btn {
        margin-left: $spacer;
      }
    }
  }

  &-gift {
    &-fields {
      display: flex;
      flex-direction: row;
      &-note {
        flex: 1;
        margin-right: $spacer * 4;
        max-width: 500px;
        textarea {
          height: 214px !important;
        }
      }
    }
    &-bottom {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: $spacer * 2;
      .btn {
        margin-left: $spacer;
      }
    }
  }

}
