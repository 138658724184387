.ca-modal-vte {
  &-h1 {
    font-size: 18px;
    font-weight: $cerebri-heavy;
    margin: 0;
    padding: 0 0 ($spacer * 2);
    // margin-bottom: ($spacer * 2);
    border-bottom: 1px solid $color-gray-cloud;
  }
  &-h2 {
    font-size: 16px;
    font-weight: $cerebri-heavy;
    text-transform: uppercase;
    margin: ($spacer * 2) 0 ($spacer);
  }
  &-h3 {
    font-size: 14px;
    font-weight: $cerebri-bold;
    margin: 0;
  }
  &-con {
    padding: $spacer * 3;
    width: 500px;
  }
  .dd, .standard-input, .auto-complete-input {
    display: block;
    min-width: unset;
    height: 45px;
    margin-bottom: 0;
  }
  .btn.special {
    display: block;
    width: 100%;
    margin-top: $spacer * 2;
  }
  &-row {
    @include flexbox(row, center, center);
    margin-bottom: $spacer;

    &.r1 > * {
      width: 100%;
    }
    &.r2 {
      & > *:first-child {
        margin-right: calc($spacer / 2);
        flex: 1;
      }
      & > *:last-child {
        margin-left: calc($spacer / 2);
        flex: 2;
      }
    }
    &.r3 {
      & > *:first-child {
        margin-right: calc($spacer / 2);
        flex: 2;
      }
      & > *:last-child {
        margin-left: calc($spacer / 2);
        flex: 1;
      }
    }
  }
  &-recents {
    width: 100%;
    margin-top: $spacer;

    td {
      padding: 3px 6px;
      border-bottom: 1px solid $color-gray-cloud;
      &.right {
        text-align: right;
      }
    }
  }
}
