.simple-page {
  .app-body .widther {
    max-width: 700px;
    padding-top: $spacer * 4;
    padding-bottom: $spacer * 4;
  }
  p {
    font-size: 18px;
  }
}

.page-no-ie {
  text-align: center;
  padding: $spacer * 8;

  p {
    font-size: 16px;
  }

  img {
    width: 48px;
  }
}

.page-about {

  &-top {
    padding: ($spacer * 8) 0;
    & > .widther {
      @include flexbox(row, center, center);
    }
    &-img {
      display: block;
      width: 367px;
      margin-right: $spacer * 8;
    }
    &-text {
      max-width: 606px;
    }
    &-h1 {
      display: inline;
      @extend .gradient-pink-purple-text;
      font-weight: $cerebri-light;
      font-size: 44px;
      margin: 0;
    }
    &-p1 {
      font-weight: $cerebri-light;
      font-size: 23px;
    }
    &-p1, &-p2 {
      margin: 0;
      margin-top: $spacer * 2;
    }
  }

  &-about {
    background-color: $color-gray-midnight;
    color: white;
    padding-top: ($spacer * 8);
    padding-bottom: ($spacer * 8) + 198px;
    &-text {
      max-width: 606px;
      margin: 0 auto;
    }
    &-h2-con {
      text-align: center;
    }
    &-h2 {
      display: inline;
      @extend .gradient-green-text;
      font-weight: $cerebri-heavy;
      font-size: 29px;
      margin: 0;
    }
    &-h3 {
      margin: 0;
      margin-top: $spacer * 4;
      font-weight: $cerebri-heavy;
      font-size: 18px;
    }
  }

  &-team-collage {
    // border: 1px solid red;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 0;
    &-img {
      display: block;
      width: 1851px;
      height: 395px;
      margin-top: -198px;
    }
  }

  &-team {
    background-color: $color-gray-ice;
    padding-bottom: ($spacer * 8);
    padding-top: ($spacer * 8) + 198px;
    &-text {
      max-width: 606px;
      margin: 0 auto;
    }
    &-h2-con {
      text-align: center;
    }
    &-h2 {
      display: inline;
      font-weight: $cerebri-heavy;
      font-size: 29px;
      margin: 0;
      span {
        @extend .gradient-groups-text;
      }
    }
    &-h3 {
      margin: 0;
      margin-top: $spacer * 4;
      font-weight: $cerebri-heavy;
      font-size: 18px;
    }
    &-h4 {
      margin: 0;
      margin-top: $spacer * 4;
      font-weight: $cerebri-bold;
      font-size: 15px;
    }
  }

  &-partners {
    text-align: center;
    padding-bottom: $spacer * 12;
    &-h3 {
      text-transform: uppercase;
      color: $color-text-medium-1-5;
      font-weight: $cerebri-bold;
      font-size: 15px;
      margin: 0;
      margin-top: $spacer * 12;
    }
    &-logos {
      @include flexbox(row, center, center);
      margin-top: $spacer * 3;
      img {
        display: block;
        margin: 0 ($spacer * 4);
      }
    }
  }

  @include media("<large") {
    &-top {
      & > .widther {
        flex-direction: column;
      }
      &-img {
        margin-right: 0;
        margin-bottom: $spacer * 4;
      }
    }
    &-partners {
      &-logos {
        flex-direction: column;
        img {
          margin: ($spacer * 2) 0;
        }
      }
    }
  }

}
