.group-pro {
  $circle-size: 282px;
  padding-bottom: $spacer * 8;

  &-banner {
    background-color: $color-gray-cloud;
    background-size: cover;
    background-position: center center;
    height: 500px;
  }

  &-padder {
    margin: 0 auto;
    max-width: 1440px;
    padding: 0 ($spacer * 4);
  }

  &-content {
    max-width: 700px;
    margin: 0 auto;
  }

  &-subheading {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: $cerebri-bold;
    text-align: center;
    margin: 0;
    margin-top: $spacer * 6;
  }

  &-topline {
    height: calc($circle-size / 2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: $spacer * 2;

    &-back {
      font-size: 17px;
      text-transform: uppercase;
      color: $color-text-faint;
      &:hover {
        color: $color-accent-pink;
        text-decoration: none;
      }
      svg.icon {
        width: 12px;
      }
    }
  }

  &-social {
    &-feed {
      margin-top: $spacer * 2;
    }
  }

  &-logo {
    &-con {
      position: relative;
      // margin-bottom: calc($circle-size / 2) + ($spacer * 2);
    }
    &-circle {
      width: $circle-size;
      height: $circle-size;
      background-color: white;
      border-radius: 100%;
      position: absolute;
      left: 50%;
      margin-left: ($circle-size * -0.5);
      margin-top: ($circle-size * -0.5);
      box-shadow: 2px 1px 10px 1px rgba(0,0,0,0.09);
    }
    &-img {
      display: block;
      width: $circle-size * 0.7;
      height: $circle-size * 0.7;
      margin-top: $circle-size * 0.15;
      margin-left: $circle-size * 0.15;
      object-fit: contain;
    }
  }

  &-title {
    margin: 0;
    margin-top: $spacer * 4;
    font-weight: $cerebri-heavy;
    font-size: 42px;
    text-align: center;
  }
  &-desc {
    font-size: 21px;
    margin: $spacer 0 0 0;
    text-align: center;
  }

  &-highlight {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: $spacer * 4;
    &-img {
      display: block;
      width: 180px;
      height: 180px;
      object-fit: cover;
      border-radius: 100%;
      margin-right: $spacer * 2;
    }
    &-text {
      font-size: 14px;
      flex: 1;
    }
    &:nth-child(odd) {
      flex-direction: row-reverse;
      .group-pro-highlight-img {
        margin-right: 0;
        margin-left: $spacer * 2;
      }
    }
  }

  &-events {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: $spacer * 2;
    &-event {
      width: 50%;
      padding-bottom: $spacer * 2;
      &:nth-child(odd) {
        padding-right: $spacer;
      }
      &:nth-child(even) {
        padding-left: $spacer;
      }
    }
  }

  &-expand-con {
    @include flexbox(row, center, center);
  }

  &-resources {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: $spacer * 2;

    &-resource {
      width: 50%;
      padding-bottom: $spacer * 2;
      &:nth-child(odd) {
        padding-right: $spacer;
      }
      &:nth-child(even) {
        padding-left: $spacer;
      }
    }
  }

  &-campaigns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: $spacer * 2;

    &-campaign {
      width: 50%;
      padding-bottom: $spacer * 2;
      &:nth-child(odd) {
        padding-right: $spacer;
      }
      &:nth-child(even) {
        padding-left: $spacer;
      }
    }
  }

  &-stat {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: $spacer * 4;
    &-num {
      margin-right: $spacer * 2;
      font-size: 90px;
      font-weight: $cerebri-bold;
      text-shadow: $color-gray-cloud 5px 5px 0;
      .fake-lines {
        width: 100px;
      }
    }
    &-text {
      flex: 1;
      font-size: 24px;
      font-weight: $cerebri-light;
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
      .group-pro-stat-num {
        margin-right: 0;
        margin-left: $spacer * 2;
      }
    }
  }

  &-nonprofits {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    .np-card {
      margin: $spacer * 2;
    }
  }

  &-members {
    &-box {
      margin-top: $spacer * 2;
      box-shadow: $shadow;
      padding: ($spacer * 3) ($spacer * 3) ($spacer * 2);
    }
    &-list {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      flex-wrap: wrap;
      margin-bottom: $spacer * 2;
    }
    &-member {
      width: 25%;
      padding: 0 ($spacer * 2) ($spacer) 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      // border-right: 1px solid $color-gray-cloud;
      &-avatar {
        display: block;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        object-fit: cover;
        // margin-left: $spacer;
      }
      &-name {
        padding: 0 $spacer;
      }
      &-lead {
        background: $gradient-groups;
        padding: 1px 6px;
        color: white;
        border-radius: 100px;
        text-transform: uppercase;
      }
      .fake-lines {
        width: 100%;
        margin: 0;
      }
    }
    &-membership {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: $spacer * 2;
      &-count {
        // font-weight: $cerebri-bold;
      }
      &-status {
        padding-left: $spacer * 2;
        flex: 1;
        color: $color-text-faint;
        font-style: italic;
      }
    }
  }

  &-fl {
    &-title.fake-lines {
      margin: ($spacer * 4) auto 0;
      max-width: 300px;
    }
    &-desc.fake-lines {
      margin-top: $spacer * 2;
    }
    &-nonprofit {
      display: block;
      width: 360px;
      margin: $spacer * 2;
      box-shadow: 2px 1px 10px 1px rgba(0,0,0,0.09);
    }
  }

  @include media("<xl") {
    &-padder {
      padding: 0 ($spacer * 3);
    }
    &-banner {
      height: 400px;
    }
    &-members {
      &-member {
        width: 33.333%;
      }
    }
  }
  @include media("<large") {
    &-banner {
      height: 300px;
    }
    &-topline {
      height: unset;
      padding-top: calc($circle-size / 2) + ($spacer * 2);
    }
    &-members {
      &-member {
        width: 50%;
      }
    }
    &-events {
      &-event {
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
    &-resources {
      &-resource {
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
    &-campaigns {
      &-campaign {
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
  @include media("<medium") {
    &-padder {
      padding: 0 ($spacer * 2);
    }
    &-highlight {
      &-img {
        width: 100px;
        height: 100px;
      }
    }
    &-stat {
      &-num {
        font-size: 45px;
      }
      &-text {
        font-size: 18px;
      }
    }
    &-members {
      &-member {
        width: 100%;
      }
      &-membership {
        align-items: flex-start;
        flex-direction: column;
        &-status {
          padding: ($spacer) 0;
        }
      }
    }
  }

}

.resource-card {
  color: inherit;
  box-shadow: $shadow;
  padding: $spacer;
  text-decoration: none !important;
  display: flex;
  flex-direction: row;

  &-img {
    display: block;
    width: 180px;
    max-height: 151px;
    object-fit: cover;
  }
  &-text {
    margin: $spacer;
    overflow: hidden;
    flex: 1;
    .fake-lines {
      width: 100%;
    }
  }
  &-label {
    font-weight: $cerebri-heavy;
    font-size: 16px;
    line-height: 19px;
    max-height: 38px;
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  &-desc {
    color: $color-text-faint;
    font-size: 13px;
    line-height: 16px;
    max-height: 48px;
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    margin-top: $spacer;
  }
  &-url {
    font-size: 13px;
    margin-top: $spacer;
    display: flex;
    flex-direction: row;
    align-items: center;
    &-favicon {
      display: block;
      width: 24px;
      height: 24px;
      margin-right: calc($spacer / 2);
    }
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
