.page-search {

  & > .app-body > .widther {
    min-height: 800px;
  }

  &-cat-banner {
    color: white;
    padding-bottom: $spacer * 5;
    overflow: hidden;
    @include cat-bg($color-cat-other);

    &.health { @include cat-bg($color-cat-health); }
    &.animals { @include cat-bg($color-cat-animals); }
    &.culture { @include cat-bg($color-cat-culture); }
    &.community-dev { @include cat-bg($color-cat-community-dev); }
    &.education { @include cat-bg($color-cat-education); }
    &.environment { @include cat-bg($color-cat-environment); }
    &.public-policy { @include cat-bg($color-cat-public-policy); }
    &.human-services { @include cat-bg($color-cat-human-services); }
    &.public-services { @include cat-bg($color-cat-public-services); }
    &.civil-rights { @include cat-bg($color-cat-civil-rights); }
    &.international { @include cat-bg($color-cat-international); }
    &.religion { @include cat-bg($color-cat-religion); }

    &-title {
      @include flexbox(row, center, center);
      margin-top: $spacer * 3;
      h1 {
        font-weight: $cerebri-light;
        font-size: 46px;
        margin: 0;
      }
      svg.icon {
        width: 40px;
        margin-right: $spacer;
      }
    }

    &-desc {
      max-width: 580px;
      margin: 0 auto;
      margin-top: $spacer;
    }

    &.invert {
      .page-search-cat-banner-subs {
        a { opacity: 1; }
        a:hover { opacity: 1; background-color: white; color: #333; }
      }
    }

    &-subs {
      @include flexbox(row, center, center);
      flex-wrap: wrap;
      text-align: center;
      gap: calc($spacer / 2);

      &.collapsed:not(:hover) {
        max-height: 100px;
        -webkit-mask-image: linear-gradient(to bottom, black 0%, transparent 100%);
                mask-image: linear-gradient(to bottom, black 0%, transparent 100%);
      }

      &-btn {
        margin: $spacer auto;
        color: inherit;
        @include flexbox(row, center, center);
        cursor: pointer;
        font-weight: $cerebri-bold;
        svg.icon {
          width: 14px;
          margin-right: calc($spacer / 2);
        }
      }
      &-sep {
        width: 100%;
        // border: 1px solid red;
      }
      a {
        display: block;
        color: inherit;
        font-size: 13px;
        white-space: nowrap;
        padding: calc($spacer / 3) $spacer;
        opacity: 0.5;
        border: 1px solid white;
        border-radius: 100px;
        transition: all 200ms;
        &:hover {
          opacity: 1;
          text-decoration: none;
        }
      }
    }

  }

  &-ntees-link {
    text-align: center;
    margin-top: 16px;
  }

  &-regtog-large, &-regtog-small {
    margin: 0 auto;
    margin-top: $spacer * 3;
  }

  /* A container that centers the terms input box. */
  &-terms-input-contain {
    margin-top: $spacer * 3;
    /* We want 'terms-input-box' to be 580px wide when there is room, while
     * conforming to the viewport width when there is not. Doing this flexbox
     * nonsense because obvious solutions like:
     *
     *   width: min(60px, 100vw - ($spacer * 8));
     *
     * are rejected by SASS with "Incompatible units: 'px' and 'vw'". See:
     *
     *   https://stackoverflow.com/questions/54090345/sass-incompatible-units-vw-and-px
     *
     * for more on that. */
    display: flex;

    &.has-category {
      margin-top: -$spacer * 2;
    }
  }

  /* The box that contains the terms input and its icon. */
  &-terms-input-box {
    flex-basis: 580px;
    height: $spacer * 4;
    margin: 0 auto;
    border: 1px solid transparent;
    border-radius: 50px;
    display: flex;
    background-color: $color-gray-ice;

    &:focus-within {
      border-color: $color-accent-blue;
    }
  }

  /* The terms input box changes color when a category has been selected. */
  &-terms-input-contain.has-category &-terms-input-box {
    box-shadow: 2px 1px 10px 1px rgba(0, 0, 0, 0.09);
    background-color: white;
  }

  &-terms-input-icon {
    width: 18px;
    margin-left: 18px;
    color: $color-text-faint;
  }

  /* The terms input within the terms input box. */
  &-terms-input {
    flex: 1;
    height: 100%;
    margin-left: 6px;
    border-radius: 50px;
    border: none;
    outline: none;
    background-color: transparent;
    color: $color-text-default;
    font-size: 19px;
    transition: all 100ms;

    &::placeholder {
      color: $color-text-faint;
    }
  }

  &-region {
    @include flexbox(row, center, center);
    gap: $spacer * 0.5;
    margin-top: $spacer * 2;
  }

  .cat-buttons {
    margin-top: $spacer * 3;
  }

  &-loading-icon {
    width: 48px;
    display: block;
    margin: 200px auto 400px;
    color: $color-gray-cloud;
  }

  &-results-title {
    font-weight: $cerebri-regular;
    font-size: 28px;
    text-align: center;
    margin-top: $spacer * 3;
    margin-bottom: 0;

    span {
      font-weight: $cerebri-heavy;
    }
  }

  &-cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: ($spacer * 1.5) ($spacer * -1.5);

    &-con {
      width: 396px;
      max-width: 33.333%;
      padding: $spacer * 1.5;
    }
  }

  &-filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: ($spacer * 3) auto 0 auto;
    max-width: 1152px;
    gap: calc($spacer / 2);
    flex-wrap: wrap;

    &-company {
      display: flex;
      flex-direction: row;
      align-items: center;

      &-label {
        display: flex;
        flex-direction: row;
        align-items: center;

        strong {
          padding: 0 calc($spacer / 2);
        }
      }

      &-logo {
        max-height: 32px;
      }
    }

    &-flex {
      flex: 1;
    }

    &-dropdowns {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $spacer * 0.5;
    }

    &-label {
      margin-left: $spacer * 2;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: $cerebri-light;
    }
  }

  &-global-prompt {
    text-align: center;
    gap: $spacer * 0.5;
    margin-top: $spacer * -4;
    margin-bottom: $spacer * 8;
    svg.icon {
      width: 10px;
      display: inline;
      vertical-align: middle;
      stroke-width: 1.5px;
    }
  }

  &-validation-message-contain {
    display: flex;
    justify-content: center;
    margin: ($spacer * 3) 0;
  }

  &-validation-message-box {
    border-radius: 48px;
    padding: ($spacer * 2) ($spacer * 3);
    background-color: $color-gray-ice;
  }

  &-validation-message-line {
    margin: 0;
    background-color: $color-gray-ice;
    font-size: 24px;
    font-weight: $cerebri-light;
    text-transform: uppercase;
    text-align: center;
  }

  .dd-menu-option.distance {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .dd-filter {
    width: 250px;

    .input-distance[type="text"] {
      width: 35px;
    }

    div.checkbox {
      margin-right: calc($spacer / 2);
    }

    &-faves {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: ($spacer * 1.5) $spacer;
    }

    &-distance {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: ($spacer * 1.5) $spacer;
    }

    &-city {
      margin: 0 $spacer ($spacer * 1.25);
    }

    &-sep {
      margin: 0 $spacer;
      border-top: 1px solid $color-gray-cloud;
    }

    &-badges {
      padding: $spacer;

      &-heading {
        text-transform: uppercase;
      }

      &-badge {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: $spacer;

        &-img {
          width: 28px;
          height: 28px;
          display: block;
          margin-right: calc($spacer / 2);
        }

        label {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex: 1;
          cursor: pointer;
        }
      }
    }

    &-apply {
      min-width: 150px;
      display: block;
      margin: ($spacer * 1.15) auto;
    }
  }

  .pagination {
    margin-bottom: $spacer * 8;
  }

  @include media("<large") {
    &-filters {
      flex-direction: column;
      &-dropdowns {
        flex-direction: column;
        align-self: stretch;
      }
      &-company {
        margin-bottom: $spacer;
      }
      &-flex {
        display: none;
      }
      &-label {
        margin: $spacer 0 0;
        &:first-child {
          margin-top: 0;
        }
      }
      .dd {
        width: 100%;
      }
      .country-select {
        margin-left: 0;
        margin-top: calc($spacer / 2);
      }
    }
    &-cards {
      &-con {
        max-width: 50%;
      }
    }
  }

  @include media("<medium") {
    &-cat-banner-title {
      // height: 140px;
      // padding: 0 ($spacer * 2);
      h1 {
        font-size: 30px;
      }
      svg.icon {
        flex-shrink: 0;
        width: 30px;
      }
    }
    &-cards {
      &-con {
        width: 100%;
        max-width: 100%;
        padding-bottom: 0;
      }
    }
  }

}

.regtog {
  border: 2px solid $color-gray-ice;
  border-radius: 200px;
  height: 100px;
  padding: $spacer * 0.25;
  @include flexbox(row, center, center);
  width: 340px;

  &-region {
    height: 100%;
    @include flexbox(column, center, center);
    padding: $spacer;
    border-radius: 100px;
    flex: 1;
    flex-shrink: 0;
    cursor: pointer;
    text-transform: uppercase;
    color: $color-text-medium-1-5;
    font-weight: $cerebri-bold;
    transition: all 200ms;
    &-img-con {
      height: 50px;
      width: 86px;
      position: relative;
      img {
        display: block;
        position: absolute;
        height: 100%;
        margin: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 200ms;
        &.dark {
          opacity: 0;
        }
      }
    }
    &.active, &:hover {
      color: $color-text-default;
      font-weight: $cerebri-bold;
      .regtog-region-img-con img.dark {
        opacity: 1;
      }
    }
    &.active {
      background-color: $color-gray-ice;
    }
  }

  &.small {
    width: 270px;
    display: inline-block;
    @include flexbox(row, flex-start, center);
    height: 52px;
    .regtog-region {
      flex: unset;
      @include flexbox(row, center, center);
      gap: $spacer * 0.5;
      &-img-con {
        height: 33px;
        width: 57px;
      }
      &.us {
        width: 118px;
      }
      &.global {
        width: 142px;
      }
    }
  }
}
