.ca-campaigns {

  &-loading {
    width: 24px;
    color: $color-gray-fog;
  }

  .col-status {
    text-transform: uppercase;
    font-weight: $cerebri-bold;
    &.active { color: $color-accent-green; }
    &.upcoming { color: $color-accent-yellow; }
    &.ended { color: $color-text-faint; }
  }

  &-col-activities {
    svg.icon {
      width: 22px;
      display: inline-block;
      & + svg.icon {
        margin-left: calc($spacer / 2);
      }
      &.purple { color: $color-accent-purple; }
      &.green { color: $color-accent-green; }
      &.orange { color: $color-accent-orange; }
    }
  }

  &-section {
    &-title {
      color: $color-accent-green;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      margin: ($spacer * 2) 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      svg.icon {
        width: 24px;
        color: $color-accent-pink;
        margin-right: calc($spacer / 2);
      }

      &.template {
        span {
          @extend .gradient-pink-purple-text;
        }
      }
    }

    &-live-campaigns {
      padding: ($spacer * 1) 0;

      &-cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-top: $spacer * -2;
        margin-right: $spacer * -1;
  
        .campaigns-cards-card {
          width: 50%;
          min-width: 480px;
          padding-right: $spacer * 1.5;
          padding-top: $spacer * 2;
        }
      }
    }
  }
}

.ca-modal-new-camp {
  .modal-box {
    padding: ($spacer * 4) ($spacer * 8);
    text-align: center;
  }
  &-h1 {
    font-weight: $cerebri-heavy;
    font-size: 30px;
    margin: 0;
  }
  &-p1 {
    font-weight: $cerebri-bold;
    font-size: 15px;
    margin: 0;
    margin-top: $spacer * 4;
    margin-bottom: $spacer * 2;
  }
  &-p2 {
    margin: ($spacer * 2) 0 ($spacer * 4);
    color: $color-text-faint;
  }
  &-acts {
    @include flexbox(row, center, center);
  }
  &-act {
    @include flexbox(column, center, center);
    border: 1px solid $color-gray-midnight;
    margin: 0 $spacer;
    width: 140px;
    height: 140px;
    position: relative;
    border-radius: 3px;
    cursor: pointer;
    transition: all 200ms;

    svg.icon {
      width: 40px;
    }

    .checkbox {
      position: absolute;
      top: $spacer;
      left: $spacer;
    }

    &.selected {
      background-image: $gradient-black;
      color: white !important;
    }

    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    &-label {
      font-weight: $cerebri-heavy;
      font-size: 15px;
      margin-top: $spacer;
    }

    &.green {
      border-color: $color-accent-green;
      color: $color-accent-green;
      &.selected { background-image: $gradient-green; }
    }
    &.purple {
      border-color: $color-accent-purple;
      color: $color-accent-purple;
      &.selected { background-image: $gradient-purple; }
    }
    &.orange {
      border-color: $color-accent-orange;
      color: $color-accent-orange;
      &.selected { background-image: $gradient-orange; }
    }

  }
}
