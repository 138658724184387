.dontrack {
  .modal-box {
    width: 100%;
    max-width: 800px;
  }

  &-head {
    background-color: $color-gray-ice;
    text-align:center;
    border-bottom: 1px solid $color-gray-cloud;
    &-h1 {
      display: inline-block;
      text-transform: uppercase;
      font-weight: $cerebri-heavy;
      font-size: 30px;
      margin: 0;
      margin-top: $spacer * 4;
      svg.icon {
        width: 24px;
        color: $color-rainbow-a;
        margin-right: 3px;
      }
    }
    &-tabs {
      @include flexbox(row, center, center);
      margin-top: $spacer * 2.5;
      gap: $spacer * 4;
      &-tab {
        border-bottom: 2px solid transparent;
        padding: calc($spacer / 2) 0;
        color: $color-text-faint;
        text-transform: uppercase;
        cursor: pointer;
        &.active {
          border-color: $color-gray-midnight;
          color: $color-text-default;
        }
        &:hover {
          color: $color-text-default;
        }
      }
      &.hide {
        margin-top: $spacer * 3.5;
        .dontrack-head-tabs-tab {
          display: none;
        }
      }
    }
  }

  &-loading {
    padding: $spacer * 8;
    svg.icon {
      width: 24px;
      color: $color-gray-fog;
      margin: 0 auto;
      display: block;
    }
  }

  &-obj {
    padding-top: $spacer * 3;
    padding-bottom: $spacer * 4;
    &-id {
      text-align: center;
      margin-top: $spacer * 1;
      font-weight: $cerebri-bold;
    }
    &-status {
      text-align: center;
      margin-top: $spacer * 1;
      font-size: 18px;
      &-tag {
        display: inline-block;
        border: 1px solid $color-text-faint;
        color: $color-text-faint;
        font-size: 12px;
        text-transform: uppercase;
        border-radius: 100px;
        padding: 3px 9px 3px 21px;
        margin-left: $spacer;
        position: relative;
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 9px;
          margin-top: -3px;
          border-radius: 100px;
          width: 6px;
          height: 6px;
          background-color: $color-text-faint;
        }
        &.green {
          color: $color-accent-green;
          border-color: $color-accent-green;
          &:before { background-color: $color-accent-green; }
        }
      }
    }
    &-org {
      @include flexbox(row, center, flex-start);
      gap: $spacer;
      margin: 0 ($spacer * 8) 0 ($spacer * 11);
      &-img {
        display: block;
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 100px;
      }
      &-text {
        &-name {
          font-weight: $cerebri-bold;
        }
        &-num {
          color: $color-text-faint;
        }
      }
    }
    &-details {
      margin: ($spacer * 2) ($spacer * 8) 0 ($spacer * 11);
      border: 1px solid $color-gray-cloud;
      border-radius: 3px;
      &-anon {
        color: $color-text-faint;
      }
      &-note, &-anon {
        padding: $spacer 0;
        margin: 0 ($spacer * 2);
      }
      &-anon + &-note {
        border-top: 1px solid $color-gray-cloud;
      }
    }
  }

  &-steps {
    margin: ($spacer * 4) ($spacer * 8) 0 ($spacer * 11);
  }
  &-step {
    // margin-top: $spacer * 2;
    position: relative;
    padding-bottom: $spacer * 2;
    &-status {
      width: 16px;
      height: 16px;
      border: 2px solid $color-accent-blue;
      border-radius: 100px;
      position: absolute;
      left: -40px;
      top: 3px;
      opacity: 0.5;
      svg.icon {
        width: 8px;
        top: 2px;
        left: 2px;
        position: absolute;
        color: white;
        stroke-width: 4px;
        display: none;
        overflow: visible;
      }
    }
    &-line {
      position: absolute;
      top: 17px;
      left: -33px;
      width: 2px;
      bottom: -3px;
      background-color: $color-accent-blue;
      opacity: 0.5;
      &.filled {
        opacity: 1;
      }
    }
    &-text {
      color: $color-text-faint;
      &-name {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: $cerebri-bold;
      }
      &-amount {
        font-weight: $cerebri-bold;
        font-size: 22px;
        margin-top: $spacer * 0.25;
      }
    }
    &.complete {
      .dontrack-step-text-name,
      .dontrack-step-text-amount {
        color: $color-text-default;
      }
      .dontrack-step-status {
        opacity: 1;
        background-color: $color-accent-blue;
        svg.icon {
          display: block;
        }
      }
    }
  }
}
