.ca-modal-cc {

  .modal-box {
    width: 600px;
    padding: $spacer * 2;
  }

  &-title {
    margin: 0 0 $spacer 0;
    font-size: 16px;
    text-transform: uppercase;
  }


  .ca-cc-input {
    #card-element {
      border: 1px solid $color-gray-cloud;
      padding: $spacer;
      color: $color-text-default;
      border-radius: 2px;
      width: 100%;
      margin-bottom: $spacer;
    }

    &-error {
      color: $color-danger;
      margin-top: $spacer * -1;
      margin-bottom: $spacer;
    }
  }

}
