
.page-login, .page-signup, .page-forgot-pw, .page-2fa {

  .form-box {
    margin-top: $spacer * 12;
    margin-bottom: $spacer * 2;

    &-success {
      margin-bottom: $spacer * 4;
    }
  }

  .other-form-text {
    font-size: 14px;
    text-align: center;
    margin-bottom: $spacer * 4;

    a {
      font-weight: bold;
    }
  }

  .donation-target {
    margin-top: $spacer * 5;
    text-align: center;
    font-size: 17px;
    font-weight: $cerebri-bold;

    &-img {
      width: 70px;
      height: 70px;
      object-fit: cover;
      border-radius: 100%;
      display: block;
      margin: 0 auto;
    }
  }

  .redeem-target {
    margin: ($spacer * 4) auto ($spacer * -3);
    font-size: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 400px;

    &-img {
      width: 100px;
      display: block;
      margin-right: $spacer * 2;
    }
  }

  &-claim-tip {
    font-size: 12px;
    text-align: left;
    padding: 0 ($spacer + 1px) 0;
    margin-top: $spacer * -1;
    color: $color-text-medium-1-5;
  }

  &-consent-disclaimer {
    font-size: 12px;
    color: $color-text-faint;
  }

  &-choice {
    width: 260px;
    margin: 0 auto;
    margin-bottom: $spacer * 4;
    .btn {
      width: 100%;
      &:hover + p {
        color: $color-text-default;
        font-weight: $cerebri-bold;
      }
    }
    p {
      font-size: 12px;
      color: $color-text-medium-1-5;
      text-align: left;
    }
  }

  &-emp-email-success {
    h2 {
      font-size: 20px;
      font-weight: $cerebri-heavy;
      margin: ($spacer * 4) 0 $spacer;
    }
  }

  &-claim-target {
    &-con {
      margin: ($spacer * 8) auto ($spacer * 12);
      @include flexbox(column, center, flex-start);
    }
    &-title {
      font-size: 16px;
      text-transform: uppercase;
    }
  }

  &-err-msg {
    color: $color-danger;
  }

  /*
   *  Small styles
   */

  @include media("<medium") {
    .form-box {
      margin-top: $spacer * 2;
    }
    .redeem-target {
      margin-bottom: 0;
    }
  }
}

.page-2fa {
  &.widther {
    min-height: 800px;
  }
  &-phone-input {
    margin-bottom: $spacer * 1.25;
  }
  &-code-input input {
    text-align: center;
    padding-left: 0 !important;
    font-weight: $cerebri-bold;
  }
  &-helpers {
    margin-top: $spacer * 4;
  }
}

.page-signup.side-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;

  // .page-signup-side {
  // }
  // .page-signup-main {
  // }

  @include media("<large") {
    flex-direction: column;
    align-items: stretch;
  }
}

.page-verify-email {
  text-align: center;

  &-layout .app-body {
    padding: ($spacer * 8) 0;
  }

  h1 {
    color: white;
    margin: 0;

    & > * {
      vertical-align: middle;
    }

    span {
      font-size: 37px;
      font-weight: $cerebri-heavy;
      padding-right: $spacer;
    }

    svg.icon {
      display: inline-block;
      width: 37px;
      height: 37px;
    }
  }

  h2 {
    margin: $spacer * 4;

    & > * {
      vertical-align: middle;
    }

    span {
      font-weight: $cerebri-heavy;
      font-size: 26px;
      padding-right: $spacer;
    }
    svg.icon {
      width: 100%;
      height: 100%;
    }
  }

  h3 {
    font-weight: $cerebri-regular;
    font-size: 19px;
  }

  &-box {
    background: white;
    padding: $spacer * 4;
    max-width: 650px;
    margin: ($spacer * 2) auto 0;
  }

  .email-icon-con {
    width: 23px;
    height: 23px;
    position: relative;
    display: inline-block;

    &:after {
      display: block;
      content: '1';
      width: 16px;
      height: 16px;
      line-height: 16px;
      border-radius: 100%;
      background-color: $color-accent-pink;
      font-weight: $cerebri-heavy;
      color: white;
      font-size: 10px;
      position: absolute;
      right: -8px;
      top: -8px;
    }
  }

  p {
    font-size: 14px;
    a {
      font-weight: $cerebri-bold;
    }
  }

  /*
   *  Small styles
   */

  @include media("<medium") {
    h1 {
      margin-top: $spacer * 4;
    }
    h2 {
      margin: $spacer * 2;
    }
  }
}
