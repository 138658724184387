@import 'include-media/dist/include-media';

@import './vars'; // needs to come first
@import './mixins';

@import './fonts/cerebri-sans';
@import './layout/app';
@import './layout/header';
@import './layout/toasts';
@import './layout/page-header';
@import './reset';
@import './tables';
@import './forms';
@import './cards';
@import './group-card';
@import './group-profile';
@import './amount-selector';
@import './donate';
@import './modal';
@import './stripe-guarantee';
@import './auth';
@import './common';
@import './drives';
@import './social';
@import './collections';
@import './company-admin/index';
@import './company-dashboard';
@import './claim';
@import './auth-pages';
@import './style-guide-page';
@import './action-tiles';
@import './event-profiles';
@import './nonprofits';
@import './vol-event-profile';
@import './campaign-profile';
@import './simple-pages';
@import './page-groups';
@import './page-collection';
@import './madness/index';
@import './page-slack-connect-nonce';
@import './clients-list';
@import './modal-donation-tracker';
@import './modal-nonprofit-selector';
@import './page-gifts-send';
@import './page-every-cent';
@import './page-gifts-redeem';
@import './page-nonprofit-dashboard';
@import './page-nonprofit-profile';
@import './page-nonprofit-profile-edit';
@import './page-nonprofits-search';
@import './page-user-profile';
@import './page-account';
@import './page-volunteer';
@import './page-volunteer-me';
@import './page-wallet';
@import './page-happenings';
@import './page-intl-orgs-search';
@import './page-new-offline-donation';
@import './page-employee-invite';
@import './page-ntees';
@import './popper';
@import './modal-donate';
@import './modal-review-prompt';
@import './backstage';
@import './ntee-picker';

.millie-logo {
  width: 95px;

  &-g { fill: $color-gray-midnight; }
  &-tri-1 { fill: $color-accent-blue; }
  &-tri-2 { fill: $color-accent-pink; }
  &-tri-3 { fill: $color-accent-green; }

  &.dark .millie-logo {
    &-g, &-tri-1, &-tri-2, &-tri-3 { fill: $color-gray-midnight; }
  }
  &.white .millie-logo {
    &-g, &-tri-1, &-tri-2, &-tri-3 { fill: white; }
  }
}

.widther {
  max-width: 1440px;
  padding: 0 ($spacer * 4);
  margin: 0 auto;

  @include media("<xl") {
    padding: 0 ($spacer * 3);
  }
  @include media("<medium") {
    padding: 0 ($spacer * 2);
  }
}

.sunrise-text              { @include gradient-text($gradient-sunrise); }
.sunset-text               { @include gradient-text($gradient-sunset); }
.jungle-text               { @include gradient-text($gradient-jungle); }
.rainbow-text              { @include gradient-text($gradient-rainbow); }
.gradient-purple-text      { @include gradient-text($gradient-purple); }
.gradient-green-text       { @include gradient-text($gradient-green); }
.gradient-pink-text        { @include gradient-text($gradient-pink); }
.gradient-orange-text      { @include gradient-text($gradient-orange); }
.gradient-blue-text        { @include gradient-text($gradient-blue); }
.gradient-pink-purple-text { @include gradient-text($gradient-pink-purple); }
.gradient-pricing-text     { @include gradient-text($gradient-pricing); }
.gradient-slack-text       { @include gradient-text($gradient-slack); }
.gradient-groups-text      { @include gradient-text($gradient-groups); }

.spin {
  animation: spin 1.5s infinite linear;
}

@-webkit-keyframes spin {
  0%  {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

.faint {
  color: $color-text-faint;
}
.strong {
  font-weight: $cerebri-bold;
}
.italic {
  font-style: italic;
}

.cat-bg-color-animals { background-color: $color-cat-animals; }
.cat-bg-color-civil-rights { background-color: $color-cat-civil-rights; }
.cat-bg-color-community-dev { background-color: $color-cat-community-dev; }
.cat-bg-color-culture { background-color: $color-cat-culture; }
.cat-bg-color-education { background-color: $color-cat-education; }
.cat-bg-color-environment { background-color: $color-cat-environment; }
.cat-bg-color-health { background-color: $color-cat-health; }
.cat-bg-color-human-services { background-color: $color-cat-human-services; }
.cat-bg-color-public-services { background-color: $color-cat-public-services; }
.cat-bg-color-international { background-color: $color-cat-international; }
.cat-bg-color-other { background-color: $color-cat-other; }
.cat-bg-color-public-policy { background-color: $color-cat-public-policy; }
.cat-bg-color-religion { background-color: $color-cat-religion; }

.box {
  background-color: white;
  box-shadow: 2px 1px 10px 1px rgba(0,0,0,0.09);
  border-radius: 3px;
}

// hide osano widget for US users and when signed in
body.country-us, body.signed-in {
  .osano-cm-widget {
    display: none;
  }
}

.confetti-firework {
  z-index: 99999;
}

// body.modal-open {
//   position: fixed;
//   width: 100%;
// }

.emoji {
  font-variant-emoji: emoji;
  font-family: 'Apple Color Emoji', 'Noto Color Emoji', Arial, sans-serif;
  &.apple {
    font-family: 'Apple Color Emoji', Arial, sans-serif;
  }
  &.google {
    font-family: 'Noto Color Emoji', Arial, sans-serif;
  }
  &.microsoft {
    font-family: 'Segoe UI Emoji', Arial, sans-serif;
  }
}
