@mixin flexbox($direction: row, $align: center, $justify: flex-start) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin gradient-text($bg-image) {
  background-image: $bg-image;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
