.ca-vol-events {

  table.events {
    tr {
      td.status {
        font-weight: $cerebri-bold;
        text-transform: uppercase;
        &.ended { color: $color-text-faint; }
        &.live { color: $color-accent-green; }
      }
    }
  }

  &-live {
    &-heading {
      color: $color-accent-green;
      text-transform: uppercase;
      text-align: center;
      margin-top: $spacer * 3;
      margin-bottom: 0;
      font-size: 15px;
      font-weight: $cerebri-bold;
    }
    &-events {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-right: $spacer * -1.5;
    }
    &-event {
      width: 50%;
      padding-top: $spacer * 1.5;
      padding-right: $spacer * 1.5;
    }
  }

  &-type-event, &-type-vol-opp {
    background: $gradient-orange;
    color: white;
    border-radius: 100px;
    padding: 1px 6px;
    text-transform: uppercase;
    font-size: 13px;
  }
  &-type-event {
    background: $gradient-groups;
  }

}

.ca-modal-event-created {
  text-align: center;
  .modal-box {
    padding: $spacer * 4;
  }
  &-h1 {
    font-weight: $cerebri-heavy;
    font-size: 24px;
    margin: 0;
    @extend .gradient-purple-text;
  }
  &-h2 {
    font-weight: $cerebri-heavy;
    font-size: 14px;
    margin: 0;
    @extend .gradient-purple-text;
  }
  &-title {
    font-weight: $cerebri-heavy;
    font-size: 21px;
    margin: 0;
    margin-bottom: calc($spacer / 2);
  }
  &-graphic {
    height: 96px;
    color: $color-accent-purple;
    margin: ($spacer * 2) auto;
  }
  &-shifts {
    font-weight: $cerebri-bold;
    font-size: 14px;
    margin-bottom: $spacer * 2;
  }
  &-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 calc($spacer / -2);
    margin-top: $spacer * 4;
    .btn {
      margin: 0 calc($spacer / 2);
    }
  }
}
