.page-offdon {

  &-main-con {
    @include flexbox(column, flex-start, center);
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
  }

  &-dtd {
    @include flexbox(column, center, center);
    width: 100%;
    background-color: $color-gray-ice;
    padding: $spacer * 4;
    gap: $spacer * 2;
    text-align: center;
    border-top: 1px solid $color-gray-cloud;
    &-logo {
      display: block;
      max-width: 204px;
    }
    &-p {
      margin: 0;
      color: $color-text-medium-1-5;
    }
  }

  &-box {
    width: 100%;
    max-width: 700px;
    background-color: white;
    padding: $spacer * 4;
    // margin: 0 auto;

    .bform-h2 {
      margin-top: $spacer * 1;
    }
  }

  &-attr-list {
    display: inline-block;
    margin: ($spacer * 3);

    th, td {
      text-align: left;
      padding: $spacer;
      vertical-align: top;
    }
  }

  &-camp-select {
    @include flexbox(row, flex-start, flex-start);
    gap: $spacer;
  }

  .app-body {
    padding-top: ($spacer * 6);
    padding-bottom: ($spacer * 6);
  }

  &-fp {
    color: $color-text-faint;
    font-size: 12px;
  }

  @include media("<medium") {
    &-attr-list {
      margin: $spacer 0;
    }
  }

}
