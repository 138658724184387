.cap-cards {

  &-cards {
    @include flexbox(row, flex-start, flex-start);
    flex-wrap: wrap;
    gap: $spacer * 2;

    &-card {
      border: 1px solid $color-gray-cloud;
      border-radius: 3px;
      overflow: hidden;
      width: 300px;
      height: 213px;
      position: relative;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &-actions {
        opacity: 0;
        position: absolute;
        background-color: rgba(0,0,0,0.8);
        inset: 0;
        width: 100%;
        height: 100%;
        @include flexbox(row, center, center);
        gap: $spacer * 2;
        transition: all 200ms;

        & > * {
          display: block;
          width: 50px;
          height: 50px;
          @include flexbox(row, center, center);
          border-radius: 100px;
          cursor: pointer;
          &:hover {
            background-color: black;
          }
          svg.icon {
            width: 24px;
            color: white;
          }
        }
      }

      &:hover {
        .cap-cards-cards-card-actions {
          opacity: 1;
        }
      }
    }
  }

  &-modal {
    &-preview {
      width: 300px;
      display: block;
    }
  }

}
