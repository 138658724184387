.ca-nps {
  &-table {
    td.approval-status {
      &.approved {
        color: $color-accent-green;
      }
      &.banned {
        color: $color-danger;
      }
      &:not(.pending) {
        font-weight: $cerebri-bold;
      }
    }
    td.cell-ntee-icon {
      svg.icon {
        width: 20px;
      }
    }
  }
}
