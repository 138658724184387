.ca-brkt-bldr {
  background-color: white !important;
  .cadmin-builder-tab {
    background-color: white !important;
    &:after {
      background-color: white !important;
    }
  }

  &-prog {
    border: 1px solid $color-gray-cloud;
    margin: $spacer * 2;
    padding: $spacer * 2;
    &-heading {
      font-size: 17px;
      margin: 0;
      font-weight: $cerebri-heavy;
    }
    &-labels {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: $spacer * 2;
      margin-bottom: $spacer;
      &-selected {
        color: $color-accent-green;
        font-weight: $cerebri-bold;
      }
      &-left {
        color: $color-text-medium-1-5;
      }
    }
    &-bar {
      background-color: $color-gray-cloud;
      border-radius: 100px;
      overflow: hidden;
      &-progress {
        transition: all 200ms;
        background: $gradient-green;
        height: 10px;
        border-radius: 100px;
      }
    }
  }

  &-cols {
    &-col {
      background-color: $color-gray-ice;
      padding: calc($spacer / 2);
      @include flexbox(row, flex-start);
      margin-bottom: $spacer;
      font-size: 14px;
      cursor: pointer;
      &:hover &-actions-view {
        color: $color-accent-pink;
      }
      &-img {
        display: block;
        width: 94px;
        height: 88px;
        margin-right: calc($spacer / 2);
        object-fit: cover;
      }
      &-details {
        padding: calc($spacer / 2);
        @include flexbox(column, flex-start);
        align-self: stretch;
        flex: 1;
      }
      &-name {
        font-weight: $cerebri-bold;
        // white-space: nowrap;
        // overflow: hidden;
      }
      &-count {
        color: $color-text-faint;
        flex: 1;
      }
      &-actions {
        align-self: flex-end;
        &-view {
          text-transform: uppercase;
          svg.icon {
            width: 10px;
          }
        }
      }
    }
  }

  &-section {
    margin: 0 ($spacer * 2);
    margin-top: $spacer * 3;
    // border-bottom: 1px solid $color-gray-ice;
    &-heading {
      margin: 0;
      font-size: 14px;
      font-weight: $cerebri-bold;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: $spacer * 1.5;
    }
    // &-help {
    //   margin: 0;
    //   padding: $spacer 0;
    //   // padding-top: 0;
    //   color: $color-text-faint;
    //   font-size: 12px;
    // }
    .auto-complete-input + &-help {
      margin-top: $spacer * -1;
      padding-top: 0;
    }
  }

  &-nonprofits-link {
    display: block;
    text-align: center;
    text-decoration: none !important;
    border-bottom: 1px solid $color-gray-cloud;
    padding: ($spacer * 1.5) 0 ($spacer * 3) 0;
    &:hover {
      font-weight: $cerebri-bold;
    }
    svg.icon {
      width: 10px;
    }
  }

  &-noti-desc {
    margin: 0;
    margin-bottom: $spacer;
  }

  &-cb-row {
    @include flexbox(row, center, flex-start);
    margin-bottom: $spacer;
    .checkbox {
      margin-right: calc($spacer / 2);
    }
  }

}
