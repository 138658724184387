// TODO: re-evaluate whether src: local('Cerebri Sans') is appropriate or not

@font-face {
  font-family: 'Cerebri Sans';
  font-style: normal;
  font-weight: 300;
  src:
    url('/fonts/cerebri-sans/cerebrisans-light.woff2') format('woff2'),
    url('/fonts/cerebri-sans/cerebrisans-light.woff') format('woff'),
    url('/fonts/cerebri-sans/cerebrisans-light.ttf') format('truetype'),
    url('/fonts/cerebri-sans/cerebrisans-light.eot') format('embedded-opentype');
}

@font-face {
  font-family: 'Cerebri Sans';
  font-style: normal;
  font-weight: 400;
  src:
    url('/fonts/cerebri-sans/cerebrisans-regular.woff2') format('woff2'),
    url('/fonts/cerebri-sans/cerebrisans-regular.woff') format('woff'),
    url('/fonts/cerebri-sans/cerebrisans-regular.ttf') format('truetype'),
    url('/fonts/cerebri-sans/cerebrisans-regular.eot') format('embedded-opentype');
}

// @font-face {
//   font-family: 'Cerebri Sans';
//   font-style: normal;
//   font-weight: 450;
//   src:
//     url('/fonts/cerebri-sans/cerebrisans-book.woff2') format('woff2'),
//     url('/fonts/cerebri-sans/cerebrisans-book.woff') format('woff'),
//     url('/fonts/cerebri-sans/cerebrisans-book.ttf') format('truetype'),
//     url('/fonts/cerebri-sans/cerebrisans-book.eot') format('embedded-opentype');
// }

// @font-face {
//   font-family: 'Cerebri Sans';
//   font-style: normal;
//   font-weight: 500;
//   src:
//     url('/fonts/cerebri-sans/cerebrisans-medium.woff2') format('woff2'),
//     url('/fonts/cerebri-sans/cerebrisans-medium.woff') format('woff'),
//     url('/fonts/cerebri-sans/cerebrisans-medium.ttf') format('truetype'),
//     url('/fonts/cerebri-sans/cerebrisans-medium.eot') format('embedded-opentype');
// }

// @font-face {
//   font-family: 'Cerebri Sans';
//   font-style: normal;
//   font-weight: 600;
//   src:
//     url('/fonts/cerebri-sans/cerebrisans-semibold.woff2') format('woff2'),
//     url('/fonts/cerebri-sans/cerebrisans-semibold.woff') format('woff'),
//     url('/fonts/cerebri-sans/cerebrisans-semibold.ttf') format('truetype'),
//     url('/fonts/cerebri-sans/cerebrisans-semibold.eot') format('embedded-opentype');
// }

@font-face {
  font-family: 'Cerebri Sans';
  font-style: normal;
  font-weight: 700;
  src:
    url('/fonts/cerebri-sans/cerebrisans-bold.woff2') format('woff2'),
    url('/fonts/cerebri-sans/cerebrisans-bold.woff') format('woff'),
    url('/fonts/cerebri-sans/cerebrisans-bold.ttf') format('truetype'),
    url('/fonts/cerebri-sans/cerebrisans-bold.eot') format('embedded-opentype');
}

// @font-face {
//   font-family: 'Cerebri Sans';
//   font-style: normal;
//   font-weight: 800;
//   src:
//     url('/fonts/cerebri-sans/cerebrisans-extrabold.woff2') format('woff2'),
//     url('/fonts/cerebri-sans/cerebrisans-extrabold.woff') format('woff'),
//     url('/fonts/cerebri-sans/cerebrisans-extrabold.ttf') format('truetype'),
//     url('/fonts/cerebri-sans/cerebrisans-extrabold.eot') format('embedded-opentype');
// }

@font-face {
  font-family: 'Cerebri Sans';
  font-style: normal;
  font-weight: 900;
  src:
    url('/fonts/cerebri-sans/cerebrisans-heavy.woff2') format('woff2'),
    url('/fonts/cerebri-sans/cerebrisans-heavy.woff') format('woff'),
    url('/fonts/cerebri-sans/cerebrisans-heavy.ttf') format('truetype'),
    url('/fonts/cerebri-sans/cerebrisans-heavy.eot') format('embedded-opentype');
}
