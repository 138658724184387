.page-haps {

  .widther {
    margin-bottom: 100px;
  }

  &-head {
    @include flexbox(row, center, space-between);
    margin-top: $spacer * 4;
    flex-wrap: wrap;
    gap: $spacer;

    &-title {
      margin: 0;
      font-size: 44px;
      font-weight: $cerebri-heavy;
    }

    &-filters {
      @include flexbox(row, center, flex-end);
      flex-wrap: wrap;
      gap: $spacer;
      .auto-complete-input {
        width: 200px;
      }
      .auto-complete-input, .segmented-input {
        margin: 0;
      }
    }
  }

  &-type-btns {
    @include flexbox(row, center, flex-start);
    gap: $spacer;
    margin-top: $spacer * 4;
    flex-wrap: wrap;
  }

  &-loading {
    width: 48px;
    display: block;
    color: $color-gray-cloud;
    margin: 100px auto;
  }

  &-list {
    @include flexbox(row, flex-start, flex-start);
    flex-wrap: wrap;
    gap: ($spacer * 2) 0;
  }

  &-hap-con {
    width: 50%;
    &:nth-child(odd) {
      padding-right: $spacer;
    }
    &:nth-child(even) {
      padding-left: $spacer;
    }
  }

  &-group {
    margin-top: $spacer * 8;
    &-heading {
      text-align: center;
      font-weight: $cerebri-heavy;
      font-size: 24px;
      margin: 0;
      margin-bottom: $spacer * 4;
      span {
        font-weight: $cerebri-regular;
        color: $color-text-faint;
      }
    }
    &-actions {
      @include flexbox(row, center, center);
      margin-top: $spacer * 3;
    }
  }

  @include media("<medium") {
    &-hap-con {
      width: 100%;
      padding: 0 !important;
    }
  }
  @include media("<medium") {
    &-head {
      &-filters .auto-complete-input {
        display: none;
      }
    }
  }

}

