.gm-mdonate {
  text-align: center;
  .modal-box {
    width: 100%;
    max-width: 500px;
    padding: ($spacer * 4) ($spacer * 2);
  }
  .amount-selector {
    margin: 0 auto;
    margin-top: $spacer * 2;
  }
  &-heading {
    font-size: 15px;
    margin: 0;
    font-weight: $cerebri-bold;
    text-transform: uppercase;
  }
  &-donate-btn {
    width: 289px;
    margin-top: 16px;
  }
  &-desc {
    font-size: 12px;
    margin: $spacer * 2;
    margin-bottom: 0;
  }
}
