.stripe-guarantee {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: $spacer * 1.5;

  font-size: 12px;

  &.bold {
    font-weight: $cerebri-bold;
  }

  & .icon:first-child {
    position: relative;
    left: -4px;
    top: -2px;
  }

  & .icon:last-child {
    position: relative;
    top: 1px;
  }
}
