.join-cta {
  margin-top: $spacer * 8;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  background: white url("/images/home/triangles-long.png") center center / auto 100% no-repeat;

  &-box {
    width: 460px;
    max-width: 100%;
    background-color: white;
    box-shadow: 2px 1px 12px 3px rgba(0,0,0,0.09);
    display: flex;
    flex-direction: column;

    &-title {
      text-align: center;
      h2 {
        @extend .rainbow-text;
        margin: 0;
        margin-top: $spacer * 4;
        display: inline-block;
        font-size: 29px;
        font-weight: $cerebri-heavy;
      }
    }

    &-cta-con {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: ($spacer * 4) 0;

      .btn {
        width: 260px;
      }
    }

    &-cta-or {
      margin: ($spacer * 2) 0;
      text-align: center;
      color: $color-gray-fog;
      width: 260px;
      position: relative;
      & > div {
        display: inline-block;
        margin: 0 auto;
        background-color: white;
        padding: 0 $spacer;
        position: relative;
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $color-gray-cloud;
      }
    }
  }

}


.page-onboard {
  .app-body > .widther {
    padding-top: $spacer * 8;
    padding-bottom: $spacer * 16;
  }
  &-bform {
    background-color: white;
    padding: $spacer * 4;
    margin: 0 auto;
    max-width: 600px;
  }
}
