.ca-sso {
  .ca-box {
    max-width: 800px;
  }

  #input-saml-idp-url {
    margin-top: $spacer;
  }

  &-scim-badge {
    margin-left: $spacer;
    border-radius: 100px;
    padding: calc($spacer / 4) calc($spacer / 2);
    font-size: 12px;
    font-weight: $cerebri-bold;
    color: white;

    &.inactive {
      background-color: $color-gray-slate;
    }
    &.active {
      background-color: $color-accent-green;
    }
  }
}
