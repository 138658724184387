.modal-g2 {

  .modal-box {
    text-align: center;
    max-width: 600px;
    padding: ($spacer * 6);
    padding-bottom: $spacer * 3;
    font-size: 15px;
  }

  &-h1 {
    font-weight: $cerebri-heavy;
    font-size: 31px;
    margin: 0;
    margin-bottom: $spacer * 4;
  }

  &-p1, &-p2 {
    text-align: left;
  }

  &-p1, &-p2, &-thanks {
    margin: 0;
    margin-bottom: $spacer * 2;
  }

  &-sig {
    @include flexbox(row, center, center);
    margin-bottom: $spacer * 4;

    &-img {
      width: 90px;
      height: 90px;
      display: block;
      margin-right: $spacer * 2;
      border-radius: 100px;
      object-fit: cover;
    }

    &-text {
      text-align: left;
    }
  }

  &-cta {
    margin-bottom: $spacer * 3;
  }

  &-dismiss {
    &-btn {
      margin-bottom: $spacer;
    }
    &-forever {
      @include flexbox(row, center, center);
      color: $color-text-faint;
      .checkbox {
        margin-right: calc($spacer / 2);
      }
    }
  }

}
