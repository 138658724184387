.page-evcent {
  .app-body > .widther {
    min-height: 600px;
  }
  &-h1, &-h2, &-h3 {
    font-weight: $cerebri-heavy;
    margin: 0;
  }
  &-h1 {
    font-size: 44px;
    margin-top: $spacer * 4;
  }
  &-h2 {
    font-size: 30px;
    margin-top: $spacer * 4;
  }
  &-h3 {
    font-size: 24px;
    margin-top: $spacer * 2;
  }
  &-filters {
    @include flexbox(row, center, flex-start);
    gap: $spacer;
    margin-top: $spacer * 2;
  }
  &-filter {
    @include flexbox(column, flex-start, flex-start);
    label {
      color: $color-text-faint;
      text-transform: uppercase;
      font-weight: $cerebri-bold;
      font-size: 12px;
    }
  }
  &-loading {
    width: 24px;
    margin: ($spacer * 4) 0;
    color: $color-gray-fog;
  }
  &-table-in {
    max-width: 500px;
  }
  &-table-out {
    max-width: 1000px;
  }
}
