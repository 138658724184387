.cadmin-dbb {

  &-gift {
    .cadmin-builder-checkbox {
      &:not(:last-child) {
        margin-bottom: $spacer * 1.5;
      }
    }
  }

  &-comm {
    &-loved-nps {
      margin: $spacer 0 ($spacer * 2);
    }
    &-pd-text {
      margin-top: $spacer;
    }
  }

  &-sharing {
    .cadmin-builder-toggle {
      &:not(:last-child) {
        margin-bottom: $spacer * 1.5;
      }
    }
  }

}
