.ca-modal-schannel {
  .modal-box {
    padding: ($spacer * 3);
    // padding: ($spacer * 2) ($spacer * 3);
    min-width: 600px;
  }

  svg.icon.loading {
    width: 24px;
    color: $color-gray-fog;
  }

  &-header {
    display: flex;
    flex-direction: row;
    padding-bottom: $spacer * 2;
    border-bottom: 1px solid $color-gray-cloud;
    align-items: center;
    img {
      display: block;
      height: 26px;
    }
    h1 {
      font-weight: $cerebri-regular;
      margin: 0;
      font-size: 20px;
      border-left: 1px solid $color-gray-cloud;
      margin-left: $spacer * 2;
      padding-left: $spacer * 2;
    }
  }

  &-subheader {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: $cerebri-heavy;
    font-size: 16px;
    padding: $spacer 0;
    img {
      display: block;
      width: 38px;
      height: 38px;
      border-radius: 100px;
      margin-right: $spacer;
      object-fit: cover;
    }
  }

  &-channel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: ($spacer * 3) 0;
    &-icon {
      width: 20px;
      color: $color-gray-slate;
      margin-right: calc($spacer / 2);
    }
    &-name {
      font-weight: $cerebri-bold;
      font-size: 18px;
    }
    .btn {
      margin: $spacer 0 $spacer ($spacer * 2);
    }
  }

  &-input {
    background-color: $color-gray-ice;
    margin: ($spacer * 2) 0;
    &-tabs {
      display: flex;
      flex-direction: row;
      justify-content: center;
      border-bottom: 1px solid $color-gray-cloud;
    }
    &-tab {
      display: block;
      padding: ($spacer * 1.5) 0 $spacer;
      margin: 0 $spacer;
      border-bottom: 2px solid transparent;
      color: $color-text-medium-1-5;
      cursor: pointer;
      &.active {
        font-weight: $cerebri-bold;
        border-color: $color-gray-midnight;
        color: $color-text-default;
      }
    }
    &-op-desc {
      margin: ($spacer * 2) ($spacer * 2) ($spacer * 1) ($spacer * 2);
    }
    &-op-info {
      margin: 0;
      padding: 0 ($spacer * 2);
      padding-top: ($spacer * 2);
      max-width: 528px;
    }
    &-form {
      display: flex;
      flex-direction: row;
      padding: $spacer * 2;
      padding-top: 0;
      .standard-input {
        flex: 1;
        margin-right: calc($spacer / 4);
        margin-bottom: 0;
      }
    }
  }

  &-not-linked {
    color: $color-danger;
    text-align: center;
    margin-top: $spacer * -3;
    margin-bottom: $spacer * 4;
  }

  &-bottom {
    color: $color-text-faint;
    &-unlink {
      color: $color-gray-slate;
      font-weight: $cerebri-bold;
      &:hover {
        cursor: pointer;
        text-decoration: none;
        color: $color-accent-pink;
      }
    }
  }

  &-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: $spacer * 2;
  }
  &-action {
    padding: 0 ($spacer * 2);
    text-align: center;
    width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    & + .ca-modal-schannel-action {
      border-left: 1px solid $color-gray-cloud;
    }
    .btn {
      width: 100%;
    }
    p {
      margin: 0 0 ($spacer * 2) 0;
    }
    &.disabled {
      opacity: 0.5;
    }
  }
}
