.vevent-profile {

  &-location, &-slack {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: $cerebri-bold;
    margin: $spacer 0;

    svg.icon {
      width: 24px;
      margin-right: $spacer;
    }
    .fake-lines {
      flex: 1;
    }
    // a {
    //   color: $color-accent-pink;
    // }
  }

  &-shifts {
    &.many {
      .vevent-profile-shift {
        border-left: 1px solid $color-gray-fog;
        margin-left: $spacer;
        padding-left: $spacer;
        &-time {
          margin-bottom: calc($spacer / 2);
          display: flex;
          flex-direction: row;
          align-items: center;
          .fake-lines {
            flex: 1;
          }
        }
      }
    }
  }

  &-shift {
    margin-bottom: $spacer;
    svg.icon {
      width: 24px;
      margin-right: $spacer;
    }
    &-time, &-cap {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &-time {
      font-weight: $cerebri-bold;
      margin-bottom: $spacer;
      display: flex;
      flex-direction: row;
      align-items: center;
      .fake-lines {
        flex: 1;
      }
      svg.icon {
        padding: 1px;
      }
    }
  }

  &-reg {
    max-width: 1124px;
    box-shadow: 2px 1px 10px 1px rgba(0,0,0,.09);
    border-radius: 3px;
    margin: ($spacer * 4) auto 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: $spacer * 3;

    &-party {
      width: 48px;
    }
    &-heading {
      @extend .gradient-orange-text;
      display: inline-block;
      padding: 0 ($spacer * 3) 0 ($spacer * 1.5);
      margin: 0;
    }
    &-shifts {
      // border: 1px solid red;
      flex: 1;
      margin: calc($spacer / -2) 0;
    }
    &-shift {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: calc($spacer / 2) 0;
      &-time {
        flex: 1;
        font-weight: $cerebri-bold;
      }
      &-actions {
        .btn {
          margin-left: $spacer;
        }
      }
    }
  }

  &-cols {
    display: flex;
    flex-flow: row wrap;
    margin-top: $spacer * 4;
    margin-left: $spacer * -1;
    margin-right: $spacer * -1;
    justify-content: center;
    align-items: stretch;

    &-col {
      width: 360px;
      margin: $spacer;
    }

    &-fake-nonprofit {
      width: 100%;
      box-shadow: 2px 1px 10px 1px rgba(0,0,0,.09);
    }
  }

  &-box {
    box-shadow: 2px 1px 10px 1px rgba(0,0,0,.09);
    padding: $spacer * 3;
    height: 100%;
    max-height: 507px;
    overflow: auto;
    border-radius: 3px;

    &-heading {
      text-transform: uppercase;
      margin: 0;
      font-weight: $cerebri-bold;
      font-size: 16px;
      &:not(:first-child) {
        margin-top: $spacer * 2;
      }
    }
  }

  &-details {
    &-lead {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: $spacer 0;
      &-img {
        display: block;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        background-size: cover;
        margin-right: $spacer
      }
    }
  }

  &-vols {
    &-shift {
      margin-top: $spacer * 2;
      &-time {
        font-weight: $cerebri-bold;
      }
      &-slots {
        text-transform: uppercase;
        padding-top: calc($spacer / 2);
        &-count {
          color: $color-accent-orange;
          font-weight: $cerebri-bold;
          padding-right: calc($spacer / 2);
          & + .vevent-profile-vols-shift-slots-need {
            padding-left: calc($spacer / 2);
            &:before {
              content: '';
              display: block;
              position: absolute;
              top: 3px;
              left: 0;
              bottom: 3px;
              width: 1px;
              background-color: $color-accent-purple;
            }
          }
        }
        &-need {
          position: relative;
          color: $color-accent-purple;
        }
      }
    }

    &-users {
      margin-top: $spacer;
      &-user {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: calc($spacer / 2);
        &-img {
          width: 24px;
          height: 24px;
          background-size: cover;
          background-position: center center;
          display: block;
          border-radius: 100px;
          margin-right: calc($spacer / 2);
        }
      }
      &-loadmore {
        width: 100%;
        text-align: center;
        padding: 0;
        padding-top: $spacer;
        color: $color-text-faint;
        cursor: pointer;
        &:hover {
          color: $color-text-default;
        }
      }
    }
  }

  &.type-event {
    .vevent-profile-reg-heading {
      @extend .gradient-groups-text;
    }
  }

  @include media("<large") {
    &-reg {
      flex-direction: column;
      h1 {
        padding: $spacer;
        // padding-bottom: $spacer * 2;
      }
      &-shift {
        margin-top: $spacer;
        flex-direction: column;
        &-actions {
          .btn:first-child {
            margin-left: 0;
          }
          .btn {
            margin-top: calc($spacer / 2);
          }
        }
      }
    }
  }
  @include media("<medium") {
    &-reg {
      &-shift {
        &-actions {
          .btn {
            width: 100%;
            margin-left: 0;
          }
        }
      }
    }
  }

  &-social {
    margin: ($spacer * 8) 0;
    &-heading {
      text-transform: uppercase;
      font-weight: $cerebri-bold;
      font-size: 16px;
      text-align: center;
      margin: 0;
      margin-bottom: $spacer * 2;
    }
  }
}

.vol-modal-reg {
  h1 {
    margin: 0;
    font-weight: $cerebri-heavy;
    font-size: 24px;
  }
  h2 {
    margin: 0;
    margin-top: $spacer * 4;
    font-weight: $cerebri-heavy;
    font-size: 21px;
  }
  .modal-box {
    padding: $spacer * 4;
  }
  .btn.confirm {
    margin-top: $spacer * 4;
    display: block;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
  }
  &-shifts {
    margin-top: $spacer;
    font-weight: $cerebri-bold;
    svg.icon {
      width: 24px;
      margin-right: calc($spacer / 2);
    }
    &-shift {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: $spacer;
      .checkbox {
        margin-right: calc($spacer / 2);
      }
      &.disabled label {
        opacity: 0.5;
      }
    }
  }
}

.vol-modal-reg-success {
  text-align: center;
  .modal-box {
    padding: $spacer * 4;
  }
  h1 {
    @extend .gradient-orange-text;
    display: inline-block;
    margin: 0 auto;
    font-weight: $cerebri-heavy;
    font-size: 24px;
    margin: 0;
  }
  &.type-event {
    h1 {
      @extend .gradient-groups-text;
    }
  }
  h2 {
    font-weight: $cerebri-heavy;
    font-size: 21px;
    margin: 0;
  }
  &-party {
    width: 96px;
    margin: ($spacer * 2) auto;
    display: block;
  }
  &-shift {
    margin-top: $spacer;
    font-weight: $cerebri-bold;
  }
  .btn {
    margin: $spacer;
  }
}
