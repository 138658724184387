.ca-transactions {

  &-loading {
    width: 36px;
    color: $color-gray-cloud;
  }

  &-table {
    border-bottom: 1px solid $color-gray-cloud;

    th, td {
      border-bottom: none;
    }
    td {
      border-top: 1px solid $color-gray-cloud;
    }
    tbody tr {
      &.purchase-match,   &.type-Purchase-match   { td.type { color: $color-accent-purple; } }
      &.purchase-grant,   &.type-Grant-           { td.type { color: $color-accent-pink; } }
      &.purchase-gift,    &.type-GiftSend-        { td.type { color: $color-accent-blue; } }
      &.purchase-gift,    &.type-Purchase-gift    { td.type { color: $color-accent-blue; } }
      &.purchase-payroll, &.type-Purchase-payroll { td.type { color: $color-accent-green; } }
      &.purchase-dfd,     &.type-Purchase-dfddfd,  &.type-GiftSend-dfd { td.type { color: $color-accent-orange; } }
      &.related-charge,   &.type-related-charge {
        td.type {
          svg.icon {
            width: 16px;
            margin-left: 6px;
          }
        }
      }
      &.positive {
        td.amount {
          font-weight: $cerebri-bold;
        }
      }
    }
    tr.purchase, tr.cause-Purchase {
      &:hover {
        cursor: pointer;
        background-color: $color-highlight-blue;
        & + tr.related-charge {
          background-color: $color-highlight-blue;
        }
      }
    }
    tr.purchase + tr.related-charge td, tr.type-related-charge td {
      border-top: none;
      padding-top: 0;
    }
    td .icon-with-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      svg.icon {
        margin-right: $spacer;
        flex-shrink: 0;
      }
    }
    td.type {
      svg.icon {
        width: 22px;
      }
    }
    td.charged-to {
      svg.icon {
        width: 20px;
      }
    }
  }

}
