.eprofile {
  $max-width: 1440px;
  // border: 1px solid lime;
  padding-bottom: $spacer * 8;

  &-padder {
    margin: 0 auto;
    max-width: $max-width;
    padding: 0 ($spacer * 4);
  }

  &-top {
    // border: 1px solid red;
    display: flex;
    flex-direction: row;
    align-items: center;

    &-left, &-right {
      flex: 1;
      flex-shrink: 0;
    }

    &-left {
      padding-right: $spacer * 2;
      &-image {
        background-color: $color-gray-cloud;
        height: 530px;
        background-size: cover;
        background-position: center center;
      }
    }

    &-right {
      padding-top: $spacer * 2;
      padding-bottom: $spacer * 2;
      padding-left: $spacer * 2;
      &-padder {
        max-width: calc($max-width / 2) - ($spacer * 2);
        padding-right: $spacer * 4;
      }
      &-clogo {
        max-height: 50px;
        max-width: 200px;
        display: block;
        margin-bottom: $spacer;
      }
      &-title {
        font-weight: $cerebri-heavy;
        font-size: 42px;
        margin: 0;
        margin-bottom: $spacer;
      }
      .fake-lines.title {
        margin: ($spacer * 2) 0;
      }
      &-groups {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        &-group {
          display: block;
          max-height: 80px;
          max-width: 200px;
          margin: 0 $spacer $spacer 0;
        }
      }
      &-content-links {
        @include flexbox(row, center, flex-start);
        flex-wrap: wrap;
        margin-bottom: calc($spacer / -2);
        .btn {
          margin-right: calc($spacer / 2);
          margin-bottom: calc($spacer / 2);
        }
      }
    }
  }

  /*
   *  Responsiveness
   */

  &.not-responsive {
    min-width: 1200px;
  }

  &.responsive {
    @include media("<large") {
      .eprofile-padder {
        padding: 0 ($spacer * 3);
      }
      .eprofile-top {
        flex-direction: column;
        &-left, &-right {
          width: 100%;
          flex: none;
        }
        &-left {
          padding-right: 0;
          &-image {
            height: 380px;
          }
        }
        &-right {
          padding: $spacer * 3;
          padding-bottom: 0;
          &-padder {
            max-width: unset;
            padding-right: 0;
          }
          &-clogo {
            max-height: 35px;
            margin-left: auto;
            margin-right: auto;
          }
          &-title {
            text-align: center;
            font-size: 22px;
          }
        }
      }
    }
    @include media("<medium") {
      .eprofile-padder {
        padding: 0 ($spacer * 2);
      }
      .eprofile-top {
        &-left-image {
          height: 260px;
        }
        &-right {
          padding: $spacer * 2;
        }
      }
    }
  }

}
