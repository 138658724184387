.mod-don {
  $field-width: 360px;

  & > .modal-box {
    width: 721px;
    padding: ($spacer * 4) ($spacer * 8);
  }

  &-h1 {
    font-size: 32px;
    @extend .rainbow-text;
    display: inline-block;
    text-transform: uppercase;
    font-weight: $cerebri-heavy;
    margin: 0;
    &-con {
      @include flexbox(row, center, center);
    }
  }

  &-p {
    width: $field-width;
  }

  &-sec {
    @include flexbox(row, center, flex-start);
    margin-top: $spacer * 4;
    &-name {
      text-transform: uppercase;
      font-weight: $cerebri-bold;
      width: 132px;
      // border: 1px solid lime;
      text-align: right;
      margin-right: $spacer * 1.5;
      flex-shrink: 0;
    }
    &-content {
      flex: 1;
      border-left: 1px solid $color-gray-cloud;
      padding-left: $spacer * 1.5;
    }
  }

  &-actions {
    @include flexbox(column, center, center);
    margin-top: $spacer * 4;
    gap: $spacer;
    .btn.main {
      min-width: 300px;
    }
    &.multiple {
      // justify-content: space-between;
    }

    @keyframes empty-circe {
      from {stroke-dashoffset: 0;}
      to {stroke-dashoffset: 70;}
    }

    &-undo {
      @include flexbox(row, center, center);
      gap: $spacer * 0.5;
      cursor: pointer;
      height: 24px;
      border: 1px solid $color-accent-blue;
      background-color: white;
      color: $color-accent-blue;
      padding: 0 $spacer;
      border-radius: 100px;
      &:hover {
        background-color: $color-accent-blue;
        color: white;
      }
      svg {
        width: 14px;
        transform: rotate(-90deg);
        circle {
          stroke-width: 2;
          stroke: currentColor;
          fill: none;
          stroke-dasharray: 70;
          stroke-dashoffset: 70;
          animation-name: empty-circe;
          animation-timing-function: linear;
        }
      }
    }
  }

  &-amount {
    &-amount {
      @include flexbox(row, center, flex-start);
      &-val {
        font-size: 24px;
        margin-right: $spacer;
      }
      &-edit {
        transition: all 200ms;
        opacity: 0;
        svg.icon {
          width: 20px;
          &:hover {
            color: $color-accent-pink;
          }
        }
      }
      &:hover {
        .mod-don-amount-amount-edit {
          opacity: 1;
        }
      }
    }
    &-edit {
      @include flexbox(row, center, flex-start);
      &-input {
        margin-bottom: 0 !important;
        margin-right: calc($spacer / 2);
      }
    }
    &-equiv {
      margin: 0;
      margin-top: calc($spacer / 4);
      font-size: 12px;
    }
    &-emp-cover {
      border: 1px solid $color-accent-blue;
      background-color: $color-highlight-blue;
      padding: $spacer;
      margin-top: $spacer;
      border-radius: 2px;
      color: $color-accent-blue;
      @include flexbox(row, center, flex-start);
      gap: $spacer * 0.5;
      font-weight: $cerebri-bold;
      &-icon {
        width: 20px;
        flex-shrink: 0;
      }
    }
  }

  &-validation {
    margin: 0;
    margin-top: $spacer;
    color: $color-danger;
  }

  &-pmdd {
    width: $field-width;
    &-opt {
      display: block;
      width: 100%;
      padding-right: calc($spacer / 2);
      // padding: $spacer;
      &-line1 {
        @include flexbox(row, center, space-between);
        gap: $spacer;
        &-left {
          font-weight: $cerebri-bold;
          flex: 1;
          overflow: hidden;
          position: relative;
          :after {
            display: block;
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: $spacer;
            background: linear-gradient(to right, transparent, white);
          }
        }
        &-right {
          @include flexbox(row, center, flex-start);
          white-space: nowrap;
          svg.icon {
            width: 24px;
            flex-shrink: 0;
            margin-right: calc($spacer / 2);
          }
        }
      }
      &-line2 {
        color: $color-text-faint;
        font-size: 12px;
      }
    }
    &-heading {
      background-color: $color-gray-cloud;
      text-transform: uppercase;
      padding: calc($spacer / 4) $spacer;
      font-size: 12px;
    }
    .dd-menu {
      .mod-don-pmdd-opt {
        padding: $spacer;
        cursor: pointer;
        &:hover {
          background-color: $color-highlight-blue;
          &-line1-left:after {
            display: none;
          }
        }
        &:not(:last-child) {
          border-bottom: 1px solid $color-gray-cloud;
        }
      }
    }
  }

  &-freq {
    &-heading {
      @include flexbox(row, center, flex-start);
      font-weight: $cerebri-bold;
      svg.icon {
        width: 24px;
        margin-right: $spacer * 0.5;
      }
    }
    &-exp {
      margin: 0;
      margin-top: $spacer * 0.5;
    }
  }

  &-dest {
    @include flexbox(row, center, flex-start);
    &-img-con {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      margin-right: $spacer;
      overflow: hidden;
      &.default {
        border-radius: 0;
      }
    }
    &-img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &-text {
      &-line1 {
        font-weight: $cerebri-bold;
      }
      &-line2 {
        color: $color-text-faint;
      }
    }
  }

  &-camps {
    .standard-select {
      width: $field-width;
    }
  }

  &-match {
    &-pill {
      background-color: $color-gray-slate;
      color: white;
      border-radius: 100px;
      padding: ($spacer * 0.25) ($spacer * 0.75);
      text-transform: uppercase;
      display: inline-block;
      font-size: 12px;
      &.color-green  { background-color: $color-accent-green; }
      &.color-red    { background-color: $color-danger; }
      &.color-yellow { background-color: $color-accent-yellow; }
    }
    &-msg {
      margin-top: ($spacer * 0.5);
      margin-bottom: 0;
    }
    &-loading {
      width: 20px;
      color: $color-gray-fog;
    }
  }

  &-opts {
    &-cb-con {
      @include flexbox(row, center, flex-start);
      gap: $spacer * 0.5;
      .checkbox {
        flex-shrink: 0;
      }
    }
    &-note {
      margin-top: $spacer;
      .standard-input {
        width: $field-width;
        margin-bottom: 0;
      }
    }
    &-pre-note {
      white-space: pre-wrap;
      margin: 0;
    }
    &-fee {
      margin-top: $spacer;
      &-total {
        margin-top: $spacer * 0.5;
        padding-left: 21px;
      }
      &-info {
        display: block;
        margin: 0;
        padding: 0;
        width: 20px;
        margin-left: $spacer;
        color: $color-accent-blue;
        &:hover, &:focus {
          color: $color-accent-pink;
        }
        svg.icon {
          width: 20px;
          display: block;
          margin: 0;
        }
      }
    }
  }

  &-create-error {
    color: $color-danger;
    margin: 0 auto;
    margin-top: $spacer;
    text-align: center;
    max-width: 400px;
  }

  &-success {
    &-msg1, &-msg2 {
      margin: 0 auto;
      margin-top: $spacer * 2;
      text-align: center;
      max-width: 420px;
    }
    &-msg1 {
      font-size: 24px;
      font-weight: $cerebri-light;
    }
    &-msg2 {
      font-weight: $cerebri-bold;
    }
    &-actions {
      margin: 0 auto;
      margin-top: $spacer * 2;
      @include flexbox(row, center, center);
    }
  }

  @include media("<large") {
    & > .modal-box {
      width: 625px;
      padding: ($spacer * 4) ($spacer * 4);
    }
  }
  @include media("<medium") {
    & > .modal-box {
      width: 100%;
      padding: ($spacer * 4) ($spacer * 2);
    }
    &-sec {
      @include flexbox(column, flex-start, flex-start);
      border-left: 1px solid $color-gray-cloud;
      padding-left: $spacer;
      &-name {
        width: unset;
        text-align: left;
        // border-bottom: 1px solid $color-gray-cloud;
        padding-bottom: calc($spacer / 2);
        margin-bottom: calc($spacer / 2);
      }
      &-content {
        padding-left: 0;
        border-left: none;
      }
    }
  }
}
