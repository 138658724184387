.cadmin-bw-lists {
  &-searching {
    display: block;
    color: $color-gray-fog;
    width: 24px;
    margin: ($spacer * 2) auto;
  }

  tr.nonprofit {
    &.status-approved {
      .approval-status { color: $color-accent-green; }
      td:first-child { border-left: 2px solid $color-accent-green; }
    }
    &.status-pending {
      .approval-status { color: $color-text-faint; }
      td:first-child { border-left: 2px solid $color-text-faint; }
    }
    &.status-banned {
      .approval-status { color: $color-danger; }
      td:first-child { border-left: 2px solid $color-danger; }
    }

    .approval-status {
      font-weight: $cerebri-bold;
    }

    &:hover {
      td {
        cursor: pointer;
        background-color: $color-highlight-blue;
      }
    }
  }

  .btn.load-more {
    display: block;
    margin: ($spacer * 3) auto 0;
  }
}
