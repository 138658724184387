.ca-ntee {
  &-cell-caret {
    svg.icon {
      width: 16px;
      cursor: pointer;
    }
  }
  &-cell-code {
    font-size: 20px;
    svg.icon, .icon-spacer {
      width: 24px;
    }
  }
  &-icon-code {
    @include flexbox(row, center, flex-start);
    gap: $spacer * 0.5;
    &-spacer {
      display: inline-block;
      width: 24px;
    }
  }
  tr.expanded {
    .ca-ntee-cell-code,
    .ca-ntee-cell-name {
      font-weight: $cerebri-heavy;
    }
  }
}

.ca-ntee-rule-editor {
  &.approved .dd-button {color: $color-accent-green;}
  &.banned   .dd-button {color: $color-danger;}
}
