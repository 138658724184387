
// old
.cadmin-modal-np {

  .modal-box {
    width: 700px;
    // padding: $spacer * 2;
  }

  .np-card {
    margin: ($spacer * 4) auto;
  }

  &-loading-icon {
    display: block;
    width: 24px;
    margin: ($spacer * 4) auto;
    color: $color-gray-fog;
  }

  &-actions {
    margin: ($spacer * 4);
    display: flex;
    flex-direction: row;
  }

  &-action {
    flex: 1;

    &-title {
      text-transform: uppercase;
    }
  }

}

// new
.ca-modal-np {
  table.attrs {
    margin-top: $spacer;
  }
  &-cat-status {
    &.status-approved {color: $color-accent-green;}
    &.status-banned {color: $color-danger;}
  }
}
