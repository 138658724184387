.modal-np-select {

  .modal-box {
    max-width: 900px;
    width: 100%;
    text-align: left;
  }

  &-head {
    padding: ($spacer * 4) ($spacer * 4) 0;
    // border-bottom: 1px solid $color-gray-cloud;
    background-color: $color-gray-ice;
    &-h1 {
      font-weight: $cerebri-heavy;
      margin: 0;
      font-size: 30px;
    }
  }

  &-filters {
    @include flexbox(row, flex-start, flex-start);
    gap: $spacer;
    flex-wrap: wrap;
    background-color: $color-gray-ice;
    padding: ($spacer * 2) ($spacer * 4);
    border-bottom: 1px solid $color-gray-cloud;
  }
  &-filter {
    label {
      text-transform: uppercase;
      color: $color-text-medium-1-5;
      display: block;
      font-size: 12px;
      font-weight: $cerebri-bold;
    }
    .standard-select, .auto-complete-input, .standard-input, .dd {
      margin-bottom: 0;
      display: block;
      width: 180px;
      min-width: 180px;
    }
  }

  &-results {
    margin: ($spacer * 2) ($spacer * 4);
    // padding-bottom: $spacer * 2;
    &-h2 {
      font-weight: $cerebri-heavy;
      margin: 0 !important;
      font-size: 18px;
    }
    &-p {
      margin: 0;
      color: $color-text-medium-1-5;
      margin-bottom: $spacer * 2;
    }
    &-list {
      @include flexbox(row, flex-start, flex-start);
      gap: $spacer;
      flex-wrap: wrap;
      margin-top: $spacer * 2;
      max-height: 500px;
      overflow: auto;
      margin: $spacer * -1;
      padding: $spacer * 1;
      .np-card {
        width: 396px;
      }
    }
    &-pagination {
      margin-top: $spacer * 3;
    }
  }

}
