@import './layout';
@import './box';
@import './common';
@import './cattrs';
@import './builder';
@import './builder-dashboard';
@import './builder-vol-event';
@import './builder-campaign';
@import './madness';
@import './modal-credit-card';
@import './modal-launch';
@import './modal-employee-role';
@import './modal-manual-bank-verify';
@import './modal-nonprofit';
@import './modal-slack-channel';
@import './modal-vte';
@import './multi-emp-select';
@import './page-billing';
@import './page-brackets-dashboard';
@import './page-campaigns';
@import './page-cards';
@import './page-dashboard-view';
@import './page-dashboard';
@import './page-dfd';
@import './page-drive-donations';
@import './page-employees';
@import './page-events';
@import './page-groups';
@import './page-groups-settings';
@import './page-matches';
@import './page-vol-events';
@import './page-vol-event-builder';
@import './page-vol-analytics';
@import './page-vol-tracking';
@import './page-purchase';
@import './page-settings';
@import './page-sso';
@import './page-subscription';
@import './page-analytics';
@import './page-transactions';
@import './page-wallet';
@import './page-grants-new';
@import './page-gifts-new';
@import './page-gifts';
@import './page-ntee-codes';
@import './page-nonprofits';
@import './bw-lists';
@import './page-slack-settings';
@import './top-line-stats';

.page-cadmin {
  .ca-box {
    &:first-child {
      margin-top: 0;
    }
  }
}

.ca {
  &-back-crumb {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: uppercase;
    font-size: 17px;
    color: $color-text-faint;
    margin-top: $spacer * -2;
    margin-bottom: $spacer * 2;

    svg.icon {
      width: 12px;
      margin: 0;
      margin-right: calc($spacer / 2);
    }

    &:hover {
      text-decoration: none;
      color: $color-accent-pink;
    }
  }
}

table.ca-keyvals {
  tr {
    &:first-child {
      td, th {
        border-top: 1px solid $color-gray-cloud;
      }
    }
    td, th {
      text-align: left;
      // font-weight: $cerebri-regular;
      padding: ($spacer) ($spacer * 2);
      border-bottom: 1px solid $color-gray-cloud;

      p:first-child {
        // margin-top: 0;
      }
    }
    th {
      padding-right: $spacer * 4;
      vertical-align: top;
    }
  }
}

.ca-checklist {
  &-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: ($spacer * 2) 0;
    &-check {
      width: 24px;
      margin-right: $spacer * 2;
      color: $color-gray-slate;
      & + div {
        flex: 1;
      }
    }
    .ca-box {
      margin: 0;
    }
    &.complete {
      .ca-checklist-item-check g {
        stroke: white;
        fill: $color-accent-green;
      }
    }
    &.disabled {
      position: relative;
      &:before {
        position: absolute;
        display: block;
        content: '';
        background-color: white;
        opacity: 0.7;
        top: -12px;
        left: -12px;
        right: -12px;
        bottom: -12px;
        // width: 100%;
        // height: 100%;
        z-index: 200;
      }
    }
  }
}

.ca-main {
  &-head {
    @include flexbox(row, center, flex-start);

    @mixin colorify($color, $bg-image) {
      .ca-main-head-h1 {
        color: $color;
        @include gradient-text($bg-image);
      }
    }
    &.color-orange { @include colorify($color-accent-orange, $gradient-orange); }
    &.color-pink   { @include colorify($color-accent-pink,   $gradient-pink); }
    &.color-green  { @include colorify($color-accent-green,  $gradient-green); }
    &.color-green2 { @include colorify($color-accent-green2, $gradient-green2); }
    &.color-purple { @include colorify($color-accent-purple, $gradient-purple); }
    &.color-blue   { @include colorify($color-accent-blue,   $gradient-blue); }
    &.color-blue2  { @include colorify($color-accent-blue2,  $gradient-blue2); }
    &.color-black  { @include colorify($color-gray-midnight, $gradient-black); }
    &.color-gray   { @include colorify($color-gray-slate,    $gradient-slate); }
    &.color-groups { @include colorify($color-groups-a,      $gradient-groups); }

    &-h1 {
      @include flexbox(row, center, flex-start);
      font-weight: $cerebri-heavy;
      font-size: 30px;
      margin: 0;
      svg.icon {
        width: 30px;
        margin-right: $spacer;
      }
    }
    &-actions {
      flex: 1;
      @include flexbox(row, center, flex-end);
      & > * {
        margin-left: $spacer;
      }
    }
  }

  &-tabs {
    @include flexbox(row, center, flex-start);
    gap: $spacer * 4;
    // font-weight: $cerebri-bold;
    border-bottom: 1px solid $color-gray-cloud;
    margin: $spacer ($spacer * -4) ($spacer * 4);
    padding: 0 ($spacer * 4);
    &-tab {
      color: inherit;
      padding: $spacer 0;
      color: $color-text-medium-1-5;
      transition: all 200ms;
      text-decoration: none !important;
      border-bottom: 4px solid transparent;
      &:hover, &.active {
        color: $color-text-default;
        border-color: $color-gray-cloud;
      }
      &.active {
        font-weight: $cerebri-bold;
        border-color: $color-gray-midnight;
      }
      &.has-badge {
        position: relative;
        .new-badge {
          position: absolute;
          display: block;
          font-size: 10px;
          font-weight: $cerebri-bold;
          padding: 1px 4px;
          border-radius: 100px;
          text-transform: uppercase;
          color: white;
          background-color: $color-accent-pink;
          right: -16px;
          top: -2px;
          opacity: 0.9;
        }
      }
    }
  }

  &-filters {
    @include flexbox(row, flex-start, flex-start);
    flex-wrap: wrap;
    margin: ($spacer * 1.5) 0 ($spacer * 3);
    &-filter {
      &:not(:last-child) {
        margin-right: $spacer;
      }
      &-label {
        display: block;
        font-size: 12px;
        font-weight: $cerebri-bold;
        text-transform: uppercase;
        color: $color-text-faint;
      }
      .standard-input, .auto-complete-input {
        width: 200px;
        margin-bottom: 0;
      }
      &.filled {
        .ca-main-filters-filter-label {
          color: $color-text-default;
        }
      }
    }
  }
}
