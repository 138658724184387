.page-collection {

  &-top {
    text-align: center;
  }

  &-a-collection {
    @extend .rainbow-text;
    font-weight: $cerebri-light;
    font-size: 17px;
    display: inline-block;
    margin: 0;
    margin-top: $spacer * 4;
    text-transform: uppercase;
  }

  &-title {
    font-weight: $cerebri-heavy;
    font-size: 30px;
    margin: 0;
    margin-top: calc($spacer / 2);
  }

  &-desc {
    max-width: 684px;
    margin: 0 auto;
    margin-top: $spacer * 2.5;
    text-align: left;
  }

  &-nonprofits {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: ($spacer * 1.5) ($spacer * -1.5);

    .np-card {
      margin: $spacer * 1.5;
    }
  }

  .join-cta {
    margin-bottom: $spacer * 6;
  }

  @include media("<medium") {
    &-nonprofits {
      .np-card {
        margin-bottom: 0;
      }
    }
  }

}
