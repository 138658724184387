.donate {
  max-width: 933px;
  margin: 0 auto;

  &-title {
    text-align: center;
    margin-bottom: $spacer * 2;

    h4 {
      @extend .rainbow-text;
      display: inline-block;
      margin: 0;
      font-weight: $cerebri-heavy;
      font-size: 40px;
    }
  }

  &-box {
    box-shadow: 2px 1px 12px 3px rgba(0,0,0,0.09);
    padding: $spacer * 4;
    position: relative;

    &.disabled {
      // border: 1px solid red;
      // opacity: 0.5;
    }

    &-currency-picker {
      position: absolute !important;
      top: $spacer * 2;
      right: $spacer * 2;
    }

    &-ineligible {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 200;
      top: 0;
      left: 0;
      background-color: rgba(white, 0.85);
      font-size: 18px;
      padding: $spacer * 2;
      text-align: center;
      text-shadow: 0px 0px 6px white;
      @include flexbox(column, center, center);
      h3 {
        font-weight: $cerebri-heavy;
        margin: calc($spacer / 2);
      }
      p {
        margin: calc($spacer / 2);
        // font-weight: $cerebri-bold;
        // background-color: white;
        // padding: 3px 6px;
      }
    }

    &-selectors {
      display: flex;
      flex-direction: row;

      &-left {
        flex: 1;
        text-align: center;
        border-right: 1px solid $color-gray-cloud;
      }
      &-right {
        flex: 1;
        text-align: center;

        .donate-box-recurring-date {
          font-size: 12px;
          color: $color-text-faint;
          margin-top: calc($spacer / 2);
        }
      }

      h4 {
        width: 293px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: $spacer;

        svg.icon {
          width: 24px;
          margin-right: calc($spacer / 2);
        }
      }

      .btn {
        margin-top: ($spacer * 2) - 8;
      }
    }

    &-text {
      text-align: center;
      margin-top: $spacer * 2;

      &-anonymous {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: $spacer;
        font-size: 13px;
        .checkbox {
          flex-shrink: 0;
          margin-right: calc($spacer / 2);
        }
        label {
          text-align: left;
        }
      }
    }

    .amount-selector {
      margin: 0 auto;
    }
  }

  &-match {
    text-align: center;

    &-headline {
      font-size: 13px;
      margin: ($spacer * 1.5) $spacer ($spacer * 0.5);
    }

    &-logo {
      max-width: 140px;
      max-height: 32px;
      display: block;
      margin: 0 auto;
    }

    &-explanation {
      background-color: $color-gray-ice;
      padding: $spacer ($spacer * 1.5);
      max-width: 576px;
      display: block;
      margin: ($spacer * 2) auto 0;
      text-align: left;
      font-size: 13px;

      span {
        display: block;
        &:nth-child(2) {
          margin-top: $spacer;
          padding-top: $spacer;
          border-top: 1px solid $color-gray-cloud;
          color: $color-text-medium-1-5;
        }
        &.success {
          color: $color-accent-blue;
          text-align: center;
        }
      }
      .btn {
        display: block;
        margin: $spacer auto 0;
      }
    }
  }

  @include media("<large") {
    &-title {
      h4 {
        font-size: 36px;
      }
    }
    &-box {
      padding: ($spacer * 4) ($spacer * 2);
    }
  }

  @include media("<medium") {
    &-title {
      h4 {
        font-size: 28px;
      }
    }
    &-box {
      &-selectors {
        flex-direction: column;
        &-left {
          border: none;
          margin-bottom: $spacer * 2;
        }
      }
    }
  }

}
