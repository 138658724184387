
$cerebri: 'Cerebri Sans', Arial, sans-serif;
$dm-mono: 'DM Mono', $cerebri;
// per convo with Rachel, we're gunna try to stick to 4 of these - light, regular, bold, & heavy
$cerebri-light: 300;
$cerebri-regular: 400;
// $cerebri-book: 450;
// $cerebri-medium: 500;
// $cerebri-semibold: 600;
$cerebri-bold: 700;
// $cerebri-extrabold: 800;
$cerebri-heavy: 900;

$color-cat-health: #F97C23;
$color-cat-animals: #D11783;
$color-cat-culture: #A13FB6;
$color-cat-community-dev: #2F3A9B;
$color-cat-education: #30B0DA;
$color-cat-environment: #8FC338;
$color-cat-public-policy: #E8A31B;
$color-cat-human-services: #F06DBA;
$color-cat-civil-rights: #6F41BF;
$color-cat-international: #337EC8;
$color-cat-religion: #46B2B7;
$color-cat-other: #7F7F7F;
$color-cat-public-services: #41AE55;

$color-accent-blue: #2C7ECE;
$color-accent-blue2: #1C5ED9;
$color-accent-pink: #E30084;
$color-accent-green: #10CF79;
$color-accent-green2: #6BC733;
$color-accent-orange: #FE8232;
$color-accent-yellow: #FFB106;
$color-accent-purple: #7837C6;
$color-gray-midnight: #363636;
$color-gray-slate: #979B9E;
$color-gray-fog-2: #B7B7B7;
$color-gray-fog: #CBCBCB;
$color-gray-cloud: #E4E7EA;
$color-gray-ice: #F6F6F6;
$color-sunrise-a: #F28A00;
$color-sunrise-b: #E50082;
$color-jungle-a: #007ECE;
$color-jungle-b: #00D272;
$color-rainbow-a: $color-sunrise-a;
$color-rainbow-b: $color-sunrise-b;
$color-rainbow-c: $color-jungle-a;
$color-rainbow-d: $color-jungle-b;
$color-red: #D01749;
$color-danger: $color-red;
// $color-danger: #F24A71;
$color-highlight-blue: #F3F7FC;
$color-slack-purple: #641C66;
$color-slack-blue: #63799E;
// $color-groups-a: #532EB1;
// $color-groups-b: #7891FF;
$color-groups-a: #5330B1;
$color-groups-b: #7485F8;
$color-pricing-a: #FF00C6;
$color-pricing-b: #7B3BC8;

$gradient-jungle: linear-gradient(to right, $color-jungle-a, $color-jungle-b);
$gradient-sunrise: linear-gradient(to right, $color-sunrise-a, $color-sunrise-b);
$gradient-sunset: linear-gradient(to right, $color-sunrise-b, $color-jungle-a);
$gradient-sunrise-reverse: linear-gradient(to left, $color-sunrise-a, $color-sunrise-b);
$gradient-rainbow: linear-gradient(to right, $color-rainbow-a, $color-rainbow-b, $color-rainbow-c, $color-rainbow-d);
$gradient-purple: linear-gradient(to bottom right, #7837C6, #C84FEF);
$gradient-orange: linear-gradient(to bottom right, #FF4E4D, #FFAA07);
$gradient-pink: linear-gradient(to bottom right, #E6008A, #FF54F8);
$gradient-blue: linear-gradient(to bottom right, #0084D0, #00E4E7);
$gradient-blue2: linear-gradient(to bottom right, #1C5ED9, #83B3E0);
$gradient-green: linear-gradient(to bottom right, #00B8B7, #00EA8E);
$gradient-green2: linear-gradient(to bottom right, #6BC733, #9CD011);
$gradient-slack: linear-gradient(to bottom right, $color-slack-blue, $color-slack-purple);
$gradient-groups: linear-gradient(to bottom right, $color-groups-a, $color-groups-b);
$gradient-pricing: linear-gradient(to right, $color-pricing-a, $color-pricing-b);
$gradient-pink-purple: linear-gradient(to right, $color-accent-pink, $color-accent-purple);
$gradient-slate: linear-gradient(to bottom right, $color-gray-slate, hsl(0, 0%, 80%));
$gradient-black: linear-gradient(to bottom right, $color-gray-midnight, $color-gray-slate);

$color-text-default: $color-gray-midnight;
$color-text-medium-1: #7C86A2;
$color-text-medium-1-2: #7E8285;
$color-text-medium-1-5: $color-gray-slate;
$color-text-medium-2: #B8BFC6;
$color-text-faint: #ACB0B3;
$color-text-fainter: #E1E4E7;

$spacer: 12px;
$shadow: 2px 1px 10px 1px rgba(0,0,0,0.09);

$breakpoints: (
  medium: 750px,
  large: 960px,
  xl: 1200px,
);

@mixin cat-bg($color) {
  background-color: $color;
  background-image: linear-gradient(45deg, scale-color($color, $lightness: -10%, $saturation: 0%), scale-color($color, $lightness: 25%, $saturation: 0%));
}
@mixin cat-bg-light($color) {
  background-color: $color;
  background-image: linear-gradient(70deg, scale-color($color, $lightness: 95%, $saturation: 0%), scale-color($color, $lightness: 70%, $saturation: 0%));
}
