.app-header {
  height: 100px;
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.09);
  position: relative;

  .widther {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
  }

  &.full-width {
    .widther {
      max-width: initial;
      padding: 0 ($spacer * 2);
    }
    .app-header-session-menu {
      right: $spacer * 2;
    }
  }

  .millie-logo {
    min-width: 110px;
    margin-right: $spacer * 3;
    display: block;
  }

  &-mm {
    display: none;
  }

  &-spacer {
    flex: 1;
  }

  &-search-field {
    width: 200px;
    margin-right: 36px !important;

    .search-field-menu {
      left: -80px;
      min-width: 360px;
    }
  }

  &-collapsable {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-collapsable-toggle {
    flex-direction: row;
    height: 100%;
    align-items: center;
    padding: 0 ($spacer * 2);
    margin-right: $spacer * -2;
    display: none;
    background-color: transparent;
    border: none;
    outline: none;

    svg.icon {
      width: 24px;
      height: 24px;
      margin-right: calc($spacer / 2);
    }
    span {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: $cerebri-bold;
    }
    &-img {
      display: block;
      width: 28px;
      height: 28px;
      background-size: cover;
      border-radius: 100px;
      margin-left: $spacer * 1;
    }
  }

  &-menu {
    display: flex;
    flex-direction: row;
    height: 100%;

    a {
      position: relative;
      font-size: 15px;
      height: 100%;
      font-weight: $cerebri-regular;
      color: $color-text-default;
      text-decoration: none;
      margin-right: $spacer * 3;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-transform: uppercase;
      opacity: 0.6;

      svg.icon {
        display: block;
        width: 22px;
        height: 22px;
        margin-right: calc($spacer / 2);
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: 23px;
        width: 100%;
        height: 2px;
        background-color: transparent;
        transition: all 100ms;
        transform: scale(0.1);
      }

      &:hover, &.active, &:focus {
        text-decoration: none;
        opacity: 1;

        &:after {
          transform: scale(1);
          background-color: $color-text-default;
        }
      }
    }
  }

  &-company {
    border-left: 1px solid $color-gray-cloud;

    &-link {
      padding: 0 ($spacer * 2);
      height: 50px;
      // display: block;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    &-img {
      max-height: 50px;
      max-width: 120px;
    }
  }

  &-session {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    border-left: 1px solid $color-gray-cloud;
    padding-left: $spacer * 2;
    color: $color-text-default;
    transition: all 100ms;

    img {
      display: block;
      width: 39px;
      height: 39px;
      border-radius: 100%;
      object-fit: cover;
    }

    &-text {
      margin-left: $spacer * 0.75;
      flex: 1;
      overflow: hidden;

      &-name {
        font-size: 17px;
        font-weight: $cerebri-regular;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: inherit;
        // text-transform: uppercase;
      }
      &-amount {
        font-size: 15px;
        color: $color-accent-green;
        font-weight: $cerebri-bold;

        span {
          padding-left: calc($spacer / 2);
          margin-left: calc($spacer / 2);
          color: #6F41BF;
          border-left: 1px solid $color-gray-cloud;
        }
      }
      &-ein {
        font-size: 15px;
        color: $color-text-faint;
      }
    }

    &-caret {
      width: 12px;
      margin-left: $spacer;
      flex-shrink: 0;
    }

    &.signed-out {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    &-buttons {
      display: flex;
      flex-direction: row;
      .btn:first-child {
        margin-right: calc($spacer / 2);
      }
    }

    &.signed-in {
      width: 228px;
      cursor: pointer;

      &:hover {
        color: $color-accent-pink;
      }
    }

    .btn {
      height: 35px;
      padding-left: $spacer * 2;
      padding-right: $spacer * 2;
      clear: both;
    }

    &-subtext {
      display: block;
      margin: 0;
      margin-top: calc($spacer / 2);
      font-size: 12px;
      width: 100%;
      text-align: center;

      a {
        // font-weight: $cerebri-bold;
        svg.icon {
          width: 10px;
          margin-bottom: -1px;
        }
      }
    }
  }

  &-session-menu {
    display: none;
    opacity: 0;
    transition: all 200ms;
    position: absolute;
    top: 100%;
    right: $spacer * 3;
    width: 240px;
    margin-top: calc($spacer / 2);
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.09);
    background-color: white;
    z-index: -1;
  }

  &.session-menu-open {
    .app-header-session-menu {
      display: block;
      opacity: 1;
      z-index: 500;
    }
  }

  &-sm {
    &-user {
      padding: ($spacer * 1.5) $spacer;
      // margin: 0 $spacer;
      text-align: center;
      border-bottom: 1px solid $color-gray-ice;
      // overflow: hidden;
      &-image {
        width: 60px;
        height: 60px;
        display: block;
        margin: 0 auto;
        margin-bottom: calc($spacer / 2);
        object-fit: cover;
        border-radius: 100%;
      }
      &-name, &-email {
        white-space: nowrap;
        overflow: hidden;
      }
      &-name {
        font-weight: $cerebri-bold;
      }
      &-email {
        color: $color-text-faint;
        margin-bottom: $spacer;
      }
      a {
        font-weight: $cerebri-bold;
        text-transform: uppercase;
      }
      &-currency-picker {
        margin: 0 auto;
        margin-top: $spacer;
      }
    }

    &-npas {
      border-bottom: 1px solid $color-gray-ice;
      max-height: 220px;
      overflow: auto;
    }
    &-npa {
      padding: ($spacer * 1.5) 0;
      margin: 0 $spacer;
      text-align: center;
      border-bottom: 1px solid $color-gray-ice;
      overflow: hidden;
      &:last-child {
        margin-bottom: -1px;
      }
      &-image {
        width: 60px;
        height: 60px;
        display: block;
        margin: 0 auto;
        margin-bottom: calc($spacer / 2);
        object-fit: cover;
        border-radius: 100%;
      }
      &-name, &-ein {
        white-space: nowrap;
        overflow: hidden;
      }
      &-name {
        font-weight: $cerebri-bold;
      }
      &-ein {
        color: $color-text-faint;
        margin-bottom: $spacer;
      }
      a {
        font-weight: $cerebri-bold;
        text-transform: uppercase;
      }
    }

    &-links {
      &-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        color: inherit;
        padding: ($spacer);
        text-transform: uppercase;
        font-weight: $cerebri-bold;

        &:hover {
          text-decoration: none;
          color: $color-accent-pink;
        }

        svg.icon {
          width: 20px;
          margin-right: $spacer;
        }
      }
    }
  }

  /*
   *  Large styles
   */

  @include media("<xl") {
    height: 92px;

    .millie-logo {
      width: 100px;
      min-width: 100px;
    }

    &-search-field {
      margin-right: ($spacer * 2) !important;
    }

    &-menu {
      a {
        font-size: 14px;
        margin-right: $spacer * 2;
      }
    }

    &-company {
      &-link {
        height: 45px;
      }
      &-img {
        max-height: 45px;
      }
    }

    &-session, &-nonprofit {
      height: 45px;
      padding-left: $spacer * 2;

      img {
        width: 30px;
        height: 30px;
      }

      &-text {
        &-name {
          font-size: 14px;
          max-width: 120px;
        }
        &-amount, &-ein {
          font-size: 14px;
        }
      }
    }

    &-session-menu {
      right: $spacer * 2;
    }
  }

  @media (max-width: 1030px) {
    &-menu {
      a {
        svg.icon {
          display: none;
        }
      }
    }
  }

  /*
   *  Medium styles
   */

  @include media("<large") {
    .millie-logo {
      width: 80px;
      min-width: 80px;
    }

    &-search-field {
      display: none;
    }

    &-menu {
      a {
        svg.icon {
          display: none;
        }

        &:after {
          margin-top: 17px;
        }
      }
    }
    &-nonprofit {
      display: none;
    }
    &-company {
      display: none;
    }
  }

  @media (max-width: 830px) {
    &-menu {
      a {
        margin-right: $spacer;
      }
    }
  }

  /*
   *  Small styles
   */

  @include media("<medium") {
    $header-height: 60px;
    height: $header-height;

    &.mobile-menu-open {
      .app-header-collapsable-toggle {
        // background-color: $color-gray-cloud;
        svg.icon {
          width: 15px;
        }
      }
      .app-header-mm {
        left: 0;
      }
    }

    .millie-logo {
      width: 80px;
      min-width: 80px;
    }

    &-search {
      display: none;
    }

    &-collapsable {
      display: none;
    }

    &-collapsable-toggle {
      display: flex;
    }

    &-session-menu {
      display: none !important;
    }

    &-mm {
      display: block;
      background-color: $color-gray-ice;
      position: absolute;
      top: 100%;
      left: 110%;
      width: 100%;
      min-height: calc(100vh - 60px);
      box-shadow: inset 0 3px 5px 0px rgba(0,0,0, 0.09), 0 3px 5px 0px rgba(0,0,0, 0.09);
      z-index: 10000;
      padding: 0 ($spacer * 4);
      padding-bottom: $spacer * 2;
      transition: left 200ms;

      &-company {
        display: block;
        border-bottom: 1px solid $color-gray-cloud;
        padding: ($spacer * 2) 0;
        img {
          max-width: 200px;
          max-height: 40px;
        }
      }

      &-menu {
        margin: $spacer 0;  
        a {
          color: $color-text-default;
          font-size: 14px;
          display: flex;
          flex-direction: row;
          align-items: center;
          text-decoration: none !important;
          padding: $spacer 0;
          &.active {
            font-weight: $cerebri-bold;
            // color: $color-accent-pink;
          }
        }
        svg.icon {
          width: 24px;
          margin-right: $spacer;
        }
      }

      &-actions {
        margin-top: $spacer * 2;
        .btn {
          display: block;
          max-width: 250px;
          margin: 0 auto;
          margin-bottom: $spacer;
        }
        &-login {
          font-size: 12px;
          text-align: center;
          margin-top: $spacer * 2;
          a {
            font-weight: $cerebri-bold;
          }
        }
      }

      &-session {
        border-top: 1px solid $color-gray-cloud;
        padding-top: $spacer * 2;
        &-user {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: $color-text-default;
          margin-bottom: $spacer;
          &-img {
            width: 56px;
            height: 56px;
            background-size: cover;
            border-radius: 100px;
            margin-right: $spacer;
          }
          &-txt {
            font-size: 14px;
            &-name {
              font-weight: $cerebri-bold;
            }
            &-email {
              color: $color-text-medium-1-5;
              padding: calc($spacer / 4) 0;
            }
            &-balance {
              font-weight: $cerebri-heavy;
              span:first-child {
                color: $color-accent-green;
                padding-right: calc($spacer / 2);
              }
              span:nth-child(2) {
                color: $color-accent-purple;
                padding-left: calc($spacer / 2);
                border-left: 1px solid $color-gray-cloud;
              }
            }
          }
        }
        &-links {
          a {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: $spacer 0;
            color: $color-text-default;
            svg.icon {
              width: 24px;
              margin-right: $spacer;
            }
          }
        }
      }
    }
  }

}
