.account {

  &-box {
    margin-top: $spacer * 2;
    box-shadow: 2px 1px 12px 3px rgba(0,0,0,0.09);
    padding: $spacer * 4;

    &-title {
      font-size: 17px;
      text-transform: uppercase;
      color: $color-accent-blue;
      margin: 0;
      font-weight: $cerebri-regular;
      margin-bottom: $spacer * 2;
    }
  }

  &-basic-form {
    max-width: 300px;
    margin: 0 auto;

    .btn {
      display: block;
      margin: 0 auto;
    }
  }

  &-email-change-notice {
    margin-top: $spacer * -1;
  }
  &-email-change-sent {
    color: $color-accent-green;
  }
  &-email-change-success {
    color: $color-accent-green;
  }

}
