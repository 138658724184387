.cadmin-builder {

  &-section {
    margin: 0 ($spacer * 3);

    &:not(:last-child) {
      border-bottom: 1px solid $color-gray-cloud;
    }

    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: ($spacer * 2) 0;
      &-title {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: $cerebri-bold;
        position: relative;
      }
    }

    &-content {
      padding-bottom: ($spacer * 3);

      .auto-complete-input:last-child,
      .standard-input:last-child {
        margin-bottom: 0;
      }
      .standard-select {
        width: 100%;
      }
    }

    &-expmsg {
      font-style: italic;
      color: $color-text-medium-1-5;
      margin: 0 0 ($spacer * 2) 0;
    }

    &-vmsg {
      color: $color-danger;
      margin: 0 0 ($spacer * 2) 0;
    }

    &.required {
      .cadmin-builder-section-header-title:after {
        display: block;
        position: absolute;
        top: 50%;
        right: -13px;
        margin-top: -3.5px;
        content: '';
        width: 7px;
        height: 7px;
        border-radius: 100%;
        background-color: $color-accent-pink;
      }
    }
  }

  &-sgroup {
    margin-top: $spacer * 6;

    &-header {
      @include flexbox(row, center, flex-start);
      width: 100%;
      overflow: hidden;
      padding: 0 ($spacer * 3);
      color: $color-gray-slate;

      padding: $spacer ($spacer * 1.5);
      border-top: 1px solid $color-gray-cloud;
      border-bottom: 1px solid $color-gray-cloud;
      background-color: white;
      position: relative;

      &-hr {
        flex: 1;
        // border-top: 1px solid $color-gray-slate;
      }
      svg.icon {
        width: 36px;
        margin-right: calc($spacer / 2);
      }
      h2 {
        margin: 0;
        font-size: 21px;
        font-weight: $cerebri-heavy;
        margin-right: $spacer;
      }
      .checkbox {
        margin-left: $spacer;
      }
    }
    &:not(.is-on-false) .cadmin-builder-sgroup-header {
      h2, svg.icon { color: $color-text-default; }
      &-hr { border-color: $color-gray-midnight; }
    }
    &.purple:not(.is-on-false) .cadmin-builder-sgroup-header {
      h2, svg.icon { color: $color-accent-purple; }
      &-hr { border-color: $color-accent-purple; }
    }
    &.orange:not(.is-on-false) .cadmin-builder-sgroup-header {
      h2, svg.icon { color: $color-accent-orange; }
      &-hr { border-color: $color-accent-orange; }
    }
    &.green:not(.is-on-false) .cadmin-builder-sgroup-header {
      h2, svg.icon { color: $color-accent-green; }
      &-hr { border-color: $color-accent-green; }
    }
  }

  &-iterable {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin-bottom: $spacer;

    &-actions {
      padding: 0 ($spacer) 0 0;
      border-right: 1px solid $color-gray-cloud;
      margin-right: $spacer;
      @include flexbox(column, center, center);
      button {
        cursor: pointer;
        color: $color-gray-slate;
        opacity: 0;
        width: 20px;
        padding: 0;
        svg.icon {
          width: 20px;
          display: block;
          transition: all 100ms;
        }
        &:hover {
          color: $color-gray-midnight;
        }
      }
    }

    &-content {
      position: relative;
      flex: 1;
      .auto-complete-input, .standard-input {
        margin-bottom: 0;
      }
      &-badge {
        position: absolute;
        width: 22px;
        background-color: white;
        color: $color-accent-blue;
        border: 1px solid $color-gray-cloud;
        z-index: 1;
        top: -10px;
        right: -10px;
        border-radius: 100px;
        svg.icon {
          display: block;
          width: 16px;
          margin: 2px;
          g {
            fill: $color-accent-blue;
          }
        }
      }
    }

    &-add {
      margin-left: 33px;
    }

    &:hover {
      .cadmin-builder-iterable-actions button {
        opacity: 1;
      }
    }
  }

  &-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    .checkbox {
      margin-right: $spacer;
    }
  }


  &-checkbox2 {
    @include flexbox(row, center, flex-start);
    .checkbox {
      margin-right: calc($spacer / 2);
    }
    label {
      text-transform: none !important;
      color: $color-text-default !important;
      font-weight: bold;
    }
    .tip {
      margin-left: calc($spacer / 2);
    }
  }

  &-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    justify-content: space-between;
    &-badge {
      position: absolute;
      top: -10px;
      left: 0;
      color: $color-accent-blue;
      font-size: 12px;
      text-transform: uppercase;
    }
    label {
      text-transform: none !important;
    }
  }

  label {
    text-transform: uppercase;
    font-size: 16px;
    color: $color-text-faint;
  }

  .timezone-input {
    display: block;
  }

  .dd-button {
    background-color: white;
  }

  &-bottom {
    &-validations {
      background-color: $color-danger;
      color: white;
      padding: ($spacer * 2);
      p {
        margin: 0 0 $spacer 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &-actions {
      padding: ($spacer * 2);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }
    &-checklist {
      color: white;
      font-size: 14px;
      flex: 1;
      &-heading {
        text-transform: uppercase;
        font-weight: $cerebri-bold;
        position: relative;
        display: inline-block;
        &-todo {
          background-color: $color-red;
          color: white;
          text-align: center;
          width: 22px;
          height: 22px;
          line-height: 22px;
          border-radius: 100px;
          position: absolute;
          top: -7px;
          right: -18px;
        }
      }
      &-items {
        overflow: hidden;
        transition: all 200ms;
      }
      &-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: calc($spacer / 2);
        &-check {
          width: 14px;
          margin-right: calc($spacer / 2);
        }
        &-label {
          color: inherit;
          line-height: 18px;
          &:not(span):hover {
            text-decoration: none;
            // color: $color-accent-pink;
            font-weight: $cerebri-bold;
          }
        }
        &.complete {
          .cadmin-builder-bottom-checklist-item-check g {
            fill: $color-accent-green;
            circle { stroke: $color-accent-green; }
          }
        }
      }
    }
    &:not(:hover) {
      .cadmin-builder-bottom-checklist-items {
        height: 0px !important;
      }
    }
  }

  .builder-link {
    .standard-input + .standard-input {
      margin-top: calc($spacer / 2);
    }
  }

}

.builder-highlight, .builder-stat, .builder-resource {
  .standard-input:not(:last-child) {
    margin-bottom: calc($spacer / 2);
  }
}
