.cadmin-veb {

  &-shift-iterable {
    margin-bottom: $spacer * 2;
  }

  &-shift {
    .date-picker {
      width: 100%;
    }
    &-times {
      display: flex;
      flex-direction: row;
      margin: calc($spacer / 2) 0;
      &-time {
        flex: 1;
        .time-picker {
          width: 100%;
        }
        &:first-child {
          padding-right: calc($spacer / 4);
        }
        &:last-child {
          padding-left: calc($spacer / 4);
        }
      }
    }
    &-cap {
      label {
        text-transform: none;
      }
    }
    &-volmins {
      margin-top: calc($spacer / 2);
      border: 1px solid $color-gray-cloud;
      padding: $spacer;
      background-color: white;

      &-cb-row {
        @include flexbox(row, center, flex-start);
        label {
          margin-left: calc($spacer / 2);
          font-size: 14px;
          font-weight: $cerebri-bold;
          text-transform: none;
        }
      }

      &.volmins-on {
        .cadmin-veb-shift-volmins-cb-row label {
          color: inherit;
        }
      }

      .time-picker {
        margin-top: $spacer;
      }

      &-exp {
        color: $color-text-medium-1-5;
        margin: 0;
        margin-top: $spacer;
      }
    }
  }

  &-tags {
    &-tag {
      @include flexbox(row, center, flex-start);
      gap: $spacer;
      &:not(:last-child) {
        margin-bottom: $spacer * 2;
      }
      &-icon {
        width: 24px;
      }
      label {
        @include flexbox(row, center, flex-start);
        gap: $spacer * 0.5;
        text-transform: none;
        color: inherit;
      }
      &.on {
        label {
          font-weight: $cerebri-bold;
        }
      }
    }
  }

}
