.user-profile {

  .app-body .widther {
    max-width: 1065px;
    // border: 1px solid red;
    margin-bottom: $spacer * 8;
  }

  &-banner {
    margin-top: $spacer * 4;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: white url("/images/user-profile/triangles-banner.svg") center center / auto 74% no-repeat;
    position: relative;

    &-avatar {
      width: 295px;
      height: 295px;
      border-radius: 100%;
      overflow: hidden;
      position: relative;
      z-index: 2;
    }

    &.fade-triangles {
      background-blend-mode: luminosity;

      .user-profile-banner-avatar {
        border-radius: 0;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: white;
        opacity: 0.9;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }
  }

  &-name {
    text-align: center;

    &-name {
      font-size: 43px;
      font-weight: $cerebri-heavy;
      margin: 0;
      margin-top: $spacer;
    }
    &-location {
      color: $color-text-faint;
      font-size: 16px;
    }
  }

  &-top {
    margin-top: $spacer * 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &-settings {
      color: $color-text-default;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: $cerebri-bold;
      font-size: 13px;
      text-transform: uppercase;

      svg.icon {
        width: 17px;
        margin-right: calc($spacer / 2);

        &.caret {
          width: 12px;
        }
      }
    }

    &-private {
      color: $color-text-faint;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      svg.icon {
        width: 17px;
      }
      &-title {
        font-size: 14px;
        text-transform: uppercase;
        margin: 0 $spacer 0 calc($spacer / 2);
      }
      &-desc {
        font-size: 13px;
      }
    }

    &-privacy {
      font-size: 13px;
      svg.icon {
        color: $color-gray-fog;
        width: 17px;
      }
    }
  }

  &-private {
    &-con {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: ($spacer * 8) 0 ($spacer * 16);
      color: $color-text-faint;

      svg.icon {
        width: 17px;
        margin-right: calc($spacer / 2);
      }
      span {
        text-transform: uppercase;
      }
    }
  }

  &-boxes {
    display: flex;
    flex-direction: row;
    margin-top: $spacer * 2;

    &-left {
      flex: 1;
      flex-shrink: 0;
      width: 50%;
      padding-right: $spacer;
    }

    &-right {
      flex: 1;
      flex-shrink: 0;
      width: 50%;
      padding-left: $spacer;
    }

    .box {
      padding: $spacer * 2;
      box-shadow: 2px 1px 12px 3px rgba(0,0,0,0.09);

      &-title {
        font-weight: $cerebri-bold;
        font-size: 16px;
        margin: 0;
        text-transform: uppercase;
      }
    }
  }

  &-giving {
    &-stats {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    &-stat {
      font-weight: $cerebri-heavy;
      text-align: center;
      padding: 0 ($spacer * 2);
      margin-top: $spacer * 2;
      &-key {
        font-size: 13px;
      }
      &-val {
        font-size: 42px;
      }
      &:not(:first-child) {
        border-left: 1px solid $color-gray-cloud;
      }
    }
  }

  &-impacted {
    margin-top: $spacer * 2;
    &-images {
      display: flex;
      flex-direction: row;
      margin-top: $spacer * 2;
      overflow: hidden;
    }
    &-image {
      width: 75px;
      height: 75px;
      flex-shrink: 0;
      background-size: cover;
      background-position: center;
      border-radius: 100%;
      background-color: white;

      &:not(:first-child) {
        margin-left: -9px;
      }
    }
  }

  &-causes {
    height: 100%;

    &-tags {
      margin-top: $spacer * 2;
    }
    &-tag {
      background-color: $color-gray-cloud;
      padding: 3px 9px;
      border-radius: 100px;
      display: inline-block;
      font-size: 13px;
      margin: 0 9px 9px 0;
    }

    &-cats {
      margin-top: $spacer * 2;
      height: 44px;
      max-width: 424px;
      overflow: hidden;
      border-radius: 100px;
      background-color: $color-gray-cloud;
    }
    &-cat {
      height: 100%;
      float: left;
      color: white;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: $spacer * 0.75;

      &:first-child {
        padding-left: $spacer;
      }

      svg.icon {
        flex-shrink: 0;
        width: 21px;
      }
    }

    &.placeholder {
      .user-profile-causes-tags, .user-profile-causes-cats {
        opacity: 0.12;
      }
    }
  }

  &-nonprofits {
    margin-top: $spacer * 4;

    &-title {
      font-weight: $cerebri-bold;
      font-size: 16px;
      margin: 0;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: $spacer;
    }

    &-cards {
      overflow: hidden;
      margin: $spacer * -1;
    }
    &-card-con {
      width: 33.333%;
      float: left;
      padding: $spacer;
    }
  }

  @include media("<xl") {
    &-banner {
      &-avatar {
        width: 240px;
        height: 240px;
      }
    }
  }

  @include media("<large") {
    &-banner {
      &-avatar {
        width: 200px;
        height: 200px;
      }
    }
    &-boxes {
      flex-direction: column;
      &-left, &-right {
        width: 100%;
        padding: 0;
      }
    }
    &-causes {
      margin-top: $spacer * 2;
    }
    &-nonprofits {
      &-card-con {
        width: 50%;
      }
    }
  }

  @include media("<medium") {
    &-banner {
      margin-top: $spacer * 2;
      &-avatar {
        width: 160px;
        height: 160px;
      }
    }
    &-name {
      &-name {
        font-size: 32px;
      }
    }
    &-top {
      flex-direction: column;
      align-items: flex-start;
      &-private {
        margin: $spacer 0;
      }
    }
    &-nonprofits {
      &-card-con {
        width: 100%;
      }
    }
  }

}

.user-profile-avatar {
  height: 100%;

  &-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-uploader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    overflow: hidden;
    background-color: rgba(0,0,0,0.5);
    transition: all 100ms;
    opacity: 0;
    cursor: pointer;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 16px;

    svg.icon {
      width: 17px;
      margin-right: calc($spacer / 2);
    }

    &-input {
      display: none !important;
    }

    &.uploading, &:hover {
      opacity: 1;
    }
  }
}
