
.explore-cols {
  $triangle-height: $spacer * 3;
  background-color: $color-gray-ice;
  padding: ($spacer) 0;
  margin: ($spacer * 5) 0;
  text-align: center;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 0; height: 0;
    position: absolute;
    top: $triangle-height * -1;
    left: 0;
    border: 0px solid transparent;
    border-bottom: $triangle-height solid $color-gray-ice;
    border-left-width: 100vw;
  }

  &:after {
    content: '';
    display: block;
    width: 0; height: 0;
    position: absolute;
    bottom: $triangle-height * -1;
    left: 0;
    border: 0px solid transparent;
    border-top: $triangle-height solid $color-gray-ice;
    border-right-width: 100vw;
  }

  &-heading {
    @extend .rainbow-text;
    font-size: 27px;
    font-weight: $cerebri-light;
    display: inline-block;
    margin: 0;
    margin-bottom: $spacer;
  }

  &-carousel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &-arrow {
      color: $color-gray-fog;
      width: 20px;
      cursor: pointer;

      &:hover {
        color: $color-gray-midnight;
      }
    }

    &-window {
      margin: $spacer ($spacer * 0);
      overflow: hidden;
      width: (310px + ($spacer * 6)) * 3;
    }

    &-slider {
      display: flex;
      flex-direction: row;
      position: relative;
      left: 0;
      transition: left 300ms ease-out;
    }
  }

  &-col-wrapper {
    flex-shrink: 0;
    width: 310px + ($spacer * 6);
    padding: 0 ($spacer * 3);
    padding-bottom: $spacer;
  }

  &-col {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: $spacer * 2;

    &-title {
      font-weight: $cerebri-heavy;
      font-size: 15px;
      margin: 0;
      line-height: 19px;
    }

    &-cards {
      text-align: left;
      .np-card {
        margin-top: $spacer;
      }
    }
  }

  @include media("<xl") {
    &-carousel-window {
      width: (310px + ($spacer * 6)) * 2;
    }
  }
  @include media("<large") {
    &-carousel-window {
      width: (310px + ($spacer * 4)) * 2;
    }
    &-col-wrapper {
      width: 310px + ($spacer * 4);
      padding-left: $spacer * 2;
      padding-right: $spacer * 2;
    }
  }
  @include media("<medium") {
    &-carousel-window {
      width: (310px + ($spacer * 4)) * 1;
    }
  }

}
