.ca-modal-launch {
  &-invite-cb {
    @include flexbox(row, center, flex-start);
    .checkbox {
      margin-right: calc($spacer / 2);
    }
  }
  &-admin-only {
    color: $color-danger;
  }
}
