.nteepick {
  display: block;
  // background-color: white;

  & > .dd-button {
    background-color: white;
  }

  &-btn {
    @include flexbox(row, center, flex-start);
    width: 100%;
    overflow: hidden;

    &-placeholder {
      color: $color-text-faint;
    }
    &-icon {
      width: 24px;
      margin-right: calc($spacer / 1);
      flex-shrink: 0;
    }
    &-name {
      font-weight: $cerebri-bold;
      text-wrap: wrap;
      // font-size: 12px;
      line-height: 17px;
      max-height: 34px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  &-menu {
    overflow: hidden;

    &-columns {
      width: 200%;
      position: relative;
      transition: left 200ms;
      left: 0;
      @include flexbox(row, flex-start, flex-start);
      text-align: left;
    }

    &-heading {
      background-color: $color-gray-ice;
      border-bottom: 1px solid $color-gray-cloud;
      padding: calc($spacer / 2) $spacer;
      position: sticky;
      top: 0;
      text-transform: uppercase;
      font-size: 13px;

      &-back {
        padding: 0;
        @include flexbox(row, center, flex-start);
        margin-bottom: calc($spacer / 2);
        &:hover {
          color: $color-accent-pink;
          cursor: pointer;
        }
        svg.icon {
          width: 10px;
          margin-right: calc($spacer / 4);
        }
      }
    }

    &-majors, &-minors {
      max-height: 300px;
      overflow: auto;
      width: 50%;
      flex-shrink: 0;

      .nteepick-menu-code {
        @include flexbox(row, center, flex-start);
        width: 100%;
        text-align: left;
        padding: $spacer;
        cursor: pointer;
        &:hover {
          background-color: $color-highlight-blue;
        }
        svg.icon {
          width: 20px;
          flex-shrink: 0;
          margin-right: calc($spacer / 2);
        }
      }
    }

    &.minors {
      .nteepick-menu-columns {
        left: -100%;
      }
    }
  }

}
