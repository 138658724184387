.page-ntees {

  &-main {
    max-width: 800px;
    margin: 0 auto;
    padding-bottom: 100px;
  }

  &-h1 {
    font-size: 30px;
    font-weight: $cerebri-heavy;
    margin: 0;
    margin-top: $spacer * 4;
  }

  &-majors {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: $spacer * 4;
  }

  &-major {
    margin-top: $spacer * 4;
    &-title {
      @include flexbox(row, center, flex-start);
      font-size: 22px;
      font-weight: $cerebri-heavy;
      margin: 0;

      &.cat-health {color: $color-cat-health;}
      &.cat-animals {color: $color-cat-animals;}
      &.cat-culture {color: $color-cat-culture;}
      &.cat-community-dev {color: $color-cat-community-dev;}
      &.cat-education {color: $color-cat-education;}
      &.cat-environment {color: $color-cat-environment;}
      &.cat-public-policy {color: $color-cat-public-policy;}
      &.cat-human-services {color: $color-cat-human-services;}
      &.cat-civil-rights {color: $color-cat-civil-rights;}
      &.cat-international {color: $color-cat-international;}
      &.cat-religion {color: $color-cat-religion;}
      &.cat-other {color: $color-cat-other;}

      svg.icon {
        width: 32px;
        margin-right: calc($spacer / 1);
      }
    }
    &-desc {
      margin: $spacer 0;
      max-width: 600px;
      font-size: 16px;
    }
    &-btns {
      @include flexbox(row, flex-start, flex-start);
      .btn {
        margin-right: $spacer;
      }
    }

    &.collapsed {
      .page-ntees-minors {
        display: none;
      }
    }
  }

  &-minors {
    margin: $spacer;
    padding: 0;
    list-style: none;
    @include flexbox(row, flex-start, flex-start);
    flex-wrap: wrap;
  }

  &-minor {
    padding: $spacer;
    white-space: nowrap;
  }
}
