/*
 * Buttons
 */

.btn {
  position: relative;
  display: inline-block;
  border: 1px solid $color-gray-midnight;
  height: 45px;
  line-height: 43px;
  font-weight: $cerebri-bold;
  font-size: 15px;
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
  padding: 0 20px;
  // outline: none;
  transition: all 100ms;
  text-decoration: none;
  background-color: $color-gray-midnight;
  color: white;
  white-space: nowrap;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:focus {
    text-decoration: none;
  }

  &:focus:not(:disabled) {
    outline: 2px solid rgba(0,0,0,0.15);
  }

  &:hover:not(:disabled) {
    text-decoration: none;
    background-color: transparent;
    color: $color-gray-midnight;
  }

  &.stretch {
    width: 100%;
  }

  &.pill {
    border-radius: 200px;
    text-transform: uppercase;
    &:before {
      border-radius: 200px !important;
    }
  }

  &.pink {
    background-color: $color-accent-pink;
    border-color: $color-accent-pink;
    &:hover:not(:disabled) {
      background-color: transparent;
      color: $color-accent-pink;
    }
  }

  &.blue {
    background-color: $color-accent-blue;
    border-color: $color-accent-blue;
    &:hover:not(:disabled) {
      background-color: transparent;
      color: $color-accent-blue;
    }
  }

  &.green {
    background-color: $color-accent-green;
    border-color: $color-accent-green;
    &:hover:not(:disabled) {
      background-color: transparent;
      color: $color-accent-green;
    }
  }

  &.slate {
    background-color: $color-gray-slate;
    border-color: $color-gray-slate;
    &:hover:not(:disabled) {
      background-color: transparent;
      color: $color-gray-slate;
    }
  }

  &.danger {
    background-color: $color-danger;
    border-color: $color-danger;
    &:hover:not(:disabled) {
      background-color: transparent;
      color: $color-danger;
    }
  }

  &.secondary {
    // font-size: 14px;
    font-weight: $cerebri-regular;
    background-color: transparent;
    color: $color-gray-midnight;
    border-color: $color-gray-midnight;

    &:hover:not(:disabled) {
      background-color: $color-gray-midnight;
      color: white;
    }

    &.pink {
      border-color: $color-accent-pink;
      color: $color-accent-pink;
      &:hover:not(:disabled) {
        background-color: $color-accent-pink;
        color: white;
      }
    }

    &.blue {
      border-color: $color-accent-blue;
      color: $color-accent-blue;
      &:hover:not(:disabled) {
        background-color: $color-accent-blue;
        color: white;
      }
    }

    &.green {
      border-color: $color-accent-green;
      color: $color-accent-green;
      &:hover:not(:disabled) {
        background-color: $color-accent-green;
        color: white;
      }
    }

    &.danger {
      border-color: $color-danger;
      color: $color-danger;
      &:hover:not(:disabled) {
        background-color: $color-danger;
        color: white;
      }
    }

    &.slate {
      border-color: $color-gray-slate;
      color: $color-gray-slate;
      &:hover:not(:disabled) {
        background-color: $color-gray-slate;
        color: white;
      }
    }

    &.white {
      border-color: $color-gray-cloud;
      color: $color-gray-cloud;
      &:hover:not(:disabled) {
        background-color: $color-gray-cloud;
        color: $color-gray-midnight;
      }
    }
  }

  &.special {
    z-index: 100;
    border: none;
    line-height: 45px;
    background-image: $gradient-sunrise;
    color: white;
    &:hover:not(:disabled) {
      background-color: transparent;
      color: $color-sunrise-a;
      &:before {
        opacity: 1;
      }
    }
    &:before {
      content: '';
      z-index: -1;
      position: absolute;
      background-color: white;
      top: 1px;
      right: 1px;
      bottom: 1px;
      left: 1px;
      z-index: -1;
      opacity: 0;
      border-radius: 1px;
    }

    &.jungle {
      background-image: $gradient-jungle;
      &:hover:not(:disabled) {
        color: $color-jungle-a;
      }
    }

    &.orange {
      background-image: $gradient-orange;
      &:hover:not(:disabled) {
        color: $color-accent-orange;
      }
      &.secondary {
        color: $color-accent-orange;
      }
    }

    &.purple {
      background-image: $gradient-purple;
      &:hover:not(:disabled) {
        color: $color-accent-purple;
      }
      &.secondary {
        color: $color-accent-purple;
      }
    }

    &.pink {
      background-image: $gradient-pink;
      &:hover:not(:disabled) {
        color: $color-accent-pink;
      }
      &.secondary {
        color: $color-accent-pink;
      }
    }

    &.green {
      background-image: $gradient-green;
      &:hover:not(:disabled) {
        color: $color-accent-green;
      }
      &.secondary {
        color: $color-accent-green;
      }
    }

    &.blue {
      background-image: $gradient-blue;
      &:hover:not(:disabled) {
        color: $color-accent-blue;
      }
      &.secondary {
        color: $color-accent-blue;
      }
    }

    &.slate {
      background-image: $gradient-slate;
      &:hover:not(:disabled) {
        color: $color-gray-slate;
      }
      &.secondary {
        color: $color-gray-slate;
      }
    }

    &.black {
      background-image: $gradient-black;
      &:hover:not(:disabled) {
        color: $color-gray-midnight;
      }
      &.secondary {
        color: $color-gray-midnight;
      }
    }

    &.slack {
      background-image: $gradient-slack;
      &:hover:not(:disabled) {
        color: $color-slack-purple;
      }
      &.secondary {
        color: $color-slack-purple;
      }
    }

    &.groups {
      background-image: $gradient-groups;
      &:hover:not(:disabled) {
        color: $color-groups-a;
      }
      &.secondary {
        color: $color-groups-a;
      }
    }

    &.secondary {
      background-color: transparent;
      &:before {
        opacity: 1;
      }
      &:hover:not(:disabled) {
        background-color: unset;
        color: white;
        &:before {
          opacity: 0;
        }
      }
    }
  }

  &.icon {
    padding-left: 40px;
    svg.icon {
      position: absolute;
      width: 20px;
      height: 20px;
      left: 10px;
      top: 50%;
      margin-top: -10px;
    }
    &.small {
      padding-left: 32px;
      svg.icon {
        position: absolute;
        width: 16px;
        height: 16px;
        left: 8px;
        top: 50%;
        margin-top: -8px;
      }
    }
    &.xs {
      padding-left: 22px;
      svg.icon {
        position: absolute;
        width: 14px;
        height: 14px;
        left: 4px;
        top: 50%;
        margin-top: -8px;
      }
    }
  }

  &.small {
    height: 35px;
    line-height: 33px;
    font-size: 14px;
    padding: 0 16px;
    border-radius: 1px;
    &.special { line-height: 35px; }
    svg.icon {
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }
  }

  &.xs {
    height: 24px;
    line-height: 22px;
    font-size: 12px;
    padding: 0 6px;
    border-radius: 1px;
    &.special { line-height: 24px; }
    svg.icon {
      width: 14px;
      height: 14px;
      vertical-align: middle;
    }
  }

  &.large {
    height: 65px;
    line-height: 63px;
    font-size: 20px;
    padding: 0 40px;
    // border-radius: 1px;
    &.special { line-height: 65px; }
  }
}

.link-btn {
  display: inline;
  color: $color-accent-blue;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  &:disabled {
    opacity: 0.5;
  }
  &:not(:disabled) {
    cursor: pointer;
    &:hover {
      color: $color-accent-pink;
    }
  }
}

.btn-row {
  @include flexbox(row, center, flex-start);
  gap: $spacer * 0.5;
}

.icon-btn {
  position: relative;
  display: inline-block;
  border: none;
  cursor: pointer;
  text-align: center;
  padding: 0;
  outline: none;
  transition: all 100ms;
  text-decoration: none;
  // background-color: $color-accent-blue;
  border-radius: 100px;
  color: $color-gray-midnight;
  white-space: nowrap;
  height: 26px;
  padding: 4px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover:not(:disabled), &:focus:not(:disabled) {
    text-decoration: none;
    background-color: $color-gray-midnight;
    color: white;
  }

  svg.icon {
    width: 18px;
    height: 18px;
  }
}

.text-btn {
  padding: 0;
  margin: 0;
  color: $color-accent-blue;
  cursor: pointer;
  font-size: 12px;
  &:hover {
    color: $color-accent-pink;
  }
}

/*
 * Inputs
 */

div.standard-input, div.auto-complete-input {
  position: relative;
  color: $color-gray-slate;
  display: block;
  margin-bottom: $spacer * 1.25;

  input, textarea {
    border: 1px solid $color-gray-cloud;
    display: block;
    border-radius: 2px;
    font-size: 14px;
    height: 45px;
    color: $color-gray-slate;
    padding-left: $spacer;
    outline: none;
    width: 100%;
    transition: all 100ms;

    &::placeholder {
      color: $color-text-faint;
    }

    &:focus {
      border-color: $color-gray-midnight;
    }

    &[type='file'] {
      display: none;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  textarea {
    padding: $spacer;
    height: 100px;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.filled {
    color: $color-text-default;

    input, textarea {
      color: $color-text-default;
    }
  }

  &.invalid {
    input, textarea {
      border-color: $color-danger;
    }
  }

  .validation-message {
    font-size: 11px;
    text-transform: uppercase;
    color: $color-danger;
    text-align: left;
  }
}

div.standard-input {
  &.has-icon input {
    padding-left: 38px;
  }

  svg.icon {
    position: absolute;
    left: 10px;
    top: 12.5px;
    width: 20px;
    transition: all 100ms;
  }

  .right-icon svg.icon {
    left: unset;
    right: 10px;
  }

  .standard-input-clear-btn {
    padding: 0;
    position: absolute;
    right: 2px;
    left: auto;
    top: 1px;
    height: 43px;
    // inset: 2px 2px 2px auto;
    transition: all 100ms;
    width: 28px;
    background-color: white;
    cursor: pointer;
    @include flexbox(row, center, center);
    color: $color-gray-slate;
    &:hover {
      color: $color-gray-midnight;
      svg.icon {
        background-color: $color-gray-cloud;
        border-color: $color-gray-cloud;
      }
    }
    svg.icon {
      border-radius: 100px;
      width: 18px;
      border: 3px solid white;
      position: relative;
      left: 0;
      top: 0;
      g {
        stroke-width: 2;
      }
    }
  }

  .prefix {
    position: absolute;
    left: 15px;
    top: 0;
    bottom: 0;
    line-height: 45px; // same at input height to veritcally align text
    color: $color-text-default;
    transition: all 100ms;
  }
}

div.auto-complete-input {
  height: 45px;

  .icon.caret {
    position: absolute;
    right: $spacer;
    top: 50%;
    transition: all 100ms;
    color: $color-gray-slate;
    width: 10px;
    margin-top: -5px;
  }

  .aci-clear-btn {
    padding: 0;
    position: absolute;
    inset: 2px 2px 2px auto;
    transition: all 100ms;
    width: 28px;
    background-color: white;
    cursor: pointer;
    @include flexbox(row, center, center);
    color: $color-gray-slate;
    &:hover {
      color: $color-gray-midnight;
      svg.icon {
        background-color: $color-gray-cloud;
        border-color: $color-gray-cloud;
      }
    }
    svg.icon {
      border-radius: 100px;
      width: 18px;
      border: 3px solid white;
      g {
        stroke-width: 2;
      }
    }
  }

  .aci-input-container {
    height: 100%;
    position: relative;
    input {
      height: 100%;
    }
  }

  &.icon {
    .aci-input-container {
      .aci-icon {
        position: absolute;
        top: 50%;
        left: 6px;
        margin-right: calc($spacer / 2);
        margin-top: -12px;
      }
    }
    .aci-selected-result-con {
      .aci-icon {
        margin-left: 6px;
        margin-right: -6px;
      }
    }
    .aci-icon {
      flex-shrink: 0;
      width: 24px;
    }
    .aci-input-container input {
      padding-left: 36px;
    }
  }

  &.selected {
    .icon.caret {
      display: none;
    }
    .aci-input-container {
      height: 0;
      overflow: hidden;
    }
  }

  &.focused {
    input {
      border-color: $color-gray-midnight;
    }
  }

  &.disabled {
    input, button {
      cursor: not-allowed;
    }
  }

  &.static-actions {
    .aci-dropdown-results {
      bottom: $spacer + 40px;
    }
  }

  .aci-dropdown {
    height: 0px;
    opacity: 0;
    position: absolute;
    border: 1px solid $color-gray-midnight;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    border-top: none;
    left: 0; right: 0;
    top: 100%;
    margin-top: -2px;
    z-index: 1000;
    background-color: white;
    transition: all 100ms;
    text-align: left;

    &:after {
      display: block;
      content: '';
      position: absolute;
      background-color: $color-gray-cloud;
      height: 1px;
      left: $spacer; right: $spacer;
      top: 0;
    }

    &-message {
      padding: $spacer;
    }

    &-results {
      overflow: auto;
      position: absolute;
      top: $spacer; bottom: $spacer;
      left: 0; right: 0;
    }

    &-static-actions {
      border-top: 1px solid $color-gray-cloud;
      padding: $spacer;
      position: absolute;
      height: 40px;
      bottom: 0;
      left: 0; right: 0;
    }
  }

  &.open .aci-dropdown {
    height: 200px;
    opacity: 1;
  }

  .aci-selected-result-con {
    text-align: left;
    background-color: white;
    border: 1px solid $color-gray-cloud;
    border-radius: 2px;
    height: 100%;
    @include flexbox(row, center, flex-start);
    .aci-result {
      height: 100%;
      @include flexbox(column, flex-start, center);
    }
  }

  &.aci-group {
    .aci-result-left {
      max-width: 200px;
    }
  }

  .aci-result {
    display: block;
    background-color: transparent;
    border: none;
    width: 100%;
    text-align: left;
    outline: none;
    font-size: 14px;
    padding: calc($spacer / 2) $spacer;

    &:hover:not(.selected), &:focus:not(.selected) {
      background-color: #F3F7FC;
      cursor: pointer;
    }

    &.selected {
      .aci-result-main {
        font-weight: $cerebri-bold;
        font-size: 14px;
        line-height: 18px;
        height: 18px;
        overflow: hidden;
      }
    }

    &-main {
      color: $color-text-default;
    }
    &-subtext {
      @include flexbox(row, center, space-between);
      width: 100%;
      color: $color-text-faint;
      font-size: 12px;
      overflow: hidden;
    }
    &-left {
      float: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 300px;
    }
    &-right {
      float: right;
    }
  }
}

.standard-input.first-name, .standard-input.last-name {
  &:after {
    content: '';
    display: block;
    width: 10px;
    height: 20px;
    position: absolute;
    left: 10px;
    top: 12.5px;
    background-color: rgba(255,255,255,0.8);
  }
}
.standard-input.first-name {
  &:after {
    left: 20px;
    width: 11px; // safari seems to be rendering part of the SVG outside of its bounds #fun
  }
}

.simple-search {
  position: relative;
  height: 45px;

  input {
    height: 100%;
    border: none;
    background-color: $color-gray-ice;
    color: $color-gray-midnight;
    border-radius: 100px;
    font-size: 14px;
    font-weight: $cerebri-regular;
    outline: none;
    padding-left: 35px;
    min-width: 200px;

    &::placeholder {
      color: $color-gray-fog;
    }
  }

  svg.icon {
    left: $spacer;
    top: 50%;
    margin-top: -8.5px;
    position: absolute;
    width: 17px;
    color: $color-gray-slate;
  }
}

/*
 * Form container
 */

.form-box {
  width: 450px;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.09) 0 1px 10px 1px;
  border: 1px solid transparent; // respects the margins of children
  text-align: center;
  background-color: #FFF;

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: $spacer * 4;
    margin-bottom: $spacer * 2;
    align-items: center;

    svg.icon {
      width: 24px;
      margin-right: $spacer * 0.75;
    }

    h1 {
      font-size: 27px;
      font-weight: $cerebri-light;
      margin: 0;
    }
  }

  form {
    width: 265px;
    margin: 0 auto;
    margin-bottom: $spacer * 4;

    input.btn[type='submit'] {
      width: 100%;
      height: 45px;
    }
  }

  &-form {
    width: 265px;
    margin: 0 auto;
    margin-bottom: $spacer * 4;
  }

  &-submit-btn {
    width: 100%;
  }

  .standard-input {
  }

  .error-message {
    margin-top: $spacer * 1.25;
    margin-bottom: 0;
    color: $color-danger;
    font-size: 13px;
  }

  &-or {
    margin: ($spacer * 2) auto;
    text-align: center;
    color: $color-gray-fog;
    position: relative;
    & > div {
      display: inline-block;
      margin: 0 auto;
      background-color: white;
      padding: 0 $spacer;
      position: relative;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $color-gray-cloud;
    }
  }

  @include media("<medium") {
    width: 100%;
    max-width: 400px;

    &-header {
      margin-top: $spacer * 2;
    }

    form {
      width: 100%;
      padding: 0 ($spacer * 2);
    }
  }
}

/*
 * Checkbox
 */

div.checkbox {
  display: inline-block;
  width: 15px;
  height: 15px;
  position: relative;

  input[type="checkbox"] {
    border: 1px solid black;
    display: block;
    -moz-appearance: none;
    -webkit-appearance: none;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
  }

  .checkbox-fake {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid $color-gray-fog;
    z-index: 1;
    border-radius: 1px;
    transition: all 100ms;

    svg {
      width: 13px;
      height: 13px;
      display: block;
      polyline {
        stroke: transparent;
      }
    }
  }

  input[type="checkbox"]:focus + .checkbox-fake, input[type="checkbox"]:focus + .checkbox-toggle {
    box-shadow: 0 0 3px rgba(0,0,0,0.3);
  }

  input[type="checkbox"]:hover:not(:disabled) + .checkbox-fake {
    border-color: $color-accent-green;
  }

  input[type="checkbox"]:disabled {
    cursor: not-allowed;
    & + .checkbox-fake, & + .checkbox-toggle {
      opacity: 0.5;
    }
  }

  input[type="checkbox"]:checked + .checkbox-fake {
    border-color: $color-accent-green;
    background-color: $color-accent-green;
    svg polyline {
      stroke: white;
    }
  }

  &.toggle {
    height: 24px;
    width: 50px;
  }

  .checkbox-toggle {
    border: 1px solid $color-danger;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 100px;
    transition: all 200ms;

    &-knob {
      width: 16px;
      height: 16px;
      background-color: $color-danger;
      border-radius: 100%;
      position: absolute;
      font-size: 12px;
      top: 3px;
      left: 3px;
      transition: all 200ms;
      &:before, &:after {
        display: block;
        position: absolute;
        top: 0;
        line-height: 16px;
        transition: opacity 200ms;
      }
      &:before {
        content: 'ON';
        right: 100%;
        margin-right: 2px;
        color: $color-accent-green;
        opacity: 0;
      }
      &:after {
        content: 'OFF';
        left: 100%;
        margin-left: 2px;
        color: $color-danger;
        opacity: 1;
      }
    }
  }

  &.off-ok {
    .checkbox-toggle {
      border-color: $color-gray-slate;
      &-knob {
        background-color: $color-gray-slate;
        &:after {
          color: $color-text-medium-1-5;
        }
      }
    }
    // .checkbox-toggle {
    //   border-color: $color-gray-slate;
    // }
  }

  input[type="checkbox"]:checked + .checkbox-toggle {
    border-color: $color-accent-green;
    .checkbox-toggle-knob {
      background-color: $color-accent-green;
      left: 29px;
      &:before {
        opacity: 1;
      }
      &:after {
        opacity: 0;
      }
    }
  }
}
.cb-label-con {
  @include flexbox(row, center, flex-start);
  gap: $spacer * 0.5;
}

/*
 * Radio Button
 */

.radio-button {
  display: inline-block;
  width: 15px;
  height: 15px;
  position: relative;

  input[type="radio"] {
    border: 1px solid black;
    display: block;
    -moz-appearance: none;
    -webkit-appearance: none;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
  }

  &-fake {
    height: 100%;
    width: 100%;
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid $color-gray-fog;
    z-index: 1;
    background-color: white;
    transition: all 100ms;
  }

  input[type="radio"]:focus + .radio-button-fake {
    box-shadow: 0 0 3px rgba(0,0,0,0.3);
  }

  input[type="radio"]:hover + .radio-button-fake {
    border-color: $color-accent-blue;
  }

  input[type="radio"]:checked + .radio-button-fake {
    border-color: $color-accent-blue;
    border-width: 4px;
  }
}

/*
 *  Segmented Input
 */

.segmented-input {
  height: 45px;
  display: inline-block;
  position: relative;

  &.small {
    height: 35px;
  }

  &.stretch {
    display: block;
    .segmented-input-option {
      flex: 1;
    }
  }

  &.disabled {
    .segmented-input-options {
      opacity: 0.5;
    }
    .segmented-input-option {
      cursor: not-allowed;
    }
  }

  &-options {
    border: 1px solid $color-gray-midnight;
    border-radius: 1px;
    height: 100%;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: $cerebri-bold;
  }

  &-option {
    height: 100%;
    padding: 0 ($spacer * 2);
    display: flex;
    @include flexbox(row, center, center);
    transition: all 200ms;
    color: $color-gray-fog;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      color: $color-gray-midnight;
    }

    &.selected {
      background-color: $color-gray-midnight;
      color: white;
    }
  }

  &-subtext {
    position: absolute;
    top: 100%;
    left: 0;
  }
}

/*
 *  Basic Form
 *  Used for vol track modal and drive track modal
 */

.bform {
  & > .modal-box {
    padding: $spacer * 4;
    width: 600px;
  }
  &-h1 {
    @include flexbox(row, center, flex-start);
    gap: $spacer;
    font-size: 30px;
    font-weight: $cerebri-heavy;
    margin: 0;
    svg.icon {
      width: 24px;
    }
  }
  &-h2 {
    display: block;
    margin: ($spacer * 4) 0 $spacer;
    font-size: 18px;
    font-weight: $cerebri-heavy;
  }
  &-h3 {
    display: block;
    margin: ($spacer * 2) 0 calc($spacer / 2);
    text-transform: uppercase;
    font-weight: $cerebri-bold;
    color: $color-text-medium-1-2;
  }
  &-actions {
    margin-top: $spacer * 4;
    @include flexbox(row, center, flex-end);
    &-right {
      flex: 1;
      @include flexbox(row, center, flex-end);
      .btn:not(:first-child) {
        margin-left: $spacer;
      }
    }
    & > .btn:first-child:last-child {
      width: 100%;
    }
  }
  &-error {
    color: $color-danger;
    margin-top: $spacer;
  }
  .standard-input, .dd {
    display: block;
    margin: 0;
    & + .bform-input-desc {
      margin-top: $spacer * 0.25;
    }
  }
  &-cb {
    @include flexbox(row, center, flex-start);
    gap: $spacer * 0.5;
  }
  &-input-desc {
    margin: 0;
  }
  &-or {
    position: relative;
    text-align: center;
    overflow: hidden;
    color: $color-text-faint;
    text-transform: lowercase;
    line-height: 12px;
    margin: ($spacer * 4) 0;
    &:before, &:after {
      content: '';
      display: block;
      width: 50%;
      height: 1px;
      background-color: $color-gray-cloud;
      top: 50%;
      position: absolute;
    }
    &:before {
      left: 0;
      margin-left: -18px;
    }
    &:after {
      right: 0;
      margin-right: -18px;
    }
  }

  &-label-row {
    @include flexbox(row, baseline, space-between);
  }

  .choice-tiles {
    margin-top: $spacer * 4;
  }

  .notice {
    margin-top: $spacer * 2;
  }

  .wizard-steps {
    margin-top: $spacer * 2;
    margin-right: $spacer * -4;
    margin-left: $spacer * -4;
    margin-bottom: $spacer * 4;
    padding-right: $spacer * 4;
    padding-left: $spacer * 4;
  }

  &-table {
    margin-top: $spacer * 1.5;
    width: 100%;
    td, th {
      text-align: left;
      padding: ($spacer * 0.5) 0;
      .standard-input {
        margin: 0;
      }
      & + td, & + th {
        padding-left: $spacer;
      }
    }
    th {
      text-transform: uppercase;
      font-weight: $cerebri-bold;
      color: $color-text-medium-1-2;
      padding-bottom: 0;
    }
  }

  @include media("<medium") {
    .modal-box {
      padding: $spacer * 2;
      width: 100%;
    }
  }
}

/*
 * Choice Tiles
 */

.choice-tiles {
  &-choice {
    display: block;
    text-align: left;
    border: 1px solid $color-gray-cloud;
    padding: $spacer * 2;
    // margin: $spacer * 2;
    border-radius: 5px;
    cursor: pointer;
    transition: all 200ms;
    position: relative;

    &-badge {
      position: absolute;
      top: $spacer;
      right: $spacer;
      color: $color-accent-green;
      font-weight: $cerebri-bold;
      font-size: 12px;
      &-emoji {
        // content: '✨';
        // display: inline;
        font-size: 20px;
        vertical-align: middle;
      }
    }

    &-main {
      &-title {
        margin: 0;
        font-size: 18px;
        font-weight: $cerebri-heavy;
      }
      &-desc {
        margin-top: $spacer;
        margin-bottom: 0;
      }
    }

    &:hover {
      background-color: $color-highlight-blue;
    }
    &.active {
      border-color: $color-accent-blue;
      background-color: $color-highlight-blue;
    }
  }

  &-choice + &-choice {
    margin-top: $spacer * 2;
  }
}

.wizard-steps {
  @include flexbox(row, flex-start, space-around);
  border-bottom: 1px solid $color-gray-cloud;

  &-step {
    @include flexbox(column, center, flex-start);
    color: $color-text-faint;
    padding-bottom: $spacer;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }

    &-icon {
      width: 20px;
      margin-bottom: calc($spacer / 2);
    }

    &.active {
      font-weight: $cerebri-bold;
      color: inherit;
      position: relative;
      &:after {
        content: '';
        display: block;
        border: 1px solid $color-gray-cloud;
        border-color: transparent $color-gray-cloud $color-gray-cloud transparent;
        position: absolute;
        width: 10px; height: 10px;
        top: 100%; left: 50%;
        margin-top: -5px; margin-left: -5px;
        transform: rotate(45deg);
        background-color: white;
      }
    }
    &.complete {
      color: $color-accent-green;
    }
  }
}
