.cadmin-cgrant {
  .ca-box {
    width: 860px;
  }
  &-body {
    display: flex;
    flex-direction: row;
    // border: 1px solid lightblue;
  }
  &-form {
    flex: 1;
    margin-right: $spacer * 3;
    &-label {
      display: block;
      padding-bottom: calc($spacer / 2);
      &.margin {
        margin-top: $spacer * 1.25;
      }
    }
    .dd {
      width: 100%;
    }
    &-field-msg {
      margin: 0;
      color: $color-text-medium-1-5;
    }
  }
  &-preview {
    width: 360px;
    &-placeholder {
      display: block;
      width: 100%;
      height: 340px;
      object-fit: cover;
      object-position: top;
      border: 1px solid $color-gray-cloud;
    }
  }
  &-error-msg {
    color: $color-danger;
    margin-top: $spacer;
  }
}
