.modal-emp-role {

  &-title {
    font-size: 20px;
    font-weight: $cerebri-heavy;
    margin: 0;
  }

  &-body {
    padding: $spacer * 4;
  }

  &-emp {
    border: 1px solid $color-gray-cloud;
    border-width: 1px 0;
    margin-top: $spacer * 2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: $spacer;
    > *:first-child {
      font-weight: $cerebri-bold;
    }
  }

  &-actions {
    margin-top: $spacer * 4;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &-option {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: $spacer * 2;

    &:not(.active) {
      opacity: 0.6;
    }

    label {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      cursor: pointer;

      strong {
        padding-left: $spacer;
        width: 180px;
      }
      p {
        width: 380px;
        margin: 0;
      }
    }
  }
}
