.page-ca-gifts {
  .card-img img {
    display: block;
    width: 40px;
    transition: transform 100ms;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.1);
    &:hover {
      transform: scale(5);
    }
  }
  .status-pending {
    color: $color-text-faint;
  }
  .status-redeemed {
    color: $color-accent-green;
    font-weight: $cerebri-bold;
  }
  .status-expired {
    color: $color-danger;
  }
}
