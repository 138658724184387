.cam-pro {

  &-act {
    max-width: 1154px;
    // border: 1px solid red;
    margin: 0 auto;
    margin-top: $spacer * 6;
    &-title {
      margin: 0;
      font-size: 24px;
      text-transform: uppercase;
      font-weight: $cerebri-heavy;
      @include flexbox(row, center, center);
      svg.icon {
        width: 24px;
        display: block;
        margin-right: $spacer;
      }
    }
    &-details {
      text-align: center;
      font-weight: $cerebri-bold;
      margin-top: $spacer * 2;
    }
    &-box {
      @include flexbox(row, center, flex-start);
      border-radius: 2px;
      box-shadow: $shadow;
      // padding: ($spacer * 2) ($spacer * 3);
      padding: ($spacer * 4) ($spacer * 6);
      margin-top: $spacer * 2;
      &-aside {
        padding: ($spacer) 0;
        border: 1px solid $color-gray-cloud;
        border-width: 0 1px 0 0;
        padding-right: $spacer * 6;
        margin-right: $spacer * 6;
      }
      &-main {
        flex: 1;
      }
      &-cta {
        @include flexbox(column, center, center);
        label {
          margin-bottom: $spacer;
        }
        // min-width: 200px;
      }

      .progress-bar {
        margin-top: 0;
        & + .progress-bar {
          margin-top: $spacer * 2;
        }
      }
    }

    &.green  .cam-pro-act-title { color: $color-accent-green; }
    &.purple .cam-pro-act-title { color: $color-accent-purple; }
    &.orange .cam-pro-act-title { color: $color-accent-orange; }
    &.pink   .cam-pro-act-title { color: $color-accent-pink; }

    @include media("<medium") {
      &-box {
        @include flexbox(column-reverse, stretch, flex-start);
        padding: $spacer * 2;
        &-aside {
          // border-right: none;
          margin-right: 0;
          margin-top: $spacer * 2;
          padding-right: 0;
          padding-top: $spacer * 2;
          border-width: 1px 0 0 0;
        }
      }
    }
  }

  .campaign-progress-bar {
    &-placeholder {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      .amounts {
        display: flex;
        flex-wrap: row wrap;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;

        .goal {
          align-self: flex-end;
        }

        .fake-lines.paragraph .fake-lines-row:last-child {
          width: 150px;
          margin: 0
        }
      }

      .bar {
        width: 100%;

        .fake-lines.paragraph .fake-lines-row:last-child {
          width: 100%;
          margin: 0;
        }
      }
    }
  } 
  
  .campaign-section {
    min-height: $spacer * 4;
    padding: 0;
    margin: 0 auto;
    margin-top: $spacer * 2;

    .nonprofits {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
    }

    &-title {
      font-size: 16px;
      font-weight: $cerebri-bold;
      text-align: center;
      text-transform: uppercase;
    }
    &.backers {
      margin-top: $spacer * 6;
    }
  }

  .campaign-headline {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &-details {
      display: flex;
      flex-direction: column;
      max-width: 510px;

      &-blurb {
        font-size: 15px;
        font-weight: $cerebri-regular;
        color: $color-gray-midnight;
      }

      &-dates {
        height: 18px;
        color: $color-gray-slate;

        .coming-soon-label, .ended-label {
          color: $color-accent-blue;
          text-transform: uppercase;
        }

        .ended-label {
          color: $color-red;
        }

        .date-range-label {
          color: $color-gray-slate;
          text-transform: unset;
          margin-left: ($spacer * 0.5);
        }
      }
    }
  }

  &-events {
    &-events {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // margin-top: $spacer * -1.5;
      margin-right: $spacer * -1.5;
    }
    &-event {
      width: 50%;
      padding: ($spacer * 1.5) ($spacer * 1.5) 0 0;
    }
    @include media("<large") {
      &-events {
        margin-right: 0;
      }
      &-event {
        width: 100%;
        padding-right: 0;
      }
    }
  }

  .campaign-cards {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;

    .np-card {
      margin: ($spacer * 2);
    }
    .campaign-card-placeholder {
      margin: ($spacer * 2);
      width: 360px;
    }
  }

  .campaign-fund {
    @extend .box;
    max-width: 1154px;
    margin: 0 auto;
    margin-top: $spacer * 2;
    padding: ($spacer * 3) ($spacer * 4);
    .campaign-section-title {
      margin: 0;
      margin-bottom: $spacer * 3;
    }
    &-row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &-donate {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      .amount-selector {
      }
      &-btn {
        width: 150px;
        margin-left: $spacer * 4;
      }
    }
    &-desc {
      padding-left: $spacer * 4;
      margin: 0;
      margin-left: $spacer * 4;
      max-width: 350px;
      font-size: 12px;
      border-left: 1px solid $color-gray-cloud;
      &-exp {
        margin-top: 0;
      }
      &-type {
        font-weight: bold;
        margin: 0;
      }
      &-bal {
        @extend .faint;
        margin: 0;
        text-transform: uppercase;
      }
    }
  }

  .campaign-recent-donors {
    .recent-donors-text {
      font-weight: $cerebri-bold;
    }
  }

  .campaign-status-card {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 104px;
    width: 100%;
    max-width: 1154px;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 2px 1px 10px 1px rgba(0,0,0,0.09);
    padding: ($spacer * 0.5);
    margin: ($spacer * 4) auto;

    &-title {
      color: $color-red;
      font-size: 24px;
      font-weight: $cerebri-heavy;
      min-width: 300px;
      padding: $spacer * 0.5;
      margin: $spacer * 2;

      svg.icon {
        width: 24px;
        height: 24px;
        position: relative;
        display: inline-block;
        color: $color-red;
        margin-right: $spacer * 0.25;
      }

      &.coming-soon {
        @extend .gradient-purple-text;

        svg.icon {
          color: $color-accent-purple;
        }
      }
    }

    &-details {
      color: $color-gray-midnight;
      font-weight: $cerebri-heavy;
      padding: $spacer * 0.5;
      margin: 0 $spacer * 2;
      margin-top: $spacer * 0.5;
    }

    &-placeholder {
      display: flex;
      flex-wrap: row wrap;
      justify-content: center;
      align-items: center;
      min-height: 104px;
      width: 100%;
      max-width: 1154px;
      border-radius: 3px;
      background-color: #FFFFFF;
      box-shadow: 2px 1px 10px 1px rgba(0,0,0,0.09);
      padding: ($spacer * 0.5) ($spacer * 1);
      margin: ($spacer * 4) auto;

      .fake-lines.paragraph .fake-lines-row:last-child{
        width: 380px;
        margin: 0 ($spacer * 4);
      }
    }
  
    @include media("<medium") {
      flex-wrap: wrap;
      width: 100%;
      min-width: 300px;

      &-title, &-details {
        text-align: center;
        width: 136px;
        margin: $spacer * 0.5;
      }
    }
  }

  .page-campaign-actions {
    padding-top: $spacer * 2;
  }

  &-social {
    margin: ($spacer * 2) 0 ($spacer * 8);
  }
}
