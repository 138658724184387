.pheader {
  height: 100px;
  background-color: white;
  position: fixed;
  top: -100px;
  left: 0;
  width: 100%;
  z-index: 150;
  transition: all 200ms;

  &.show {
    top: 0;
    box-shadow: 0 1px 10px 1px rgba(0,0,0,0.09);
  }

  &-con {
    // border: 1px solid violet;
    height: 100%;
    @include flexbox(row, center, center);
    gap: $spacer * 8;
  }

  &-main {
    @include flexbox(row, center, flex-start);
    gap: $spacer * 2;
    height: 100%;
    &-img {
      display: block;
      max-height: 64px;
      max-width: 128px;
    }
    &-text {
      height: 100%;
      @include flexbox(row, center, center);
      &-title {
        font-weight: $cerebri-heavy;
        font-size: 20px;
        line-height: 25px;
        max-height: 50px;
        margin: 0;
        overflow: hidden;
        display: block;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        &.thin {
          font-weight: $cerebri-light;
          text-transform: uppercase;
        }
      }
    }
  }

  &-items {
    flex: 1;
    @include flexbox(row, center, flex-end);
    gap: $spacer * 4;
    height: 100%;

    &.has-active {
      .pheader-item {
        opacity: 0.4;
      }
    }

    &.no-colors {
      .pheader-item-label {
        // font-size: 16px;
        font-weight: $cerebri-bold;
      }
    }
  }

  &-item {
    @include flexbox(row, center, flex-start);
    gap: $spacer * 0.5;
    cursor: pointer;
    height: 100%;
    transition: all 200ms;
    position: relative;

    &.active, &:hover {
      opacity: 1 !important;
    }

    &.active:after {
      display: block;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $color-gray-midnight;
    }

    &.color-green {
      color: $color-accent-green;
      &.active:after { background-color: $color-accent-green; }
    }
    &.color-purple {
      color: $color-accent-purple;
      &.active:after { background-color: $color-accent-purple; }
    }
    &.color-orange {
      color: $color-accent-orange;
      &.active:after { background-color: $color-accent-orange; }
    }
    &.color-pink {
      color: $color-accent-pink;
      &.active:after { background-color: $color-accent-pink; }
    }
    &.color-blue {
      color: $color-accent-blue;
      &.active:after { background-color: $color-accent-blue; }
    }

    &-icon {
      width: 22px;
    }
    &-label {
      font-size: 15px;
      font-weight: $cerebri-heavy;
      text-transform: uppercase;
    }
  }

  @include media("<xl") {
    &-main-img {
      display: none;
    }
    &-items {
      gap: $spacer * 2;
    }
  }
  @include media("<large") {
    display: none;
  }
}


// .ruler {
//   position: fixed;
//   top: 50%;
//   left: 0;
//   width: 100%;
//   height: 1px;
//   background-color: red;
//   z-index: 500;
// }
