.style-guide {

  h2 {
    margin-bottom: $spacer * 2;
  }

  .color-row {
    display: flex;
    flex-direction: row;
    margin-bottom: $spacer * 4;

    .color {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: $spacer * 4;
      width: 80px;

      &-name {
        font-weight: $cerebri-bold;
        margin-top: $spacer;
        text-align: center;
      }

      &-circle {
        width: 80px;
        height: 80px;
        border-radius: 100%;

        &.blue { background-color: $color-accent-blue; }
        &.pink { background-color: $color-accent-pink; }
        &.green { background-color: $color-accent-green; }
        &.yellow { background-color: $color-accent-yellow; }
        &.purple { background-color: $color-accent-purple; }
        &.orange { background-color: $color-accent-orange; }
        &.danger { background-color: $color-danger; }

        &.midnight { background-color: $color-gray-midnight; }
        &.slate { background-color: $color-gray-slate; }
        &.fog { background-color: $color-gray-fog; }
        &.cloud { background-color: $color-gray-cloud; }
        &.ice { background-color: $color-gray-ice; }
        &.white { background-color: white; }

        &.rainbow { background-image: $gradient-rainbow; }
        &.sunrise { background-image: $gradient-sunrise; }
        &.jungle { background-image: $gradient-jungle; }

        &.gradient-purple { background-image: $gradient-purple; }
        &.gradient-orange { background-image: $gradient-orange; }
        &.gradient-pink { background-image: $gradient-pink; }
        &.gradient-blue { background-image: $gradient-blue; }
        &.gradient-green { background-image: $gradient-green; }
      }
    }
  }

  .button-cols {
    display: flex;
    flex-direction: row;
  }
  .button-row {
    display: flex;
    flex-direction: column;
    margin-right: $spacer * 4;
  }
  .btn {
    margin-bottom: $spacer * 2;
  }

  .icons {
    overflow: hidden;

    .icon-group {
      margin: 0 $spacer $spacer 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      float: left;
      border: 1px solid $color-gray-cloud;
      padding: $spacer;
      border-radius: 5px
    }

    svg.icon {
      width: 24px;
      height: 24px;
      margin-right: $spacer;
    }
  }

  &-radios {
    display: flex;
    flex-direction: row;
    align-items: center;
    .radio-button, .checkbox {
      margin-right: calc($spacer / 2);
    }
    label {
      margin-right: $spacer;
    }
  }

  .triangles-con {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 100px;
  }

}
