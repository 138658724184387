.page-cadmin-dashboard {

  &-forms {
    display: flex;
    flex-direction: row;
    margin-bottom: $spacer * 4;
  }
  &-main-form {
    flex: 1;
    padding-right: $spacer * 4;
  }
  &-cg-form {
    flex: 1;
    padding-left: $spacer * 4;
    &:not(.disabled) {
      border-left: 1px solid $color-gray-cloud;
    }
    &-ssi-con {
      margin-bottom: $spacer * 1.25;
    }
  }

}
