.modal {
  background-color: rgba(0,0,0,0.85);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: $spacer * 4;
  z-index: 1000;

  .modal-box {
    background-color: white;
    margin: $spacer * 4;
    margin: auto;
    position: relative;

    &-back, &-close {
      padding: 9px;
      position: absolute;
      top: ($spacer * 2) - 9px;
      color: $color-gray-slate;
      display: flex;
      align-items: center;
      border-radius: 100px;
      cursor: pointer;

      &:hover {
        color: $color-gray-midnight;
        background-color: $color-gray-cloud;
      }

      svg.icon {
        width: 14px;
        display: block;
      }
    }

    &-back {
      left: ($spacer * 2) - 9px;
    }

    &-close {
      right: ($spacer * 2) - 9px;

      // The optional close button text:
      &-text {
        margin-left: ($spacer * 0.5);
        font-weight: $cerebri-bold;
        text-transform: uppercase;
      }
    }
  }

  &.modal-success {
    .modal-box {
      padding: $spacer * 4;
      max-width: 612px;
      text-align: center;
    }

    .modal-success-title {
      margin: 0;
      font-size: 24px;
      font-weight: $cerebri-bold;
      color: $color-accent-pink;
    }

    .modal-success-subtitle {
      margin: 0;
      margin-top: $spacer * 2;
      font-size: 24px;
      font-weight: $cerebri-light;
    }
    .modal-success-message {
      margin: 0;
      margin-top: $spacer * 2;
      font-weight: $cerebri-bold;
      font-size: 14px;
    }
    .modal-success-actions {
      margin-top: $spacer * 2;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .btn {
        margin: 0 calc($spacer / 2);
      }
    }
  }

  @include media("<medium") {
    padding: ($spacer * 2) 0;
  }
}


.image-modal {
  .modal-box {
    padding: $spacer * 4;
  }
  &-img {
    display: block;
    max-width: 800px;
    width: 100%;
  }
  &-iframe {
    display: block;
    border: none;
    width: 800px;
    height: 500px;
  }
}

.confirm-modal {
  .modal-box {
    padding: ($spacer * 4) ($spacer * 4);
    min-width: 450px;
    max-width: 600px;
  }
  &-title {
    margin: 0;
    font-size: 30px;
    font-weight: $cerebri-heavy;
  }
  &-actions {
    @include flexbox(row, center, flex-end);
    margin-top: $spacer * 4;
    .btn {
      margin-left: $spacer;
    }
  }
}
