.ca-grps-cfg {

  .ca-box {
    max-width: 800px;
  }

  &-sections {
    margin-bottom: $spacer * 2;
  }

  &-section {
    @include flexbox(row, center, flex-start);
    padding: ($spacer) ($spacer * 2) ($spacer) ($spacer);
    background-color: $color-gray-ice;
    margin-bottom: $spacer;
    border-radius: 4px;

    &-move {
      margin-right: $spacer;
      @include flexbox(column, center, center);
      opacity: 0;
      transition: all 200ms;
    }

    &-name {
      // width: 300px;
      margin-right: $spacer;
      flex: 1;
      &-label {
        font-weight: $cerebri-heavy;
        font-size: 16px;
      }
    }

    &-btns {
      opacity: 0;
      transition: all 200ms;
    }

    .standard-input {
      margin-bottom: 0;
    }

    &:hover {
      .ca-grps-cfg-section-move, .ca-grps-cfg-section-btns {
        opacity: 1;
      }
    }
  }


}
