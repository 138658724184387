.ca-modal-cattrs-new {

  &-table {
    &-cell-remove {
      button {
        width: 24px;
        height: 24px;
        padding: 5px;
        border-radius: 100px;
        cursor: pointer;
        &:hover {
          background-color: rgba(0,0,0,0.1);
        }
        svg.icon {
          width: 14px;
        }
      }
    }
  }

}
