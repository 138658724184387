.page-np-edit {
  $padding-left: $spacer * 4;
  display: flex;
  flex-direction: row;
  height: 100vh;

  &-preview {
    flex: 1;
    overflow: auto;
    padding-bottom: $spacer * 8;
  }

  &-form {
    width: 400px;
    background-color: $color-gray-ice;
    display: flex;
    flex-direction: column;

    &-fields {
      overflow: auto;
      padding: ($spacer * 2) ($spacer * 3) 200px $padding-left;
      flex: 1;

      &-help {
        display: block;
        text-align: right;
      }
    }

    &-actions-text {
      font-size: 12px;
      color: $color-text-faint;
      padding: calc($spacer / 2) ($spacer * 2);
    }

    &-footer {
      padding: $spacer ($spacer * 2);
      background-color: $color-gray-midnight;
      position: fixed;
      bottom: 0;
      right: 0;
      width: 400px;
      color: white;

      &-actions {
        @include flexbox(row, center, flex-end);
        margin-top: $spacer * 2;
        p {
          margin-right: $spacer;
          font-size: 13px;
        }
      }

      &-score {
        margin-bottom: $spacer;
        &-label {
          margin-bottom: calc($spacer / 2);
          @include flexbox(row, center, space-between);
          a {
            color: inherit;
            &:hover {
              text-decoration: none;
              color: $color-accent-pink;
            }
          }
        }
        &-bar {
          width: 100%;
          background-color: black;
          border-radius: 20px;
          &-progress {
            background-color: $color-accent-green;
            height: 6px;
            border-radius: 20px;
            transition: all 400ms;
            min-width: 6px;
          }
        }
        &.red &-bar-progress {
          background-color: $color-danger;
        }
        &.yellow &-bar-progress {
          background-color: $color-accent-yellow;
        }
      }
    }
  }

  .npp-edit-form-section {
    margin-bottom: $spacer * 2;

    &-title {
      cursor: pointer;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $spacer;

      h3 {
        margin: 0;
        font-size: 16px;
        font-weight: $cerebri-bold;
        text-transform: uppercase;
      }

      svg.icon.check {
        position: absolute;
        width: 16px;
        left: -22px;
        top: 3px;
      }

      &-spacer {
        flex: 1;
      }

      svg.icon.caret {
        color: $color-gray-slate;
        width: 12px;
      }
    }

    &-body {
      .standard-input {
        margin-bottom: calc($spacer / 3);
      }
    }

    &-subtext {
      color: $color-text-faint;
      font-size: 13px;
      text-transform: uppercase;
    }

    &.collapsed {
      .npp-edit-form-section-title {
        h3 {
          opacity: 0.5;
        }
      }
      .npp-edit-form-section-body {
        display: none;
      }

      &.optional {
        .npp-edit-form-section-title svg.icon.check {
          opacity: 0.1;
        }
      }
    }

    &.complete {
      .npp-edit-form-section-title {
        color: $color-accent-green;
        h3 {
          opacity: 1;
        }
        svg.icon.check {
          opacity: 1 !important;
          g {
            stroke: white;
            fill: $color-accent-green;
          }
        }
        &-required {
          display: none;
        }
      }
      &.expanded {
        .npp-edit-form-section-title h3 {
          color: $color-text-default;
        }
      }
    }

    &.metadata {
      label {
        color: $color-text-medium-1-2;
        text-transform: uppercase;
        font-size: 12px;
      }
      .dd, .auto-complete-input, .standard-input {
        margin-bottom: $spacer;
        & + .npp-edit-form-section-subtext {
          margin-top: $spacer * -1;
          margin-bottom: $spacer;
        }
      }
    }
  }

  .npp-edit-form-subsection {
    margin-left: $padding-left * -1;
    padding-left: $padding-left;
    position: relative;
    margin-bottom: $spacer;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 39px;
      width: 1px;
      background-color: $color-gray-fog;
    }

    &-delete {
      position: absolute;
      display: block;
      opacity: 0;
      transition: all 200ms;
      width: 19px;
      top: 50%;
      margin-top: -9px;
      left: 12px;
      color: $color-gray-slate;
      cursor: pointer;

      &:hover {
        color: $color-gray-midnight;
      }
    }

    &:hover {
      .npp-edit-form-subsection-delete {
        opacity: 1;
      }
    }
  }

  .npp-edit-form-stat {
    &-figure {
      width: 62px;
    }
  }


  .npp-edit-form-dollar {
    &-amount {
      width: 100px;
    }
  }
}

.fake-lines {
  margin: $spacer 0;
  &-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
  &-img {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin-right: $spacer;
    background-color: $color-gray-cloud;
  }
  &-line {
    background-color: $color-gray-cloud;
    flex: 1;
  }
  &.paragraph .fake-lines-row:last-child {
    width: 60%;
  }
  &.users .fake-lines-row {
    &:nth-child(5n+1) { width: 50%; }
    &:nth-child(5n+2) { width: 60%; }
    &:nth-child(5n+3) { width: 40%; }
    &:nth-child(5n+4) { width: 65%; }
    &:nth-child(5n+5) { width: 55%; }
  }
}
