
.npp {
  padding-bottom: $spacer * 8;

  &-section {
    padding: 0 ($spacer * 4);
    max-width: 714px + ($spacer * 8);
    margin: 0 auto;
    margin-top: $spacer * 4;

    &-header {
      position: relative;
      text-align: center;

      &:before {
        content: '';
        position: absolute;
        display: block;
        height: 1px;
        background-color: $color-gray-cloud;
        left: 0;
        right: 0;
        top: 50%;
        z-index: -1;
      }

      h3 {
        display: inline-block;
        font-size: 14px;
        font-weight: $cerebri-regular;
        text-transform: uppercase;
        text-align: center;
        background-color: white;
        padding: 0 $spacer;
        margin: 0;
      }
    }
  }

  &-cat {
    @include cat-bg($color-cat-other);

    &.health { @include cat-bg($color-cat-health); }
    &.animals { @include cat-bg($color-cat-animals); }
    &.culture { @include cat-bg($color-cat-culture); }
    &.community-dev { @include cat-bg($color-cat-community-dev); }
    &.education { @include cat-bg($color-cat-education); }
    &.environment { @include cat-bg($color-cat-environment); }
    &.public-policy { @include cat-bg($color-cat-public-policy); }
    &.human-services { @include cat-bg($color-cat-human-services); }
    &.public-services { @include cat-bg($color-cat-public-services); }
    &.civil-rights { @include cat-bg($color-cat-civil-rights); }
    &.international { @include cat-bg($color-cat-international); }
    &.religion { @include cat-bg($color-cat-religion); }

    &-wrapper {
      height: 278px;
      max-width: 714px + ($spacer * 16);
      margin: 0 auto;
      // height: 100%;
      position: relative;
    }
    &-name {
      position: absolute;
      bottom: $spacer * 2;
      left: 0;
      width: 100%;
      font-weight: $cerebri-heavy;
      color: rgba(255,255,255,0.09);
      font-size: 80px;
      white-space: nowrap;
    }
    &-img {
      display: block;
      position: absolute;
      left: $spacer * 8;
      top: 50%;
      height: 140px;
      margin-top: -70px;
    }
    &-icon {
      width: 140px;
      color: white;
      position: absolute;
      left: $spacer * 8;
      top: 50%;
      margin-top: -70px;
    }

    @include media("<xl") {
      .npp-cat-wrapper {
        height: 200px;
      }
      .npp-cat-img {
        height: 100px;
        margin-top: -50px;
      }
      .npp-cat-icon {
        width: 100px;
        margin-top: -50px;
      }
      .npp-cat-name {
        font-size: 60px;
        bottom: $spacer;
      }
    }
  }

  &-hero {
    max-width: 1100px + ($spacer * 8);
    margin: 0 auto;
    margin-top: $spacer * 4;
    margin-bottom: ($spacer * 4) + 1;

    &-heighter {
      padding-bottom: 50%;
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: ($spacer * -4) - 2;
        background-color: $color-gray-cloud;
        height: 1px;
      }
    }

    &-image-con {
      position: absolute;
      width: 200px;

      &.ic1 {
        z-index: 3;
        width: 42.5%;
        bottom: 0;
        right: 50%;
        margin-right: -21.25%;
      }
      &.ic2 {
        z-index: 2;
        width: 52%;
        top: 0;
        right: 0;
      }
      &.ic3 {
        z-index: 1;
        width: 51%;
        top: 50%;
        margin-top: -17%;
        left: 0;
      }
    }

    &-image {
      background-position: center;
      background-size: cover;
      padding-bottom: 66.666667%;
      box-shadow: rgba(0,0,0,0.28) 0 2px 6px 2px;
    }

    &-logo {
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      max-width: 19%;
      max-height: 30%;
      z-index: 2;
    }

    @include media("<xl") {
      max-width: 1100px + ($spacer * 6);
    }
  }

  &-basic {
    margin-top: $spacer * 8;

    h1 {
      font-weight: $cerebri-heavy;
      font-size: 46px;
      margin: 0;
    }

    &-badges {
      margin-top: $spacer;
    }

    &-badges + &-mission {
      p:first-child {
        margin-top: 8px;
      }
    }

    &-mission {
      font-size: 17px;

      p {
        margin: 20px 0;
      }
    }

    &-cols {
      display: flex;
      flex-direction: row;
      &-left {
      }
      &-sep {
        width: 1px;
        background-color: $color-gray-cloud;
        margin: 0 ($spacer * 1.5);
      }
      &-right {
        flex: 1;
      }
    }

    &-ctas {
      @include flexbox(row, flex-start, flex-start);
      margin-top: $spacer;
      gap: $spacer * 0.5;
      flex-wrap: wrap;
    }
    .favorite-btn.is-favorited {
      svg g {
        fill: currentColor;
      }
    }

    &-location {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: $cerebri-heavy;
      font-size: 14px;
      margin-bottom: calc($spacer / 2);

      svg.icon {
        width: 22px;
        margin-right: calc($spacer / 2);
      }
    }

    &-ein {
      color: $color-text-faint;
      font-size: 14px;
    }

    &-tags {
      &-tag {
        background-color: #F6F6F6;
        font-size: 13px;
        color: $color-text-faint;
        height: 20px;
        line-height: 20px;
        padding: 0 ($spacer * 0.75);
        border-radius: 10px;
        display: inline-block;
        margin: 0 ($spacer * 0.75) ($spacer * 0.75) 0;
      }
    }
  }

  &-claim {
    background-color: $color-highlight-blue;
    padding: $spacer * 2;
    @include flexbox(row, center, space-between);
    border-radius: 2px;
    &-title {
      margin: 0;
      text-transform: uppercase;
      font-size: 16px;
    }
    &-desc {
      margin: 0;
      margin-top: calc($spacer / 2);
      // color: $color-text-medium-1-5;
    }
  }

  &-recent-donors {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: $spacer * 2;
    padding: 0 ($spacer * 2);

    &-images {
      display: flex;
      flex-direction: row;
      margin-right: $spacer;
      padding-left: 5px;

      img {
        display: block;
        width: 29px;
        height: 29px;
        border-radius: 100%;
        background-color: white;
        border: 1px solid white;
        box-shadow: 0 2px 4px rgba(0,0,0,0.12);
        object-fit: cover;
        margin-left: -5px;
      }
    }
  }

  &-personal {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-text {
      flex: 1;
      padding-right: $spacer * 2;
    }

    &-image {
      width: 176px;
      height: 176px;
      border-radius: 100%;
      background-size: cover;
      background-position: center;
    }
  }

  &-stats {

    &-stat {
      display: flex;
      flex-direction: row;
      align-items: center;

      &:not(:first-child) {
        margin-top: $spacer * 2;
      }

      &-text {
        flex: 1;
        padding-left: $spacer * 2;
        font-weight: $cerebri-light;
        font-size: 20px;
      }

      &-number {
        font-size: 90px;
        font-weight: $cerebri-bold;
        text-shadow: $color-gray-cloud 5px 5px 0;
      }

      &.fake {
        .npp-stats-stat-number {
          min-width: 160px;
        }
      }

      @include media(">=medium") {
        &:nth-child(even) {
          flex-direction: row-reverse;

          .npp-stats-stat-text {
            text-align: right;
            padding-left: 0;
            padding-right: $spacer * 2;
          }
        }
      }
    }
  }

  &-video {

    &-heighter {
      height: 0;
      width: 100%;
      padding-bottom: 56.25%; // 16:9
      position: relative;

      iframe {
        border: 0;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }

  &-strengths {
    display: flex;
    flex-direction: row;

    &-sep {
      width: 1px;
      background-color: $color-gray-cloud;
      margin: 0 ($spacer * 3);
    }

    &-strength {
      flex: 1;

      &-number {
        font-size: 40px;
        font-weight: $cerebri-bold;
        text-shadow: $color-gray-cloud 3px 3px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      &-label {
        font-size: 20px;
        font-weight: $cerebri-light;
      }
    }

    .fake-amount {
      display: inline-block;
      width: 80px;
      margin-left: calc($spacer / 2);
    }
  }

  &-finance {
    &-cols {
      margin-top: $spacer * 2;
      display: flex;
      flex-direction: row;
      justify-content: center;

      &-sep {
        width: 1px;
        background-color: $color-gray-cloud;
        margin: 0 ($spacer * 2);
      }
      &-budget {
        text-align: right;
        &-val {
          font-weight: $cerebri-heavy;
          font-size: 56px;
        }
        &-label {
          font-size: 16px;
        }
      }
      &-percents {
        .npp-finance-percent {
          margin-top: $spacer * 1;
          font-size: 16px;
          &:first-child {
            margin-top: 0;
          }
          &.program {
            color: $color-accent-blue;
          }
          &.management {
            color: $color-accent-green;
          }
          &.fundraising {
            color: $color-accent-pink;
          }
          &-val {
            font-weight: $cerebri-heavy;
            width: 50px;
            display: inline-block;
            // padding-right: $spacer;
          }
        }
      }
    }

    &-chart {
      background-color: $color-gray-cloud;
      height: 25px;
      line-height: 25px;
      border-radius: 25px;
      overflow: hidden;
      margin-top: $spacer * 2;
      white-space: nowrap;

      &-part {
        height: 100%;
        display: inline-block;
        color: white;
        font-weight: $cerebri-heavy;
        font-size: 11px;
        padding-left: calc($spacer / 2);

        &.program {
          background-color: $color-accent-blue;
        }
        &.management {
          background-color: $color-accent-green;
        }
        &.fundraising {
          background-color: $color-accent-pink;
        }
      }
    }
  }

  &-programs, &-events {
    &-items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: $spacer * 2;

      .npp-programs-item, .npp-events-item {
        flex: 1;
        min-width: 50%;
        max-width: 50%;
        margin-top: $spacer * 2;
        &:nth-child(odd) {
          padding-right: $spacer;
        }
        &:nth-child(even) {
          padding-left: $spacer;
        }
        &:first-child, &:nth-child(2) {
          margin-top: 0;
        }
        &-image {
          padding-bottom: 50%;
          background-size: cover;
          background-position: center;
        }
        &-title {
          font-weight: $cerebri-heavy;
          font-size: 17px;
          margin: ($spacer * 2) 0;
        }
      }
    }
  }

  .donate {
    margin-top: $spacer * 4;
  }

  &-website {
    text-align: center;

    &-title {
      text-transform: uppercase;
      color: $color-text-faint;
    }

    &-fake-link.fake-lines {
      width: 130px;
      margin: $spacer auto;
    }
  }

}

/*
 *  Small
 */

.nonprofit-profile.small {
  overflow: hidden;
  padding-bottom: $spacer * 4;
}

body.size-s, .nonprofit-profile.small {
  .npp {
    &-section {
      padding: 0 ($spacer * 2);
    }
    &-cat {
      .npp-cat-wrapper {
        height: 150px;
      }
      .npp-cat-img {
        height: 80px;
        margin-top: -40px;
      }
      .npp-cat-icon {
        width: 80px;
        margin-top: -40px;
      }
      .npp-cat-name {
        font-size: 40px;
        left: $spacer;
        bottom: 0;
      }
    }
    &-hero + .npp-basic {
      margin-top: $spacer * 8;
    }
    &-basic {
      margin-top: $spacer * 4;
      h1 {
        font-size: 36px;
      }
      &-cols {
        flex-direction: column;
        &-left {
          margin-bottom: $spacer;
        }
        &-sep {
          display: none;
        }
      }
    }
    &-personal {
      &-image {
        width: 100px;
        height: 100px;
      }
    }
    &-stats {
      &-stat {
        flex-direction: column;
        align-items: flex-start;
        &-number {
          font-size: 80px;
          text-shadow: $color-gray-cloud 3px 3px 0;
        }
        &-text {
          margin-top: 0;
          padding-left: 0;
        }
      }
    }
    &-strengths {
      flex-direction: column;
      &-strength {
        margin-top: $spacer * 2;
        &:first-child {
          margin-top: 0;
        }
        &-number {
          font-size: 30px;
          text-shadow: $color-gray-cloud 2px 2px 0;
        }
        &-label {
          font-size: 18px;
        }
      }
      &-sep {
        display: none;
      }
    }
    &-finance {
      &-cols {
        flex-direction: column;
        &-budget {
          text-align: left;
          &-val {
            font-size: 42px;
          }
        }
        &-sep {
          display: none;
        }
        &-percents {
          margin-top: $spacer;
        }
      }
    }
    &-programs, &-events {
      &-items {
        flex-direction: column;
        flex-wrap: nowrap;
        .npp-programs-item {
          max-width: 100%;
          padding: 0 !important;
          &:not(:first-child) {
            margin-top: $spacer * 2;
          }
        }
      }
    }
  }
}
