.ca-mtrx {
  .ca-box {
    &-body {
      padding: 0;
    }
    .pagination {
      padding: ($spacer * 3) 0;
    }
  }
}

.mtrx-title {
  @include flexbox(row, center, center);
  gap: $spacer;
  position: relative;
  svg.icon {
    width: 30px;
  }
  h1 {
    font-weight: $cerebri-heavy;
    font-size: 30px;
    margin: 0;
  }
  &-ent-select {
    position: absolute;
    top: 0;
    right: 0;
  }
}



.mtrx-filters.ca-main-filters {
  justify-content: center;
  margin-top: $spacer * 2;
  margin-bottom: $spacer * 4;
}



.mtrx-presets {
  @include flexbox(row, center, center);
  margin: 0 auto;
  gap: $spacer;
  margin-top: $spacer * 2;

  & + .mtrx-presets {
    margin-top: $spacer;
  }

  &-preset {
    @include flexbox(column, center, center);
    flex: 1;
    gap: calc($spacer / 2);
    border: 1px solid $color-gray-cloud;
    border-radius: 3px;
    max-width: 180px;
    height: 100px;
    cursor: pointer;
    transition: transform 200ms;
    text-transform: uppercase;
    svg.icon {
      width: 24px;
    }

    @mixin preset-color($color, $bg-image) {
      color: $color;
      &.active, &:hover {
        border-color: $color;
        background-image: $bg-image;
      }
    }
    &.orange { @include preset-color($color-accent-orange, $gradient-orange); }
    &.pink   { @include preset-color($color-accent-pink,   $gradient-pink); }
    &.green  { @include preset-color($color-accent-green,  $gradient-green); }
    &.green2 { @include preset-color($color-accent-green2, $gradient-green2); }
    &.purple { @include preset-color($color-accent-purple, $gradient-purple); }
    &.blue   { @include preset-color($color-accent-blue,   $gradient-blue); }
    &.blue2  { @include preset-color($color-accent-blue2,  $gradient-blue2); }
    &.black  { @include preset-color($color-gray-midnight, $gradient-black); }
    &.gray   { @include preset-color($color-gray-slate,    $gradient-slate); }
    &.groups { @include preset-color($color-groups-a,      $gradient-groups); }

    &:hover {
      transform: translateY(-2px);
      box-shadow: $shadow;
    }
    &.active, &:hover {
      background-image: $gradient-slate;
      color: white;
    }
    &.active {
      font-weight: $cerebri-bold;
    }
  }
}

.mtrx-filter-headings {
  &-filter {
    font-weight: $cerebri-bold;
    font-size: 22px;
    margin: 0;
    margin-left: 30px + $spacer;
    color: $color-text-medium-1-5;
    &.light {
      font-weight: $cerebri-regular;
    }
  }
}

.mtrx-help {
  max-width: 200px;
  &-name {
    font-weight: $cerebri-bold;
  }
  &-desc {
    margin-top: calc($spacer / 2);
  }
}

.mtrx-slct {
  .modal-box {
    width: 1000px;
    padding-bottom: $spacer * 2;
  }
  .bform-h2 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .bform-actions {
    margin-top: $spacer * 2;
  }

  &-hr {
    background-color: $color-gray-cloud;
    height: 1px;
    margin: 0 ($spacer * -4);
  }

  &-cols {
    @include flexbox(row, flex-start, flex-start);
    &-left {
      flex: 2;
      border-right: 1px solid $color-gray-cloud;
    }
    &-right {
      flex: 1;
      padding-left: $spacer * 4;
    }
  }

  &-selected, &-all {
    max-height: 465px;
    overflow: auto;
  }
  &-selected {
    padding-top: $spacer * 2;
    .mtrx-slct-metric {
      margin-bottom: $spacer;
    }
  }
  &-all {
    padding-right: $spacer * 4;
    padding-bottom: $spacer * 4;
    &-metric-list {
      @include flexbox(row, flex-start, flex-start);
      flex-wrap: wrap;
      gap: calc($spacer / 2);
    }
  }

  &-metric {
    border: 1px solid $color-gray-fog;
    padding: 2px 8px;
    border-radius: 100px;
    cursor: pointer;
    font-size: 12px;
    position: relative;

    &:hover {
      background-color: $color-highlight-blue;
    }

    &.active {
      color: $color-accent-blue;
      border-color: $color-accent-blue;
      background-color: $color-highlight-blue;
    }
  }
}



.mtrx-tabs {
  &-tabs {
    margin: ($spacer * 1) ($spacer * 3) ($spacer * 3) ($spacer * 3);
    @include flexbox(row, flex-start, flex-start);
    gap: calc($spacer / 2);
    flex-wrap: wrap;
  }
  &-tab {
    padding: calc($spacer / 2) $spacer;
    border-radius: 100px;
    background-color: $color-gray-ice;
    // color: $color-text-medium-1-5;
    color: $color-text-default;
    text-decoration: none !important;
    transition: all 200ms;
    &.separate {
      margin-left: $spacer;
    }
    &.active, &:hover {
      background-color: $color-gray-midnight;
      color: white;
      box-shadow: $shadow;
    }
    &.active {
      font-weight: $cerebri-bold;
    }
    &:hover {
      transform: translateY(-2px);
    }
  }
}


.mtrx-table {
  width: 100%;

  &-loading {
    display: block;
    width: 24px;
    margin: 200px auto;
    color: $color-gray-fog;
  }

  &-con2 {
    position: relative;
    &:before {
      z-index: 2;
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: $spacer * 8;
      background: linear-gradient(to left, white, transparent);
    }
  }
  &-con {
    border-top: 1px solid $color-gray-cloud;
    position: relative;
    width: 100%;
    overflow: auto;
  }

  th, td {
    text-align: right;
    height: 70px;
    min-width: 150px;
    padding: 0;
    border-bottom: 1px solid $color-gray-cloud;
    background-color: white;
    &:first-child {
      text-align: left;
      position: sticky;
      left: 0;
      z-index: 1;
    }
    &:nth-child(1) {
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 100%;
        top: 0;
        bottom: 0;
        width: 12px;
        background: linear-gradient(to right, rgba(0,0,0,0.04), transparent);
      }
    }
  }

  tr:first-child th {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    &:first-child {
      z-index: 2;
    }
  }
  tbody tr {
    &:hover td {
      background-color: $color-gray-ice;
    }
  }

  &-cell {
    &-sortind {
      width: 12px;
      stroke-width: 2px;
      margin-left: calc($spacer / 4);
    }
  }
  &-cell-gby {
    min-width: 280px !important;
    max-width: 320px;
    &-row {
      @include flexbox(row, center, space-between);
      border-right: 1px solid $color-gray-cloud;
      height: 100%;
      padding: 0 ($spacer * 1.5) 0 ($spacer * 3);
      .ellipsis-menu {
        margin-left: $spacer;
      }
    }
    &-left {
      flex: 1;
      overflow: hidden;
    }
    &-status {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 100px;
      background-color: $color-gray-slate;
      margin-right: calc($spacer / 4);
      &.active { background-color: $color-accent-green; }
      &.upcoming { background-color: $color-accent-blue; }
      &.ended { background-color: $color-danger; }
    }
  }
  &-cell-header {
    font-weight: $cerebri-regular;
    &-row {
      @include flexbox(row, center, flex-end);
    }
    &.sort {
      font-weight: $cerebri-bold;
    }
    &.sortable:hover {
      cursor: pointer;
      background-color: $color-highlight-blue !important;
    }
    &.mtrx-table-cell-gby {
      padding: 0 !important;
    }
  }
  td.mtrx-table-cell-val, th.mtrx-table-cell-header {
    padding: 0 $spacer;
  }
  &-cell-val {
    // font-family: $dm-mono;
    // font-weight: 500;
    font-size: 16px;
  }

}



.mtrx-sum {
  @include flexbox(row, stretch, flex-start);
  flex-wrap: wrap;
  gap: $spacer * 2;
  margin: $spacer * 3;
  padding-bottom: $spacer * 3;

  &-metric {
    position: relative;
    // border-left: 2px solid $color-gray-cloud;
    padding: $spacer;
    padding-left: $spacer + 5px;
    width: 200px;
    // @include flexbox(column, flex-end, center);
    // text-align: right;
    &-value {
      font-weight: $cerebri-heavy;
      font-size: 28px;
    }
    &-label {
      text-transform: uppercase;
      // font-size: 16px;
      // color: $color-text-medium-2;
    }

    &:before {
      position: absolute;
      top: 0; left: 0; bottom: 0;
      content: '';
      display: block;
      width: 5px;
      background-image: $gradient-slate;
      border-radius: 100px;
      opacity: 0.2;
    }
  }

  @mixin sum-color($color, $bg-image) {
    .mtrx-sum-metric:before {
      background-image: $bg-image;
    }
    .mtrx-sum-metric-value {
      color: $color;
      @include gradient-text($bg-image);
    }
  }
  &.pink   { @include sum-color($color-accent-pink,   $gradient-pink); }
  &.purple { @include sum-color($color-accent-purple, $gradient-purple); }
  &.blue   { @include sum-color($color-accent-blue,   $gradient-blue); }
  &.blue2  { @include sum-color($color-accent-blue2,  $gradient-blue2); }
  &.green  { @include sum-color($color-accent-green,  $gradient-green); }
  &.green2 { @include sum-color($color-accent-green2, $gradient-green2); }
  &.orange { @include sum-color($color-accent-orange, $gradient-orange); }
  &.groups { @include sum-color($color-groups-a,      $gradient-groups); }

}
